import { createGuid } from "./guidUtils";
import { resizeImage } from "./imageResizingUtils";

export const isImage = type =>
  type === "image/jpeg" ||
  type === "image/jpg" ||
  type === "image/gif" ||
  type === "image/png";

export const isPdf = type => type === "application/pdf";

export const isDocx = type =>
  type ===
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const isDoc = type => type === "application/msword";

export const loadFiles = (files, { imageConfig }) => {
  const promises = [];
  files.forEach(file => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const result = { name: file.name, type: file.type, id: createGuid() };

        if (isImage(file.type)) {
          loadImage(file, imageConfig, reader, resolve, result);
        } else if (isPdf(file.type) || isDoc(file.type) || isDocx(file.type)) {
          loadPdfOrWord(file, reader, resolve, result);
        } else {
          reject(
            "Przynajmniej jeden z wybranych plików jest w nieodpowiednim formacie. Wspierane formaty to: .jpg, .jpeg, .png, .gif, .pdf, .docx, .doc"
          );
        }
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
    promises.push(promise);
  });

  return Promise.all(promises);
};

const loadImage = (file, { maxHeight, maxWidth }, reader, resolve, result) => {
  const image = new Image();
  image.src = reader.result;

  image.onload = () => {
    if (image.width > maxWidth || image.height > maxHeight) {
      result.url = resizeImage(image, maxWidth, maxHeight, 0.7, file.type);
    } else {
      result.url = reader.result;
    }
    resolve(result);
  };
};

const loadPdfOrWord = (file, reader, resolve, result) => {
  result.url = reader.result;
  resolve(result);
};
