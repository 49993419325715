import Axios from "axios";
import qs from "qs";

const UploadsService = {
  get: async id => {
    const result = await Axios.get(`/api/uploads/${id}`);

    return result.data;
  },
  getAll: async (page, limit, filters) => {
    const result = await Axios.get("/api/uploads", {
      params: { limit, page, ...filters },
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: "repeat " })
    });

    return result.data;
  },
  batchInsert: async items => {
    await Axios.post("/api/uploads/batchInsert", items);
  },
  update: async (id, { name, description, version }) => {
    await Axios.patch(`/api/uploads/${id}`, { name, description, version });
  },
  delete: async id => {
    await Axios.delete(`/api/uploads/${id}`);
  }
};

export default UploadsService;
