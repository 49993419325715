import React, { useState, useEffect } from 'react';
import SiteWrapper from '../layout/SiteWrapper';
import PageContent from '../layout/PageContent';
import { Grid, Button, Table, Card } from 'tabler-react';
import Loader from '../layout/Loader';
import handleError from '../../utils/errorHandler';
import PageHeader from '../layout/PageHeader';
import Icon from '@mdi/react';
import {
  mdiTrashCanOutline,
  mdiPlus,
  mdiNewspaperPlus,
  mdiTable,
  mdiPencilOutline,
  mdiInformationOutline,
} from '@mdi/js';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import ConfirmModal from '../modal/ConfirmModal';
import Modal from '../modal/Modal';
import ListNoDataMessage from '../shared/ListNoDataMessage';
import moment from 'moment';
import AddEditTable from './AddEditTable';
import DictionaryService from '../../services/dictionaryService';
import StyledLink from '../shared/StyledLink';
import { useLocation, useParams } from 'react-router-dom';

const StyledButtonsCol = styled(Table.Col)`
  width: 100px;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 10px;
  }
`;

const useSearchParams = (search) => {
  const [params, setParams] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const p = {};
    for (const [key, value] of urlParams) {
      p[key] = value;
    }
    setParams(p);
  }, [search]);

  return params;
};

const Tables = ({ history }) => {
  const [addEditModalOptions, setAddEditModalOptions] = useState({
    isOpen: false,
    isEdit: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [tables, setTables] = useState(null);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: '',
  });
  const search = useLocation().search;
  const searchParams = useSearchParams(search);

  const getTables = async () => {
    const tables = await DictionaryService.getAll('TABLE');
    return tables.map((x) => ({ ...x, value: JSON.parse(x.value) }));
  };

  const fetchTables = async () => {
    setIsLoading(true);
    try {
      const tables = await getTables();

      setTables(tables);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchTables();
  }, []);

  useEffect(() => {
    if (searchParams.key && tables) {
      const table = tables.find(
        (x) => x.key.toUpperCase() === searchParams.key.toUpperCase()
      );

      if (table) {
        setAddEditModalOptions({
          isOpen: true,
          isEdit: true,
          state: { ...table },
        });

        const urlParams = new URLSearchParams(
          '?key=TABLE-0BE18550-E723-4CAF-8DE6-DBEA6A9AD4A1'
        );
        urlParams.delete('key');
        history.replace({
          search: urlParams.toString(),
        });
      }
    }
  }, [tables, searchParams]);

  const deleteTable = async ({ key }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await DictionaryService.delete(key);

      fetchTables();
    } catch (error) {
      handleError(error, history);
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Tabele" icon={mdiTable}>
          <Button
            color="success"
            onClick={() =>
              setAddEditModalOptions({
                isOpen: true,
                isEdit: false,
              })
            }
          >
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Nazwa tabeli</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Data modyfikacji</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tables.map((table) => {
                    return (
                      <Table.Row key={table.key}>
                        <Table.Col>
                          <StyledLink
                            onClick={() =>
                              setAddEditModalOptions({
                                isOpen: true,
                                isEdit: true,
                                state: { ...table },
                              })
                            }
                          >
                            <strong>{table.value.name}</strong>
                          </StyledLink>
                        </Table.Col>
                        <Table.Col>
                          {moment(table.createdDate).format('YYYY-MM-DD HH:mm')}
                        </Table.Col>
                        <Table.Col>
                          {moment(table.updatedDate).format('YYYY-MM-DD HH:mm')}
                        </Table.Col>
                        <Table.Col>
                          {table.updatedByName}{' '}
                          <Tooltip
                            title={`Utworzone przez: <strong>${table.createdByName}</strong>`}
                            position="bottom"
                            arrow
                          >
                            <Icon
                              path={mdiInformationOutline}
                              color="lightblue"
                              size={0.8}
                              style={{ paddingTop: 5 }}
                            />
                          </Tooltip>
                        </Table.Col>
                        <StyledButtonsCol>
                          <StyledButtonsWrapper>
                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setAddEditModalOptions({
                                    isOpen: true,
                                    isEdit: true,
                                    state: { ...table },
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć tabelę: <strong>${table.value.name}</strong>?`,
                                    state: { key: table.key },
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </StyledButtonsWrapper>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {tables.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal
          isOpen={addEditModalOptions.isOpen}
          title={addEditModalOptions.isEdit ? 'Edytuj tabelę' : 'Dodaj tabelę'}
          fullscreen
        >
          <AddEditTable
            isEdit={addEditModalOptions.isEdit}
            tableData={addEditModalOptions.state}
            onSuccess={() => {
              setAddEditModalOptions({ isOpen: false });
              fetchTables();
            }}
            onDiscard={() => {
              setAddEditModalOptions({ isOpen: false });
            }}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={deleteTable}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default Tables;
