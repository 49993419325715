import React from "react";
import styled from "styled-components";

export const StyledStatus = styled.span`
  padding: 5px;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
`;

const Status = ({ value }) => {
  const style = {};
  if (value === "Szkic") {
    style.background = "#467fcf";
  } else if (value.includes("Opublikowan")) {
    style.background = "#5eba00";
  } else if (value.includes("Zarchiwizowan")) {
    style.background = "#343a40";
  }

  return (
    <div>
      <StyledStatus style={style}>{value}</StyledStatus>
    </div>
  );
};

export default Status;
