import React from "react";
import { Button, Table } from "tabler-react";
import Icon from "@mdi/react";
import { mdiTrashCanOutline } from "@mdi/js";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import { isPdf } from "../../utils/fileUtils";

const StyledButtonsCol = styled(Table.Col)`
  width: 75px;

  > div {
    text-align: center;
  }
`;

const StyledImageWrapper = styled.div`
  > img {
    height: 30px;
  }
`;

const AttachmentsList = ({ attachments, onRemoveClick }) => {
  const renderType = type => {
    if (isPdf(type)) {
      return (
        <StyledImageWrapper>
          <img src="/pdf_icon.png" alt="" />
        </StyledImageWrapper>
      );
    }

    return (
      <StyledImageWrapper>
        <img src="/word_icon.png" alt="" />
      </StyledImageWrapper>
    );
  };

  return (
    <Table
      cards={true}
      striped={true}
      responsive={true}
      className="table-vcenter"
    >
      <Table.Header>
        <Table.Row>
          <Table.ColHeader>Typ</Table.ColHeader>
          <Table.ColHeader>Nazwa</Table.ColHeader>
          <Table.ColHeader />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {attachments.map(attachment => {
          return (
            <Table.Row key={attachment.id}>
              <Table.Col className="w-1">
                {renderType(attachment.type)}
              </Table.Col>
              <Table.Col>{attachment.name}</Table.Col>
              <StyledButtonsCol>
                <div>
                  <Tooltip title="Usuń" position="bottom" arrow>
                    <Button
                      color="danger"
                      type="button"
                      className="only-icon"
                      onClick={() => onRemoveClick(attachment.id)}
                    >
                      <Icon
                        path={mdiTrashCanOutline}
                        size={0.8}
                        color="white"
                      />
                    </Button>
                  </Tooltip>
                </div>
              </StyledButtonsCol>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default AttachmentsList;
