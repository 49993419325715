import React, { useState, useContext } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../context/UserContext";
import routes from "../../routes";
import Loader from "../layout/Loader";
import ErrorMessage from "../shared/form/ErrorMessage";
import logo from "../../logo.png";
import styled from "styled-components";

const StyledHeader = styled.div`
  > img {
    width: 140px;
  }

  > div {
    color: #222222;
    font-size: 18px;
    margin-top: 15px;
    font-weight: 600;
  }
`;

const Scheme = Yup.object().shape({
  login: Yup.string().required("Login musi być uzupełniony."),
  password: Yup.string().required("Hasło musi być uzupełnione.")
});

const LoginPage = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ login: "", password: "" });
  const [errorMessage, setErrorMessage] = useState(null);
  const { logIn } = useContext(UserContext);

  const handleSumbit = async ({ login, password }) => {
    setData({ login, password });
    setIsLoading(true);
    try {
      await logIn(login, password);
      setIsLoading(false);
      if (props.location.state && props.location.state.ref) {
        props.history.push(props.location.state.ref);
      } else {
        props.history.push(routes.home);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 401) {
        setErrorMessage(
          "Logowanie się nie powiodło. Login lub hasło jest niepoprawne."
        );
      } else {
        setErrorMessage(
          "Coś poszło nie tak. Proszę skontaktuj się z administratorem."
        );
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={Scheme}
      onSubmit={handleSumbit}
      render={({ values, errors, touched }) => {
        return (
          <div className="page">
            <div className="page-single">
              <div className="container">
                <div className="row">
                  <div className="col col-login mx-auto">
                    <div className="text-center mb-6">
                      {/* <strong>{props.location.state}</strong> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-login mx-auto">
                    <div className="card">
                      <div className="text-center mb-6 mt-6">
                        <StyledHeader>
                          <img src={logo} alt="logo" />
                          <div>SYSTEM OBSŁUGI STRONY PWSM</div>
                        </StyledHeader>
                      </div>
                      <Form autoComplete="off">
                        <div className="card-body p-6">
                          <div className="card-title">
                            Zaloguj się na swoje konto
                          </div>
                          <div className="form-group">
                            <label className="form-label">Login</label>
                            <Field
                              name="login"
                              className={`form-control ${
                                errors.login && touched.login
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="text"
                              placeholder="Login"
                            />
                            {errors.login && touched.login && (
                              <span className="invalid-message">
                                {errors.login}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="form-label">Hasło</label>
                            <Field
                              name="password"
                              className={`form-control ${
                                errors.password && touched.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="password"
                              placeholder="Hasło"
                            />
                            {errors.password && touched.password && (
                              <span className="invalid-message">
                                {errors.password}
                              </span>
                            )}
                          </div>
                          <div>
                            <ErrorMessage show={errorMessage}>
                              {errorMessage}
                            </ErrorMessage>
                          </div>
                          <div className="form-footer">
                            <button
                              className="btn btn-block btn-primary"
                              type="submit"
                            >
                              Zaloguj się
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default LoginPage;
