import React, { useState, useEffect } from 'react';
import SiteWrapper from '../layout/SiteWrapper';
import PageContent from '../layout/PageContent';
import { Grid, Button, Table, Card } from 'tabler-react';
import Loader from '../layout/Loader';
import handleError from '../../utils/errorHandler';
import PageHeader from '../layout/PageHeader';
import Icon from '@mdi/react';
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiInformationOutline,
  mdiFileCheckOutline,
  mdiFileCancelOutline,
  mdiBookOpenVariant,
} from '@mdi/js';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import ConfirmModal from '../modal/ConfirmModal';
import Modal from '../modal/Modal';
import Status from '../shared/Status';
import moment from 'moment';
import Pager from '../pager/Pager';
import StyledLink from '../shared/StyledLink';
import AddEditBlogPosts from './AddEditBlogPosts';
import PostListImage from '../posts/PostListImage';
import BlogPostsService from '../../services/blogPostsService';

const StyledButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 10px;
  }
`;

const StyledButtonsCol = styled(Table.Col)`
  width: 100px;
`;

const limit = 7;

const BlogPosts = ({ history }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: '',
  });
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalOptions, setEditModalOptions] = useState({ isOpen: false });
  const [pagingInfo, setPagingInfo] = useState(null);
  const [currentFilters, setCurrentFilters] = useState(null);

  const getBlogPosts = async (page, filters) => {
    const blogPosts = await BlogPostsService.getAll(page, limit, filters);
    return blogPosts;
  };

  const fetchBlogPosts = async (page = 1, filters = null) => {
    setIsLoading(true);
    try {
      const blogPosts = await getBlogPosts(page, filters);
      setBlogPosts(blogPosts.items);
      setPagingInfo({ totalCount: blogPosts.count, currentPage: page });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const deleteBlogPost = async ({ id }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await BlogPostsService.delete(id);

      await fetchBlogPosts();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleRelease = async (id) => {
    try {
      await BlogPostsService.release(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchBlogPosts();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleArchive = async (id) => {
    try {
      await BlogPostsService.archive(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchBlogPosts();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleOnChangePage = (page) => {
    fetchBlogPosts(page, currentFilters);
  };

  const renderStatusButton = (post) => {
    let title = '';
    let message = '';
    let onConfirmHandler = null;
    let color = '';
    let icon = null;

    switch (post.status) {
      case 'Szkic':
        title = 'Opublikuj';
        message = `Czy na pewno chcesz zmienić status na <strong>Opublikowany</strong>?`;
        onConfirmHandler = () => handleRelease(post.id);
        color = 'primary';
        icon = mdiFileCheckOutline;
        break;
      case 'Opublikowany':
        title = 'Archiwizuj';
        message = `Czy na pewno chcesz zmienić status na <strong>Zarchwizowany</strong>?`;
        onConfirmHandler = () => handleArchive(post.id);
        color = 'warning';
        icon = mdiFileCancelOutline;
        break;
      case 'Zarchiwizowany':
        title = 'Opublikuj ponownie';
        message = `Czy na pewno chcesz przywrócić status <strong>Opublikowany</strong>?`;
        onConfirmHandler = () => handleRelease(post.id);
        color = 'gray-dark';
        icon = mdiFileCheckOutline;
        break;
      default:
        break;
    }

    return (
      <Tooltip title={title} position="bottom" arrow>
        <Button
          color={color}
          type="button"
          className="only-icon"
          onClick={() =>
            setConfirmModalOptions({
              isOpen: true,
              message,
              state: { id: post.id },
              onConfirmHandler,
            })
          }
        >
          <Icon path={icon} size={0.8} color="white" />
        </Button>
      </Tooltip>
    );
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Blog" icon={mdiBookOpenVariant}>
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj wpis
          </Button>
        </PageHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div
            style={{
              padding: '0 0 0 5px',
            }}
          >
            Znalezionych wpisów: <strong>{pagingInfo.totalCount}</strong>
          </div>
          {blogPosts.length > 0 && (
            <div style={{ marginLeft: 'auto' }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={limit}
              />
            </div>
          )}
        </div>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader>Tytuł</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Kategoria</Table.ColHeader>
                    <Table.ColHeader>Data prezentacji</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Data modyfikacji</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {blogPosts.map((blogPost) => {
                    return (
                      <Table.Row key={blogPost.id}>
                        <Table.Col>
                          <PostListImage url={blogPost.mainPhoto.url} />
                        </Table.Col>
                        <Table.Col>
                          <StyledLink
                            onClick={() =>
                              setEditModalOptions({
                                isOpen: true,
                                state: { ...blogPost },
                              })
                            }
                          >
                            <strong>{blogPost.title}</strong>
                          </StyledLink>
                        </Table.Col>
                        <Table.Col>
                          <Status value={blogPost.status} />
                        </Table.Col>
                        <Table.Col>{blogPost.categoryName}</Table.Col>
                        <Table.Col>
                          {moment(blogPost.visibleDate).format('YYYY-MM-DD')}
                        </Table.Col>
                        <Table.Col>
                          {moment(blogPost.createdDate).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Table.Col>
                        <Table.Col>
                          {moment(blogPost.updatedDate).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Table.Col>
                        <Table.Col>
                          {blogPost.updatedByName}{' '}
                          <Tooltip
                            title={`Utworzone przez: <strong>${blogPost.createdByName}</strong>`}
                            position="bottom"
                            arrow
                          >
                            <Icon
                              path={mdiInformationOutline}
                              color="lightblue"
                              size={0.8}
                              style={{ paddingTop: 5 }}
                            />
                          </Tooltip>
                        </Table.Col>

                        <StyledButtonsCol>
                          <StyledButtonsWrapper>
                            {renderStatusButton(blogPost)}
                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setEditModalOptions({
                                    isOpen: true,
                                    state: { ...blogPost },
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                className="only-icon"
                                type="button"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć aktualność pod tytułem: <strong>${blogPost.title}</strong>?`,
                                    state: { id: blogPost.id },
                                    onConfirmHandler: deleteBlogPost,
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </StyledButtonsWrapper>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          {blogPosts.length > 0 && (
            <div style={{ marginLeft: 'auto' }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={limit}
              />
            </div>
          )}
        </div>
        <Modal isOpen={addModalIsOpen} title="Dodawanie wpisu" fullscreen>
          <AddEditBlogPosts
            onSuccess={() => {
              setAddModalIsOpen(false);
              fetchBlogPosts();
            }}
            onDiscard={() => setAddModalIsOpen(false)}
          />
        </Modal>
        <Modal isOpen={editModalOptions.isOpen} title="Edycja wpisu" fullscreen>
          <AddEditBlogPosts
            isEdit
            blogPost={editModalOptions.state}
            onSuccess={() => {
              setEditModalOptions({ isOpen: false });
              fetchBlogPosts();
            }}
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={confirmModalOptions.onConfirmHandler}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default BlogPosts;
