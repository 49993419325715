import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'tabler-react/dist/Tabler.css';
import 'react-tippy/dist/tippy.css';
import 'react-datepicker/dist/react-datepicker.css';

import HomePage from './HomePage';
import Error404 from './components/shared-pages/Error404';
import Error400 from './components/shared-pages/Error400';
import Error500 from './components/shared-pages/Error500';
import Users from './components/users/Users';
import PostCategories from './components/post-categories/PostCategories';
import PrivateRoute from './components/shared/PrivateRoute';
import routes from './routes';
import UserContextProvider from './context/UserContext';
import Error403 from './components/shared-pages/Error403';
import ChangePassword from './components/change-password/ChangePassword';
import OpeningHours from './components/opening-hours/OpeningHours';
import FileImages from './components/file-images/FileImages';
import Hero from './components/posts/Posts';
import Faq from './components/faq/Faq';
import Menu from './components/menu/Menu';
import Pages from './components/pages/Pages';
import LoginPage from './components/shared-pages/LoginPage';
import HeroItems from './components/hero/HeroItems';
import EnrolmentCourses from './components/enrolment-courses/EnrolmentCourses';
import EnrolmentEmailSettings from './components/enrolment-email-settings/EnrolmentEmailSettings';
import Enrolments from './components/enrolments/Enrolments';
import JobOffers from './components/job-offers/JobOffers';
import EditJobOffer from './components/job-offers/EditJobOffer';
import { ToastProvider } from 'react-toast-notifications';
import Tables from './components/tables/Tables';
import BlogPostCategories from './components/blog-post-categories/BlogPostCategories';
import BlogPosts from './components/blog-posts/BlogPosts';
import OtherSettings from './components/other-settings/OtherSettings';
import SyncV2 from './components/sync-v2/SyncV2';

const App = () => {
  return (
    <UserContextProvider>
      <ToastProvider>
        <BrowserRouter basename="/">
          <Switch>
            <PrivateRoute exact path={routes.home} component={SyncV2} />
            <PrivateRoute path={routes.syncv2} component={SyncV2} />
            <PrivateRoute path={routes.users} component={Users} />
            <PrivateRoute
              path={routes.changePassword}
              component={ChangePassword}
            />
            <PrivateRoute
              path={routes.postCategories}
              component={PostCategories}
            />
            <PrivateRoute path={routes.openingHours} component={OpeningHours} />
            <PrivateRoute path={routes.filesImages} component={FileImages} />
            <PrivateRoute path={routes.posts} component={Hero} />
            <PrivateRoute path={routes.pages} component={Pages} />
            <PrivateRoute
              path={routes.blogPostCategories}
              component={BlogPostCategories}
            />
            <PrivateRoute path={routes.blogPosts} component={BlogPosts} />
            <PrivateRoute path={routes.faq} component={Faq} />
            <PrivateRoute path={routes.menu} component={Menu} />
            <PrivateRoute path={routes.hero} component={HeroItems} />
            <PrivateRoute path={routes.tables} component={Tables} />
            <PrivateRoute
              path={routes.otherSettings}
              component={OtherSettings}
            />
            <PrivateRoute
              path={routes.enrolmentCourses}
              component={EnrolmentCourses}
            />
            <PrivateRoute
              path={routes.enrolmentEmailSettings}
              component={EnrolmentEmailSettings}
            />
            <PrivateRoute path={routes.enrolments} component={Enrolments} />
            <PrivateRoute
              path={routes.jobOffers + '/:id'}
              component={EditJobOffer}
            />
            <PrivateRoute path={routes.jobOffers} component={JobOffers} />
            <Route path={routes.login} component={LoginPage} />
            <Route path={routes.error400} component={Error400} />
            <Route path={routes.error404} component={Error403} />
            <Route path={routes.error500} component={Error500} />
            <Route path={routes.error403} component={Error403} />
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </ToastProvider>
    </UserContextProvider>
  );
};

export default App;
