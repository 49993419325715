import React from "react";
import Sidebar from "./Sidebar";
import ExtendSession from "../extend-session/ExtendSession";

const SiteWrapper = ({ children }) => {
  return (
    <div>
      <div className="sidebar">
        <Sidebar />
      </div>
      <ExtendSession />
      <div className="page-wrapper">{children}</div>
    </div>
  );
};

export default SiteWrapper;
