import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormButtons from '../shared/form/FormButtons';
import {
  renderFormGroup,
  renderFormGroupCustomControl,
} from '../shared/form/FormRenderHelpers';
import ErrorMessage from '../shared/form/ErrorMessage';
import handleError from '../../utils/errorHandler';
import Modal from '../modal/Modal';
import FileImageSelector from '../file-image-selector/FileImageSelector';
import styled from 'styled-components';
import { mdiImageSearchOutline } from '@mdi/js';
import Icon from '@mdi/react';
import FilesImagesDisplay from '../file-images/FilesImagesDisplay';
import { withRouter } from 'react-router-dom';
import HeroItemsService from '../../services/heroItemsService';
import AddActions from './AddActions';

const StyledSelectFileImage = styled.div`
  display: block;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: ${({ isSelected }) => (isSelected && '200px') || '122px'};
  width: ${({ isSelected }) => (isSelected && '200px') || '122px'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;

  :hover {
    cursor: pointer;
    border: 1px solid #6574cd;
  }
`;

const StyledWrapperMainPhotoPreview = styled.div`
  > div > div {
    margin: 0;
  }
`;

const Schema = Yup.object().shape({
  title: Yup.string()
    .max(200, 'Podany tytuł jest za długi. Maksymalna ilość znaków to 300.')
    .required('Tytuł musi być uzupełniony.'),
  subTitle: Yup.string().max(
    300,
    'Podany podtytuł jest za długi. Maksymalna ilość znaków to 300.'
  ),
  mainPhotoId: Yup.string().required('Zdjęcie wyróżniające musi być wybrane.'),
});

const AddEditHeroItem = ({
  onSuccess,
  onDiscard,
  history,
  heroItem,
  isEdit,
}) => {
  if (isEdit && !heroItem) {
    return <></>;
  }

  const [validationError, setValidationError] = useState(null);
  const [fileSelectorModalOptions, setFileSelectorModalOptions] = useState({
    isOpen: false,
  });
  const [mainPhoto, setMainPhoto] = useState(
    isEdit ? heroItem.mainPhoto : null
  );

  const saveHeroItem = async (heroItemModel) => {
    const actions = [
      { title: heroItemModel.actionTitle1, to: heroItemModel.actionTo1 },
      { title: heroItemModel.actionTitle2, to: heroItemModel.actionTo2 },
      { title: heroItemModel.actionTitle3, to: heroItemModel.actionTo3 },
    ];

    heroItemModel = { ...heroItemModel, actions: JSON.stringify(actions) };

    try {
      if (isEdit) {
        await HeroItemsService.edit(heroItem.id, heroItemModel);
      } else {
        await HeroItemsService.add(heroItemModel);
      }
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  const handleOnSelectMainPhoto = (img, form) => {
    if (mainPhoto && mainPhoto.id === img.id) {
      form.setFieldValue('mainPhotoId', '');
      setMainPhoto(null);
      setFileSelectorModalOptions({
        isOpen: true,
        selectedFiles: [],
        onSelect: (file) => handleOnSelectMainPhoto(file, form),
      });
    } else {
      form.setFieldValue('mainPhotoId', img.id);
      setMainPhoto(img);
      setFileSelectorModalOptions({ isOpen: false });
    }
  };

  const actions =
    isEdit && heroItem.actions ? JSON.parse(heroItem.actions) : '';

  const initialValues = {
    title: isEdit ? heroItem.title : '',
    subTitle: isEdit ? heroItem.subTitle : '',
    mainPhotoId: isEdit ? heroItem.mainPhoto.id : '',
    isTitleVisible: isEdit ? heroItem.isTitleVisible : true,
    actionTitle1: (actions && actions[0].title) || '',
    actionTitle2: (actions && actions[1].title) || '',
    actionTitle3: (actions && actions[2].title) || '',
    actionTo1: (actions && actions[0].to) || '',
    actionTo2: (actions && actions[1].to) || '',
    actionTo3: (actions && actions[2].to) || '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={saveHeroItem}
        render={(form) => {
          return (
            <Form>
              {renderFormGroup(form, 'title', {
                label: 'Tytuł',
                placeholder: 'Wprowadź tytuł...',
              })}
              {renderFormGroup(form, 'isTitleVisible', {
                label: 'Czy tytuł widoczny?',
                type: 'checkbox',
                onChangeCustom: (field, checked) =>
                  form.setFieldValue('isTitleVisible', checked),
                options: [
                  {
                    label: '',
                    name: 'isTitleVisible',
                    checked: form.values.isTitleVisible,
                  },
                ],
              })}
              {renderFormGroup(form, 'subTitle', {
                label: 'Podtytuł',
                placeholder: 'Wprowadź podtytuł...',
              })}
              {renderFormGroupCustomControl({
                label: 'Zdjęcie wyróżniające',
                renderControl: () => (
                  <>
                    {!mainPhoto && (
                      <StyledSelectFileImage
                        onClick={() =>
                          setFileSelectorModalOptions({
                            isOpen: true,
                            selectedFiles: mainPhoto ? [mainPhoto] : [],
                            onSelect: (file) =>
                              handleOnSelectMainPhoto(file, form),
                          })
                        }
                        isSelected={mainPhoto !== null}
                      >
                        <Icon
                          path={mdiImageSearchOutline}
                          size={2}
                          color="#495057"
                        />
                      </StyledSelectFileImage>
                    )}
                    {mainPhoto && (
                      <StyledWrapperMainPhotoPreview>
                        <FilesImagesDisplay
                          files={[mainPhoto]}
                          onEditClick={() =>
                            setFileSelectorModalOptions({
                              isOpen: true,
                              selectedFiles: mainPhoto ? [mainPhoto] : [],
                              onSelect: (file) =>
                                handleOnSelectMainPhoto(file, form),
                            })
                          }
                          onRemoveClick={() => {
                            form.setFieldValue('mainPhotoId', '');
                            setMainPhoto(null);
                          }}
                        />
                      </StyledWrapperMainPhotoPreview>
                    )}
                    <ErrorMessage
                      show={form.errors.mainPhotoId && form.touched.mainPhotoId}
                    >
                      {form.errors.mainPhotoId}
                    </ErrorMessage>
                  </>
                ),
              })}
              {renderFormGroupCustomControl({
                label: 'Akcje',
                renderControl: () => <AddActions form={form} />,
              })}
              <ErrorMessage show={validationError}>
                {validationError}
              </ErrorMessage>
              <FormButtons onDiscard={onDiscard}></FormButtons>
            </Form>
          );
        }}
      />
      <Modal isOpen={fileSelectorModalOptions.isOpen} fullscreen>
        <FileImageSelector
          selectedFiles={fileSelectorModalOptions.selectedFiles}
          onSelect={fileSelectorModalOptions.onSelect}
          onApprove={fileSelectorModalOptions.onApprove}
          onDiscard={() => setFileSelectorModalOptions({ isOpen: false })}
          type={fileSelectorModalOptions.type || ['image']}
        />
      </Modal>
    </>
  );
};

export default withRouter(AddEditHeroItem);
