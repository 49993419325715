import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DictionaryService from '../../services/dictionaryService';
import FormButtons from '../shared/form/FormButtons';
import { renderFormGroup } from '../shared/form/FormRenderHelpers';
import ErrorMessage from '../shared/form/ErrorMessage';
import handleError from '../../utils/errorHandler';

const Schema = Yup.object().shape({
  value: Yup.string().required('Wartość musi być uzupełniona.'),
});

const EditOtherSetting = ({ onSuccess, onDiscard, history, state }) => {
  const [validationError, setValidationError] = useState(null);

  const updateSetting = async ({ value }) => {
    try {
      await DictionaryService.update(state.key, value, state.version);
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  if (!state) return <></>;

  const initialValues = {
    label: state.label,
    value: state.value,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateSetting}
      validationSchema={Schema}
      render={(form) => {
        return (
          <Form>
            {renderFormGroup(form, 'value', {
              label: form.values.label,
              placeholder: 'Wprowadź wartość...',
            })}
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default EditOtherSetting;
