import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";
import ErrorMessage from "../shared/form/ErrorMessage";
import handleError from "../../utils/errorHandler";
import EnrolmentCoursesService from "../../services/enrolmentCoursesService";

const Schema = Yup.object().shape({
  course: Yup.string().required("Nazwa kierunku musi być uzupełniona."),
  courseSymbol: Yup.string().required("Oznaczenie grupy musi być uzupełnione.")
});

const AddEditEnrolmentCourse = ({
  onSuccess,
  onDiscard,
  history,
  course: courseModel,
  isEdit
}) => {
  const [validationError, setValidationError] = useState(null);

  const addEditEnrolmentCourse = async enrolmentCourse => {
    try {
      if (!isEdit) {
        await EnrolmentCoursesService.add(enrolmentCourse);
      } else {
        await EnrolmentCoursesService.edit(courseModel.id, enrolmentCourse);
      }
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  if (isEdit && !courseModel) {
    return <></>;
  }

  const initialValues = {
    course: isEdit && courseModel ? courseModel.course : "",
    courseSymbol: isEdit && courseModel ? courseModel.courseSymbol : "",
    isActive: isEdit && courseModel ? courseModel.isActive : true
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={addEditEnrolmentCourse}
      validationSchema={Schema}
      render={form => {
        return (
          <Form>
            {renderFormGroup(form, "courseSymbol", {
              label: "Oznaczenie grupy",
              placeholder: "Oznaczenie grupy..."
            })}
            {renderFormGroup(form, "course", {
              label: "Nazwa kierunku",
              placeholder: "Wprowadź nazwę kierunku..."
            })}
            {renderFormGroup(form, "isActive", {
              label: "Czy aktywny?",
              type: "checkbox",
              onChangeCustom: (field, checked) =>
                form.setFieldValue("isActive", checked),
              options: [
                {
                  label: "",
                  name: "isActive",
                  checked: form.values.isActive
                }
              ]
            })}
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default AddEditEnrolmentCourse;
