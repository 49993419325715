import React, { useState, useEffect } from "react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import { Button, Grid } from "tabler-react";
import Icon from "@mdi/react";
import PageHeader from "../layout/PageHeader";
import { mdiPlus, mdiAttachment, mdiClose } from "@mdi/js";
import FilesImagesDisplay from "./FilesImagesDisplay";
import AddFileImages from "./AddFileImages";
import Modal from "../modal/Modal";
import UploadsService from "../../services/uploadsService";
import handleError from "../../utils/errorHandler";
import Loader from "../layout/Loader";
import EditFileImage from "./EditFileImage";
import ConfirmModal from "../modal/ConfirmModal";
import { isImage } from "../../utils/fileUtils";
import Pager from "../pager/Pager";
import FileImagesFilters from "./FileImagesFilters";

const defaults = {
  limit: 21
};

const FileImages = ({ history }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalOptions, setEditModalOptions] = useState({ isOpen: false });
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false
  });
  const [pagingInfo, setPagingInfo] = useState(null);
  const [currentFilters, setCurrentFilters] = useState(null);
  const [previewModalOptions, setPreviewModalOptions] = useState({
    isOpen: false,
    file: null
  });

  const getFiles = async (page, filters) => {
    const files = await UploadsService.getAll(page, defaults.limit, filters);
    return files;
  };

  const fetchFiles = async (page = 1, filters = null) => {
    setIsLoading(true);
    try {
      const files = await getFiles(page, filters);

      setFiles(files.items);
      setPagingInfo({ totalCount: files.count, currentPage: page });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const deleteFileImage = async ({ id }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await UploadsService.delete(id);

      const page =
        files.length === 1 && pagingInfo.currentPage > 1
          ? pagingInfo.currentPage - 1
          : pagingInfo.currentPage;
      await fetchFiles(page);
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleOnChangePage = page => {
    fetchFiles(page, currentFilters);
  };

  const onSearch = ({ name, userId, fileType }) => {
    const filters = {};
    if (name) filters.name = name;
    if (userId) filters.userId = userId;
    if (fileType.length > 0) filters.fileType = fileType;
    setCurrentFilters(filters);
    fetchFiles(pagingInfo.currentPage, filters);
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Pliki i zdjęcia" icon={mdiAttachment}>
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj pliki i zdjęcia
          </Button>
        </PageHeader>
        <FileImagesFilters
          filters={currentFilters}
          onSearch={onSearch}
          onReset={() => {
            setCurrentFilters(null);
            fetchFiles(pagingInfo.currentPage);
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10
          }}
        >
          <div
            style={{
              padding: "0 0 0 5px"
            }}
          >
            Znalezionych plików i zdjęć:{" "}
            <strong>{pagingInfo.totalCount}</strong>
          </div>
          {files.length > 0 && (
            <div style={{ marginLeft: "auto" }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={defaults.limit}
              />
            </div>
          )}
        </div>
        <Grid.Row>
          <FilesImagesDisplay
            files={files}
            onRemoveClick={({ id, name, type }) =>
              setConfirmModalOptions({
                isOpen: true,
                state: { id },
                message: `Czy na pewno chcesz usunąć ${
                  isImage(type) ? "zdjęcie" : "plik"
                }: <strong>${name}</strong>?`
              })
            }
            onEditClick={file =>
              setEditModalOptions({ isOpen: true, state: file })
            }
            onPreviewClick={file => {
              if (isImage(file.type)) {
                setPreviewModalOptions({ isOpen: true, file });
              } else {
                window.open(window.location.origin + file.url, "_blank");
              }
            }}
          />
        </Grid.Row>
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
          {files.length > 0 && (
            <div style={{ marginLeft: "auto" }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={defaults.limit}
              />
            </div>
          )}
        </div>
      </PageContent>
      <Modal isOpen={addModalIsOpen} fullscreen title="Dodaj pliki i zdjęcia">
        <AddFileImages
          onSuccess={() => {
            setAddModalIsOpen(false);
            fetchFiles(1);
          }}
          onDiscard={() => setAddModalIsOpen(false)}
        />
      </Modal>
      <Modal isOpen={editModalOptions.isOpen} title="Edytuj plik/zdjęcie">
        <EditFileImage
          onSuccess={() => {
            setEditModalOptions({ isOpen: false });
            fetchFiles(pagingInfo.currentPage);
          }}
          file={editModalOptions.state}
          onDiscard={() => setEditModalOptions({ isOpen: false })}
        />
      </Modal>
      <Modal isOpen={previewModalOptions.isOpen} title="Podgląd" fullscreen>
        {previewModalOptions.file && (
          <div style={{ textAlign: "center" }}>
            <img
              src={previewModalOptions.file.url}
              style={{ maxWidth: "80%" }}
              alt=""
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20
              }}
            >
              <Button
                type="button"
                color="secondary"
                onClick={() => setPreviewModalOptions({ isOpen: false })}
              >
                <Icon path={mdiClose} size={0.8} color="#495057" />
                Zamknij
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <ConfirmModal
        isOpen={confirmModalOptions.isOpen}
        message={confirmModalOptions.message}
        state={confirmModalOptions.state}
        onConfirm={deleteFileImage}
        onDiscard={() => setConfirmModalOptions({ isOpen: false })}
      ></ConfirmModal>
    </SiteWrapper>
  );
};

export default FileImages;
