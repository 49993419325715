import Axios from "axios";

const UsersService = {
  get: async id => {
    const result = await Axios.get(`/api/users/${id}`);

    return result.data;
  },
  getAll: async () => {
    const result = await Axios.get("/api/users");

    return result.data;
  },
  add: async user => {
    await Axios.post("/api/users", user);
  },
  delete: async id => {
    await Axios.delete(`/api/users/${id}`);
  },
  updateData: async (id, userData) => {
    await Axios.patch(`/api/users/${id}/updateData`, userData);
  },
  updatePassword: async (id, passwordData) => {
    await Axios.patch(`/api/users/${id}/updatePassword`, passwordData);
  }
};

export default UsersService;
