import Axios from 'axios';
const TOKEN = 'nfp_Xsniyt7KX9TY5a31FjYzTxCJNVBFGRWP77ef';

const NetlifyService = {
  getSites: async () => {
    const result = await Axios.get(`https://api.netlify.com/api/v1/sites`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    });

    return result.data;
  },
  getBuilds: async () => {
    const result = await Axios.get(
      `https://api.netlify.com/api/v1/sites/17a0d034-5b37-4e05-92f1-9d31b259e1fd/deploys`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          per_page: 20,
          page: 1,
        },
      }
    );

    return result.data;
  },
  triggerBuild: async () => {
    await Axios.post(
      `https://api.netlify.com/api/v1/sites/17a0d034-5b37-4e05-92f1-9d31b259e1fd/builds`,
      {},
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    );
  },
};

export default NetlifyService;
