import React, { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import {
  renderFormGroup,
  renderFormGroupCustomControl,
} from '../shared/form/FormRenderHelpers';
import FormButtons from '../shared/form/FormButtons';
import { Button } from 'tabler-react';
import Icon from '@mdi/react';
import {
  mdiBookRemoveMultiple,
  mdiChevronDown,
  mdiChevronUp,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js';
import styled from 'styled-components';
import './Tables.css';
import Label from '../shared/form/Label';
import DictionaryService from '../../services/dictionaryService';
import { createGuid } from '../../utils/guidUtils';
import handleError from '../../utils/errorHandler';

const generateId = () => 'id_' + Math.random() + new Date().valueOf();

const StyledRowDefinitionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledTable = styled.table`
  margin: 0 15px;

  th {
    font-size: 14px;
    font-weight: 600;
  }

  th,
  td {
    padding: 5px;
  }
`;

const Schema = Yup.object().shape({
  name: Yup.string()
    .max(300, 'Nazwa tabeli jest za długa. Maksymalna ilość znaków to 300.')
    .required('Nazwa tabeli musi być uzupełniona.'),
});

const AddEditTable = ({ onSuccess, onDiscard, history, tableData, isEdit }) => {
  if (isEdit && !tableData) {
    return <></>;
  }

  const key = isEdit ? tableData.key : `TABLE-${createGuid()}`;

  const tableRef = useRef(null);

  const save = async (values) => {
    const value = { ...values, HTML: tableRef.current.innerHTML };
    try {
      if (isEdit) {
        await DictionaryService.update(
          tableData.key,
          JSON.stringify(value),
          tableData.version
        );
      } else {
        await DictionaryService.add(key, JSON.stringify(value));
      }
      onSuccess();
    } catch (error) {
      handleError(error, history);
    }
  };

  const initialValues = {
    name: isEdit ? tableData.value.name : '',
    showTableName: isEdit ? tableData.value.showTableName : false,
    columns: isEdit
      ? tableData.value.columns
      : {
          firstLine: [
            {
              name: 'column' + new Date().valueOf(),
              value: '',
              specificValues: '',
              center: false,
              bold: false,
            },
          ],
        },
    rows: isEdit ? tableData.value.rows : [],
  };

  const addColumn = (form) => {
    const columns = { ...form.values.columns };

    columns.firstLine.push({
      name: 'column' + new Date().valueOf(),
      value: '',
      specificValues: '',
      center: false,
      bold: false,
    });

    form.setFieldValue('columns', columns);
  };

  const removeColumn = (name, form) => {
    const columns = { ...form.values.columns };
    const removeIndex = columns.firstLine.findIndex((x) => x.name === name);
    columns.firstLine = columns.firstLine.filter((x) => x.name !== name);
    form.setFieldValue('columns', columns);
    const rows = [...form.values.rows];
    rows.forEach((r) => {
      if (r.values[removeIndex] !== undefined) {
        r.values.splice(removeIndex, 1);
      }
    });
    form.setFieldValue('rows', rows);
  };

  const setColumnValue = (field, value, form) => {
    const columns = { ...form.values.columns };

    const column = columns.firstLine.find(
      (x) =>
        x.name ===
        field.name
          .replace('name', '')
          .replace('specificValues', '')
          .replace('center', '')
          .replace('bold', '')
    );

    if (field.name.includes('name')) {
      column.value = value;
    }

    if (field.name.includes('specificValues')) {
      column.specificValues = value;
    }

    if (field.name.includes('center')) {
      column.center = value;
    }

    if (field.name.includes('bold')) {
      column.bold = value;
    }

    form.setFieldValue('columns', columns);
  };

  const addRow = (form) => {
    form.setFieldValue(
      'rows',
      [...form.values.rows].concat([
        {
          id: generateId(),
          values: new Array(form.values.columns.firstLine.length).fill(''),
        },
      ])
    );
  };

  const setRowValue = (rowId, index, value, form) => {
    const rows = [...form.values.rows];

    const row = rows.find((r) => r.id === rowId);

    row.values[index] = value;

    form.setFieldValue('rows', rows);
  };

  const removeRow = (rowId, form) => {
    const rows = [...form.values.rows].filter((row) => row.id != rowId);

    form.setFieldValue('rows', rows);
  };

  const moveUpRow = (rowId, form) => {
    moveRow(rowId, form, -1);
  };

  const moveDownRow = (rowId, form) => {
    moveRow(rowId, form, 1);
  };

  const moveRow = (rowId, form, direction) => {
    const rows = [...form.values.rows];
    const fromIndex = rows.findIndex((x) => x.id === rowId);
    const movedRow = rows.splice(fromIndex, 1)[0];
    rows.splice(fromIndex + direction, 0, movedRow);
    form.setFieldValue('rows', rows);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={save}
        render={(form) => {
          return (
            <Form>
              {renderFormGroup(form, 'name', {
                label: 'Nazwa',
                placeholder: 'Wprowadź nazwę tabeli...',
              })}
              {renderFormGroup(form, 'showTableName', {
                label: 'Pokaż nazwę tabeli',
                type: 'checkbox',
                onChangeCustom: (field, checked) =>
                  form.setFieldValue('showTableName', checked),
                options: [
                  {
                    label: '',
                    name: 'showTableName',
                    checked: form.values.showTableName,
                  },
                ],
              })}
              {renderFormGroupCustomControl({
                label: 'Definicje kolumn',
                renderControl: () => (
                  <div style={{ width: '100%' }}>
                    <StyledTable>
                      <thead>
                        <tr>
                          <th>L. p.</th>
                          <th>Nazwa kolumny</th>
                          <th>Wartości</th>
                          <th>Wycentrowanie</th>
                          <th>Pogrubienie</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.values.columns.firstLine.map((x, index) => (
                          <tr key={x.name}>
                            <td>{index + 1}.</td>
                            <Field name={x.name + 'name'}>
                              {({ field, form }) => (
                                <td>
                                  <input
                                    id={x.name + 'name'}
                                    type="text"
                                    name={x.name + 'name'}
                                    onChange={(e) =>
                                      setColumnValue(
                                        field,
                                        e.target.value,
                                        form
                                      )
                                    }
                                    value={x.value}
                                    className="form-control"
                                  />
                                </td>
                              )}
                            </Field>
                            <Field name={x.name + 'specificValues'}>
                              {({ field, form }) => (
                                <td>
                                  <input
                                    id={x.name + 'specificValues'}
                                    type="text"
                                    name={x.name + 'specificValues'}
                                    onChange={(e) =>
                                      setColumnValue(
                                        field,
                                        e.target.value,
                                        form
                                      )
                                    }
                                    value={x.specificValues}
                                    className="form-control"
                                    placeholder="Wartości"
                                    style={{ minWidth: 300 }}
                                  />
                                </td>
                              )}
                            </Field>
                            <Field name={x.name + 'center'}>
                              {({ field, form }) => (
                                <td>
                                  <label className="custom-control custom-checkbox custom-control-inline">
                                    <input
                                      id={x.name + 'center'}
                                      type="checkbox"
                                      name={x.name + 'center'}
                                      checked={x.center}
                                      onChange={(e) =>
                                        setColumnValue(
                                          field,
                                          e.target.checked,
                                          form
                                        )
                                      }
                                      className="custom-control-input custom-control custom-checkbox custom-control-inline"
                                    />
                                    <span className="custom-control-label"></span>
                                  </label>
                                </td>
                              )}
                            </Field>
                            <Field name={x.name + 'bold'}>
                              {({ field, form }) => (
                                <td>
                                  <label className="custom-control custom-checkbox custom-control-inline">
                                    <input
                                      id={x.name + 'bold'}
                                      type="checkbox"
                                      name={x.name + 'bold'}
                                      checked={x.bold}
                                      onChange={(e) =>
                                        setColumnValue(
                                          field,
                                          e.target.checked,
                                          form
                                        )
                                      }
                                      className="custom-control-input custom-control custom-checkbox custom-control-inline"
                                    />
                                    <span className="custom-control-label"></span>
                                  </label>
                                </td>
                              )}
                            </Field>
                            <td>
                              <Button
                                color="danger"
                                type="button"
                                className="only-icon"
                                onClick={() => removeColumn(x.name, form)}
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </StyledTable>

                    <div>
                      <Button
                        color="success"
                        type="button"
                        className="only-icon"
                        onClick={() => addColumn(form)}
                      >
                        <Icon path={mdiPlus} size={0.8} color="white" />
                      </Button>
                    </div>
                  </div>
                ),
              })}
              {renderFormGroupCustomControl({
                label: 'Wiersze',
                renderControl: () => (
                  <div style={{ width: '100%' }}>
                    <StyledTable>
                      <thead>
                        <tr>
                          {form.values.columns.firstLine.map((x) => (
                            <th key={x.name}>{x.value}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {form.values.rows.map((row, rowIndex) => (
                          <tr key={row.id}>
                            {form.values.columns.firstLine.map((x, index) => (
                              <td key={x.name + row.id}>
                                {(x.specificValues && (
                                  <Field
                                    name={x.name + row.id + '_index' + index}
                                  >
                                    {({ field, form }) => (
                                      <select
                                        id={x.name + row.id + '_index' + index}
                                        name={
                                          x.name + row.id + '_index' + index
                                        }
                                        onChange={(e) =>
                                          setRowValue(
                                            row.id,
                                            index,
                                            e.target.value,
                                            form
                                          )
                                        }
                                        value={row.values[index]}
                                        className="form-control"
                                      >
                                        <option value=""> </option>
                                        {x.specificValues
                                          .split(';')
                                          .map((val) => (
                                            <option key={val} value={val}>
                                              {val}
                                            </option>
                                          ))}
                                      </select>
                                    )}
                                  </Field>
                                )) || (
                                  <Field
                                    name={x.name + row.id + '_index' + index}
                                  >
                                    {({ field, form }) => (
                                      <input
                                        id={x.name + row.id + '_index' + index}
                                        type="text"
                                        name={
                                          x.name + row.id + '_index' + index
                                        }
                                        onChange={(e) =>
                                          setRowValue(
                                            row.id,
                                            index,
                                            e.target.value,
                                            form
                                          )
                                        }
                                        value={row.values[index]}
                                        className="form-control"
                                      />
                                    )}
                                  </Field>
                                )}
                              </td>
                            ))}
                            <td>
                              <Button
                                color="danger"
                                type="button"
                                className="only-icon"
                                onClick={() => removeRow(row.id, form)}
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </td>
                            <td>
                              <Button
                                color="info"
                                type="button"
                                className="only-icon"
                                disabled={rowIndex === 0}
                                onClick={() => moveUpRow(row.id, form)}
                              >
                                <Icon
                                  path={mdiChevronUp}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </td>
                            <td>
                              <Button
                                color="info"
                                type="button"
                                className="only-icon"
                                disabled={
                                  rowIndex === form.values.rows.length - 1
                                }
                                onClick={() => moveDownRow(row.id, form)}
                              >
                                <Icon
                                  path={mdiChevronDown}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </StyledTable>
                    <div>
                      <Button
                        color="success"
                        type="button"
                        className="only-icon"
                        onClick={() => addRow(form)}
                      >
                        <Icon path={mdiPlus} size={0.8} color="white" />
                      </Button>
                    </div>
                  </div>
                ),
              })}
              <Label>Podgląd</Label>
              <div ref={tableRef}>
                <div id={key}>
                  {form.values.showTableName && (
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                        color: '#222222',
                        lineHeight: 2,
                      }}
                    >
                      {form.values.name}
                    </div>
                  )}
                  <table className="table-custom">
                    <thead>
                      <tr>
                        {form.values.columns.firstLine.map((x) => (
                          <th
                            key={x.name}
                            style={{ textAlign: x.center ? 'center' : 'left' }}
                          >
                            {x.value}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {form.values.rows.map((row) => (
                        <tr key={row.id}>
                          {console.log(row)}
                          {row.values.map((val, index) => (
                            <td
                              key={index}
                              className={val ? '' : 'empty'}
                              style={{
                                textAlign: form.values.columns.firstLine[index]
                                  ? form.values.columns.firstLine[index].center
                                    ? 'center'
                                    : 'left'
                                  : 'left',
                                fontWeight: form.values.columns.firstLine[index]
                                  ? form.values.columns.firstLine[index].bold
                                    ? '600'
                                    : 'normal'
                                  : 'normal',
                              }}
                            >
                              <span className="desc">
                                {form.values.columns.firstLine[index] &&
                                  form.values.columns.firstLine[index].value}
                                {': '}
                              </span>
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <FormButtons onDiscard={onDiscard}></FormButtons>
            </Form>
          );
        }}
      />
    </>
  );
};

export default withRouter(AddEditTable);
