import React from "react";
import styled from "styled-components";
import Icon from "@mdi/react";

const PageHeaderWrapper = styled.div.attrs({ className: "page-header" })`
  > div:last-child {
    margin-left: auto;
  }
`;

const StyledPageTitle = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin-top: 6px;
    margin-left: 15px;
  }
`;

const PageHeader = ({ title, icon, children }) => {
  return (
    <PageHeaderWrapper>
      <StyledPageTitle>
        <Icon path={icon} color="#495057" size={1.5} />
        <h1 className="page-title">{title}</h1>
      </StyledPageTitle>
      <div>{children}</div>
    </PageHeaderWrapper>
  );
};

export default PageHeader;
