import React, { useState, useEffect } from "react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import { Grid, Button, Table, Card } from "tabler-react";
import Loader from "../layout/Loader";
import handleError from "../../utils/errorHandler";
import UsersService from "../../services/usersService";
import PageHeader from "../layout/PageHeader";
import Icon from "@mdi/react";
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiAccountGroupOutline
} from "@mdi/js";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import ConfirmModal from "../modal/ConfirmModal";
import Modal from "../modal/Modal";
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";

const StyledButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 10px;
  }
`;

const StyledButtonsCol = styled(Table.Col)`
  width: 100px;
`;

const Users = ({ history }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: ""
  });
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalOptions, setEditModalOptions] = useState({ isOpen: false });

  const getUsers = async () => {
    const users = await UsersService.getAll(1, 1);
    return users;
  };

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const users = await getUsers();

      setUsers(users);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const deleteUser = async ({ id }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await UsersService.delete(id);

      await fetchUsers();
    } catch (error) {
      handleError(error, history);
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Użytkownicy" icon={mdiAccountGroupOutline}>
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Id</Table.ColHeader>
                    <Table.ColHeader>Imię i Nazwisko</Table.ColHeader>
                    <Table.ColHeader>Login</Table.ColHeader>
                    <Table.ColHeader>Rola</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {users.map(user => {
                    return (
                      <Table.Row key={user.id}>
                        <Table.Col>
                          {user.id}{" "}
                          {user.isDeleted && (
                            <strong>
                              <span style={{ color: "red" }}>(USUNIĘTY)</span>
                            </strong>
                          )}
                        </Table.Col>
                        <Table.Col>
                          {user.firstName} {user.lastName}
                        </Table.Col>
                        <Table.Col>{user.login}</Table.Col>
                        <Table.Col>{user.role}</Table.Col>
                        <StyledButtonsCol>
                          {!user.isDeleted && (
                            <StyledButtonsWrapper>
                              <Tooltip title="Edytuj" position="bottom" arrow>
                                <Button
                                  color="secondary"
                                  type="button"
                                  className="only-icon"
                                  onClick={() =>
                                    setEditModalOptions({
                                      isOpen: true,
                                      state: {
                                        id: user.id,
                                        login: user.login,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        role: user.role
                                      }
                                    })
                                  }
                                >
                                  <Icon
                                    path={mdiPencilOutline}
                                    size={0.8}
                                    color="#222222"
                                  />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Usuń" position="bottom" arrow>
                                <Button
                                  color="danger"
                                  className="only-icon"
                                  type="button"
                                  onClick={() =>
                                    setConfirmModalOptions({
                                      isOpen: true,
                                      message: `Czy na pewno chcesz usunąć użytkownika: <strong>${user.firstName} ${user.lastName}</strong>?`,
                                      state: { id: user.id }
                                    })
                                  }
                                >
                                  <Icon
                                    path={mdiTrashCanOutline}
                                    size={0.8}
                                    color="white"
                                  />
                                </Button>
                              </Tooltip>
                            </StyledButtonsWrapper>
                          )}
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={addModalIsOpen} title="Dodaj użytkownika">
          <AddUserForm
            onSuccess={() => {
              setAddModalIsOpen(false);
              fetchUsers();
            }}
            onDiscard={() => setAddModalIsOpen(false)}
          ></AddUserForm>
        </Modal>
        <Modal isOpen={editModalOptions.isOpen} title="Edytuj użytkownika">
          <EditUserForm
            user={editModalOptions.state}
            onSuccessChange={fetchUsers}
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          ></EditUserForm>
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={deleteUser}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default Users;
