import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import Modal from '../../modal/Modal';
import styled from 'styled-components';
import Loader from '../../layout/Loader';
import { Button, StampCard } from 'tabler-react';
import DictionaryService from '../../../services/dictionaryService';

const StyledItems = styled.div`
  overflow-y: auto;
`;

const StyledItem = styled.div`
  .fe-check {
    opacity: 0;
  }

  :hover {
    cursor: pointer;

    .card {
      border: 1px solid #467fcf;

      .stamp {
        background-color: #5eba00 !important;
      }

      .fe-check {
        opacity: 1;
      }
    }
  }
`;

const EditorTable = ({ icon, onEnd }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tables, setTables] = useState(null);

  const handleOnClick = () => {
    setSelectedNode(document.getSelection().anchorNode);
    setIsOpen(true);
  };

  const onSelect = (html) => {
    setIsOpen(false);

    const node = document.createElement('div');
    node.innerHTML = html;

    selectedNode.appendChild(node);

    onEnd();
  };

  const getTables = async () => {
    const tables = await DictionaryService.getAll('TABLE');
    return tables.map((x) => ({ ...x, value: JSON.parse(x.value) }));
  };

  const fetchTables = async () => {
    setIsLoading(true);
    try {
      const tables = await getTables();

      setTables(tables);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      //handleError(error, history);
    }
  };

  useEffect(() => {
    fetchTables();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        color="secondary"
        type="button"
        className="only-icon"
        onClick={handleOnClick}
      >
        <Icon path={icon} size={0.8} color="#222222" />
      </Button>
      <Modal
        isOpen={isOpen}
        style={{ content: { height: '90vh' } }}
        styleInnerContent={{
          display: 'flex',
          flexFlow: 'column',
          height: '100%',
        }}
      >
        <StyledItems>
          {tables.map((x) => (
            <StyledItem key={x.key} onClick={() => onSelect(x.value.HTML)}>
              <StampCard header={x.value.name} color="grey" icon="check" />
            </StyledItem>
          ))}
        </StyledItems>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="button"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            <Icon path={mdiClose} size={0.8} color="#495057" />
            Anuluj
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditorTable;
