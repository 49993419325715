import React from "react";

const ErrorMessage = ({ show, children }) => {
  if (!show) {
    return <></>;
  }

  return <div className="invalid-message">{children}</div>;
};

export default ErrorMessage;
