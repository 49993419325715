import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import routes from "../../routes";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";
import UsersService from "../../services/usersService";
import ErrorMessage from "../shared/form/ErrorMessage";
import { withRouter } from "react-router-dom";
import { Button } from "tabler-react";
import Icon from "@mdi/react";
import { mdiUndo } from "@mdi/js";
import handleError from "../../utils/errorHandler";
import { UserContext } from "../../context/UserContext";

const Schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/,
      "Hasło musi zawierać min. 6 znaków, a w tym conajmniej 1 dużą literę, 1 małą literę, liczbę oraz znak specjalny (!@#$%^&*)"
    )
    .required("Hasło musi być uzupełnione"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Hasła nie pasują do siebie"
  )
});

const EditUserPasswordForm = ({ onDiscard, undo, history, user }) => {
  const [validationError, setValidationError] = useState(null);
  const { logOut, user: userCtx } = useContext(UserContext);

  const editUserPassword = async ({ password }) => {
    try {
      await UsersService.updatePassword(user.id, { password });
      if (user.id === userCtx.id) {
        history.push(routes.login);
        logOut();
      } else {
        onDiscard();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={editUserPassword}
      validationSchema={Schema}
      render={form => {
        return (
          <Form>
            {renderFormGroup(form, "password", {
              label: "Hasło",
              placeholder: "Wprowadź hasło...",
              type: "password"
            })}
            {renderFormGroup(form, "confirmPassword", {
              label: "Powtórz hasło",
              placeholder: "Wprowadź hasło jeszcze raz...",
              type: "password",
              disabled: form.errors.password || !form.touched.password
            })}
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}>
              {undo && (
                <Button type="button" color="secondary" onClick={undo}>
                  <Icon path={mdiUndo} size={0.8} color="#495057" />
                  Cofnij
                </Button>
              )}
            </FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(EditUserPasswordForm);
