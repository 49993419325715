import Axios from "axios";

const FaqService = {
  get: async id => {
    const result = await Axios.get(`/api/faq/${id}`);

    return result.data;
  },
  getAll: async filters => {
    const result = await Axios.get("/api/faq", {
      params: { ...filters }
    });

    return result.data;
  },
  add: async faq => {
    await Axios.post("/api/faq", faq);
  },
  edit: async (id, faq) => {
    await Axios.patch(`/api/faq/${id}`, faq);
  },
  delete: async id => {
    await Axios.delete(`/api/faq/${id}`);
  },
  release: async id => {
    await Axios.patch(`/api/faq/${id}/release`);
  },
  archive: async id => {
    await Axios.patch(`/api/faq/${id}/archive`);
  }
};

export default FaqService;
