import React from "react";
import { Field } from "formik";

const Input = ({
  id,
  type,
  name,
  errors,
  touched,
  disabled = false,
  placeholder = "",
  className = ""
}) => {
  return (
    <Field
      id={id}
      type={type}
      name={name}
      className={`form-control ${className} ${
        errors && touched ? "is-invalid" : ""
      }`}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default Input;
