import Axios from "axios";

const JobOffersService = {
  get: async id => {
    const result = await Axios.get(`/api/jobOffers/${id}`);

    return result.data;
  },
  getAll: async (page, limit, filters) => {
    const result = await Axios.get("/api/jobOffers");

    return result.data;
  },
  edit: async (id, jobOffer) => {
    await Axios.patch(`/api/jobOffers/${id}`, jobOffer);
  },
  changeStatus: async (id, status) => {
    await Axios.patch(`/api/jobOffers/${id}/changeStatus/${status}`);
  }
};

export default JobOffersService;
