import React from 'react';
import { Grid, Button, Card, Table } from 'tabler-react';
import styled from 'styled-components';
import { mdiSync } from '@mdi/js';
import PageHeader from '../layout/PageHeader';
import Loader from '../layout/Loader';
import PageContent from '../layout/PageContent';
import SiteWrapper from '../layout/SiteWrapper';
import NetlifyService from '../../services/netlifyService';
import handleError from '../../utils/errorHandler';
import moment from 'moment';
import { StyledStatus } from '../shared/Status';

const StyledTitle = styled.div`
  color: #9aa0ac;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
`;

const StyledTableRow = styled(Table.Row)`
  th,
  td {
    text-align: center;
  }
`;

const SyncV2 = ({ history }) => {
  const [syncButtonIsDisabled, setSyncButtonIsDisabled] = React.useState(true);
  const [builds, setBuilds] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchBuilds = async () => {
    setIsLoading(true);
    try {
      const builds = await NetlifyService.getBuilds();
      setBuilds(builds);

      if (
        builds.length > 0 &&
        [
          'building',
          'enqueued',
          'uploading',
          'preparing',
          'processing',
        ].includes(builds[0].state)
      ) {
        setSyncButtonIsDisabled(true);
      } else {
        setSyncButtonIsDisabled(false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      handleError(error, history);
    }
  };

  React.useEffect(() => {
    fetchBuilds();
  }, []);

  const handleSync = async () => {
    setSyncButtonIsDisabled(true);
    try {
      await NetlifyService.triggerBuild();

      await fetchBuilds();
    } catch (error) {
      handleError(error, history);
    }
  };

  const getDuration = (build) => {
    if (!build.created_at || !build.published_at) return '—';

    const startTime = moment(build.created_at);
    const stopTime = moment(build.published_at);
    const duration = moment.duration(stopTime.diff(startTime));
    const seconds = duration.asSeconds();
    const minutes = Math.floor(seconds / 60);

    let result = minutes > 0 ? minutes + 'm ' : '';
    result += Math.floor(seconds - minutes * 60) + 's';

    return result;
  };

  const renderStatus = (status) => {
    let value = '';
    let background = '';
    switch (status) {
      case 'ready':
        value = 'Sukces';
        background = '#5eba00';
        break;
      case 'building':
      case 'enqueued':
      case 'uploading':
      case 'preparing':
      case 'processing':
        value = 'W trakcie';
        background = '#467fcf';
        break;
      case 'error':
        value = 'Błąd';
        background = '#cd201f';
        break;
      case 'canceled':
        value = 'Anulowany';
        background = '#343a40';
        break;
      case 'new':
        value = 'Nowy';
        background = '#343a40';
        break;
      default:
        value = '???';
        background = '#343a40';
        break;
    }

    return <StyledStatus style={{ background }}>{value}</StyledStatus>;
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Synchronizacja (NOWA)" icon={mdiSync}></PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <StyledTitle>Synchronizacja (nowa)</StyledTitle>
                </Card.Title>
                <Card.Options>
                  <div style={{ marginRight: 15 }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={async () => {
                        await fetchBuilds();
                      }}
                    >
                      Odśwież
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="success"
                      type="button"
                      disabled={syncButtonIsDisabled}
                      onClick={handleSync}
                    >
                      Synchronizuj
                    </Button>
                  </div>
                </Card.Options>
              </Card.Header>
            </Card>
            <div>
              <strong>Ostatnie 20 synchronizacji:</strong>
            </div>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <StyledTableRow>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>
                      Data rozpoczęcia synchronizacji
                    </Table.ColHeader>
                    <Table.ColHeader>
                      Data zakończenia synchronizacji
                    </Table.ColHeader>
                    <Table.ColHeader>Czas trwania</Table.ColHeader>
                  </StyledTableRow>
                </Table.Header>
                <Table.Body>
                  {builds &&
                    builds.map((build) => (
                      <StyledTableRow key={build.id}>
                        <Table.Col>{renderStatus(build.state)}</Table.Col>
                        <Table.Col>
                          {build.created_at
                            ? moment(build.created_at).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : '—'}
                        </Table.Col>
                        <Table.Col>
                          {build.published_at
                            ? moment(build.published_at).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : '—'}
                        </Table.Col>
                        <Table.Col>{getDuration(build)}</Table.Col>
                      </StyledTableRow>
                    ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </PageContent>
    </SiteWrapper>
  );
};

export default SyncV2;
