import * as React from "react";

const Error403 = props => {
  return (
    <div
      className="page text-center"
      style={{ margin: "0 auto", height: "60vh" }}
    >
      <div className="container">
        <h1 className="h1 mt-0 mb-4 display-1 text-muted mb-5">Błąd 403</h1>
        <h2 className="h2 mt-0 mb-4">Oops... Coś poszło nie tak...</h2>
        <h4 className="h4 mt-0 mb-4 text-muted font-weight-normal mb-7">
          Nie masz uprawnień do tej sekcji.
        </h4>
        <button
          onClick={() => props.history.push("/")}
          className="btn btn-primary"
        >
          <i className="fe fe-arrow-left mr-2" />
          Strona główna
        </button>
      </div>
    </div>
  );
};

export default Error403;
