import * as React from "react";

const Error500 = props => {
  return (
    <div
      className="page text-center"
      style={{ margin: "0 auto", height: "60vh" }}
    >
      <div className="container">
        <h1 className="h1 mt-0 mb-4 display-1 text-muted mb-5">Błąd 500</h1>
        <h2 className="h2 mt-0 mb-4">Oops... Coś poszło nie tak...</h2>
        <h4 className="h4 mt-0 mb-4 text-muted font-weight-normal mb-7">
          Napotkano problem w aplikacji, proszę skontaktuj się z
          administratorem.
        </h4>
      </div>
    </div>
  );
};

export default Error500;
