import React, { useState } from 'react';
import Icon from '@mdi/react';
import Modal from '../../modal/Modal';
import { Button } from 'tabler-react';
import FormButtons from '../form/FormButtons';
import { replaceSelectedText } from './EditorHelper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { renderFormGroup } from '../form/FormRenderHelpers';
import Input from '../form/Input';

const Schema = Yup.object().shape({
  link: Yup.string().required('Adres linka musi być uzupełniony'),
});

const EditorAnchorLink = ({ icon, onEnd }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = useState('');
  const selectedRange = React.useRef(null);

  const handleOnClick = () => {
    selectedRange.current = document.getSelection().getRangeAt(0);
    setIsOpen(true);
  };

  const onConfirm = () => {
    setIsOpen(false);

    const wrapper = document.createElement('a');
    wrapper.setAttribute('href', value);
    setValue('');

    replaceSelectedText(selectedRange.current, wrapper);
    onEnd();
  };

  return (
    <>
      <Button
        color="secondary"
        type="button"
        className="only-icon"
        onClick={handleOnClick}
      >
        <Icon path={icon} size={0.8} color="#222222" />
      </Button>
      <Modal isOpen={isOpen}>
        <input
          name="link"
          className="form-control"
          placeholder="Wprowadź adres www..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <FormButtons
          submitOptions={{ onClick: onConfirm }}
          onDiscard={() => setIsOpen(false)}
        ></FormButtons>
      </Modal>
    </>
  );
};

export default EditorAnchorLink;
