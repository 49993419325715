import React, { useEffect, createContext, useState } from "react";
import AccountService from "../services/accountService";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await AccountService.getUserInfo();
      if (userInfo) {
        setUser(userInfo);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    fetchUserInfo();

    const cookies = document.cookie.split("=");
    const index = cookies.findIndex(x => x === "authExpirationDate");

    if (index !== -1) {
      const expirationDate = decodeURIComponent(cookies[index + 1]);
      setUser(u => ({ ...u, expirationDate }));
    }
  }, []);

  const logIn = async (login, password) => {
    const result = await AccountService.login(login, password);
    setUser(result.data);
    setIsAuthenticated(true);
  };

  const logOut = async () => {
    setUser(null);
    setIsAuthenticated(false);
    try {
      await AccountService.logout();
    } catch (error) {}
  };

  const extendSession = async () => {
    await AccountService.extendSession();

    const cookies = document.cookie.split("=");
    const index = cookies.findIndex(x => x === "authExpirationDate");

    if (index !== -1) {
      const expirationDate = decodeURIComponent(cookies[index + 1]);
      setUser(u => ({ ...u, expirationDate }));
    }
  };

  const context = {
    user,
    logIn,
    logOut,
    isAuthenticated,
    extendSession
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
