import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid } from "tabler-react";
import { withRouter } from "react-router-dom";
import handleError from "../../utils/errorHandler";
import Loader from "../layout/Loader";
import UploadsService from "../../services/uploadsService";
import Pager from "../pager/Pager";
import FilesImagesDisplay from "../file-images/FilesImagesDisplay";
import FormButtons from "../shared/form/FormButtons";

const StyledFilesDisplayWrapper = styled.div`
  margin-top: 15px;
  height: calc(100vh - 210px);
  overflow-y: auto;
`;

const limit = 24;

const FileImageSelector = ({
  onDiscard,
  history,
  onSelect,
  selectedFiles,
  onApprove,
  type
}) => {
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagingInfo, setPagingInfo] = useState(null);

  const getFiles = async (page, filters) => {
    const files = await UploadsService.getAll(page, limit, filters);
    return files;
  };

  const fetchFiles = async (page = 1, filters = { fileType: type }) => {
    setIsLoading(true);
    try {
      const files = await getFiles(page, filters);

      setFiles(files.items);
      setPagingInfo({ totalCount: files.count, currentPage: page });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const handleOnChangePage = page => {
    fetchFiles(page);
  };

  if (!selectedFiles || !onSelect) {
    return <></>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 10
        }}
      >
        <div
          style={{
            padding: "0 0 0 5px"
          }}
        >
          Wybranych zdjęć <strong>{selectedFiles.length}</strong> ze wszystkich
          znalezionych <strong>{pagingInfo.totalCount}</strong>.
        </div>
        {files.length > 0 && (
          <div style={{ marginLeft: "auto" }}>
            <Pager
              onClick={handleOnChangePage}
              totalCount={pagingInfo.totalCount}
              currentPage={pagingInfo.currentPage}
              limit={limit}
            />
          </div>
        )}
      </div>
      <Grid.Row>
        <StyledFilesDisplayWrapper>
          <FilesImagesDisplay
            files={files}
            selectedFiles={selectedFiles}
            onSelectClick={onSelect}
          />
        </StyledFilesDisplayWrapper>
      </Grid.Row>
      <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
        {files.length > 0 && (
          <div style={{ marginLeft: "auto" }}>
            <Pager
              onClick={handleOnChangePage}
              totalCount={pagingInfo.totalCount}
              currentPage={pagingInfo.currentPage}
              limit={limit}
            />
          </div>
        )}
      </div>
      <FormButtons
        submitOptions={{
          onClick: () => onApprove(selectedFiles),
          type: "button",
          label: "Zatwierdź",
          disabled: selectedFiles.length === 0
        }}
        onDiscard={onDiscard}
      ></FormButtons>
    </>
  );
};

export default withRouter(FileImageSelector);
