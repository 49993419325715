import * as React from "react";

const Error400 = props => {
  console.log(props);
  return (
    <div
      className="page text-center"
      style={{ margin: "0 auto", height: "60vh" }}
    >
      <div className="container">
        <h1 className="h1 mt-0 mb-4 display-1 text-muted mb-5">Błąd 400</h1>
        <h4 className="h4 mt-0 mb-4 text-muted font-weight-normal mb-7">
          {props.location.state.message}
        </h4>
        <button
          onClick={() => props.history.push(props.location.state.prevLocation)}
          className="btn btn-primary"
        >
          <i className="fe fe-arrow-left mr-2" />
          Wróć
        </button>
      </div>
    </div>
  );
};

export default Error400;
