import React, { useState, useEffect } from 'react';
import SiteWrapper from '../layout/SiteWrapper';
import PageContent from '../layout/PageContent';
import { Grid, Button, Table, Card } from 'tabler-react';
import Loader from '../layout/Loader';
import handleError from '../../utils/errorHandler';
import PageHeader from '../layout/PageHeader';
import Icon from '@mdi/react';
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiInformationOutline,
  mdiFileCheckOutline,
  mdiFileCancelOutline,
  mdiStarOutline,
  mdiChevronUp,
  mdiChevronDown,
  mdiCheck,
  mdiClose,
} from '@mdi/js';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import ConfirmModal from '../modal/ConfirmModal';
import Modal from '../modal/Modal';
import Status from '../shared/Status';
import moment from 'moment';
import StyledLink from '../shared/StyledLink';
import HeroItemsService from '../../services/heroItemsService';
import PostListImage from '../posts/PostListImage';
import AddEditHeroItem from './AddEditHeroItem';

const StyledButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 10px;
  }
`;

const StyledButtonsCol = styled(Table.Col)`
  width: 100px;
`;

const StyledAction = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  > div:nth-child(2) {
    margin-left: auto;
  }

  > div:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const moveDir = {
  UP: 'UP',
  DOWN: 'DOWN',
};

const HeroItems = ({ history }) => {
  const [heroItems, setHeroItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: '',
  });
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalOptions, setEditModalOptions] = useState({ isOpen: false });

  const getHeroItems = async () => {
    const heroItems = await HeroItemsService.getAll(null);
    return heroItems;
  };

  const fetchHeroItems = async () => {
    setIsLoading(true);
    try {
      const heroItems = await getHeroItems();
      setHeroItems(heroItems);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchHeroItems();
  }, []);

  const deleteHeroItem = async ({ id }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await HeroItemsService.delete(id);

      await fetchHeroItems();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleRelease = async (id) => {
    try {
      await HeroItemsService.release(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchHeroItems();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleArchive = async (id) => {
    try {
      await HeroItemsService.archive(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchHeroItems();
    } catch (error) {
      handleError(error, history);
    }
  };

  const moveHeroItem = async (dir, id) => {
    try {
      if (dir === moveDir.UP) {
        await HeroItemsService.moveUp(id);
      } else {
        await HeroItemsService.moveDown(id);
      }
      setConfirmModalOptions({ isOpen: false });
      await fetchHeroItems();
    } catch (error) {
      handleError(error, history);
    }
  };

  const renderStatusButton = (heroItem) => {
    let title = '';
    let message = '';
    let onConfirmHandler = null;
    let color = '';
    let icon = null;

    switch (heroItem.status) {
      case 'Szkic':
        title = 'Opublikuj';
        message = `Czy na pewno chcesz zmienić status na <strong>Opublikowany</strong>?`;
        onConfirmHandler = () => handleRelease(heroItem.id);
        color = 'primary';
        icon = mdiFileCheckOutline;
        break;
      case 'Opublikowany':
        title = 'Archiwizuj';
        message = `Czy na pewno chcesz zmienić status na <strong>Zarchwizowany</strong>?`;
        onConfirmHandler = () => handleArchive(heroItem.id);
        color = 'warning';
        icon = mdiFileCancelOutline;
        break;
      case 'Zarchiwizowany':
        title = 'Opublikuj ponownie';
        message = `Czy na pewno chcesz przywrócić status <strong>Opublikowany</strong>?`;
        onConfirmHandler = () => handleRelease(heroItem.id);
        color = 'gray-dark';
        icon = mdiFileCheckOutline;
        break;
      default:
        break;
    }

    return (
      <Tooltip title={title} position="bottom" arrow>
        <Button
          color={color}
          type="button"
          className="only-icon"
          onClick={() =>
            setConfirmModalOptions({
              isOpen: true,
              message,
              state: { id: heroItem.id },
              onConfirmHandler,
            })
          }
        >
          <Icon path={icon} size={0.8} color="white" />
        </Button>
      </Tooltip>
    );
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Wyróżnione" icon={mdiStarOutline}>
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>
                      Kolejność <br />
                      wyświetlania
                    </Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader>Tytuł</Table.ColHeader>
                    <Table.ColHeader>Czy tytuł widoczny?</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Data modyfikacji</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {heroItems.map((heroItem, index) => {
                    return (
                      <Table.Row key={heroItem.id}>
                        <Table.Col>
                          <StyledAction>
                            <div>{index + 1}</div>
                            <div>
                              <Tooltip
                                title="Przesuń do góry"
                                position="bottom"
                                arrow
                              >
                                <Button
                                  color="info"
                                  type="button"
                                  className="only-icon"
                                  disabled={
                                    heroItems[0].orderIndex ===
                                    heroItem.orderIndex
                                  }
                                  onClick={() =>
                                    setConfirmModalOptions({
                                      isOpen: true,
                                      message: `Czy na pewno chcesz przesunąć do góry?`,
                                      onConfirmHandler: () =>
                                        moveHeroItem(moveDir.UP, heroItem.id),
                                    })
                                  }
                                >
                                  <Icon
                                    path={mdiChevronUp}
                                    size={0.8}
                                    color="white"
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                title="Przesuń do dołu"
                                position="bottom"
                                arrow
                              >
                                <Button
                                  color="info"
                                  type="button"
                                  className="only-icon"
                                  disabled={
                                    heroItems[heroItems.length - 1]
                                      .orderIndex === heroItem.orderIndex
                                  }
                                  onClick={() =>
                                    setConfirmModalOptions({
                                      isOpen: true,
                                      message: `Czy na pewno chcesz przesunąć do dołu?`,
                                      onConfirmHandler: () =>
                                        moveHeroItem(moveDir.DOWN, heroItem.id),
                                    })
                                  }
                                >
                                  <Icon
                                    path={mdiChevronDown}
                                    size={0.8}
                                    color="white"
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          </StyledAction>
                        </Table.Col>
                        <Table.Col>
                          <PostListImage url={heroItem.mainPhoto.url} />
                        </Table.Col>
                        <Table.Col>
                          <StyledLink
                            onClick={() =>
                              setEditModalOptions({
                                isOpen: true,
                                state: { ...heroItem },
                              })
                            }
                          >
                            <strong>{heroItem.title}</strong>
                          </StyledLink>
                        </Table.Col>
                        <Table.Col>
                          {heroItem.isTitleVisible ? (
                            <Icon path={mdiCheck} size={0.8} color="#222222" />
                          ) : (
                            <Icon path={mdiClose} size={0.8} color="#222222" />
                          )}
                        </Table.Col>
                        <Table.Col>
                          <Status value={heroItem.status} />
                        </Table.Col>
                        <Table.Col>
                          {moment(heroItem.createdDate).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Table.Col>
                        <Table.Col>
                          {moment(heroItem.updatedDate).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Table.Col>
                        <Table.Col>
                          {heroItem.updatedByName}{' '}
                          <Tooltip
                            title={`Utworzone przez: <strong>${heroItem.createdByName}</strong>`}
                            position="bottom"
                            arrow
                          >
                            <Icon
                              path={mdiInformationOutline}
                              color="lightblue"
                              size={0.8}
                              style={{ paddingTop: 5 }}
                            />
                          </Tooltip>
                        </Table.Col>

                        <StyledButtonsCol>
                          <StyledButtonsWrapper>
                            {renderStatusButton(heroItem)}

                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setEditModalOptions({
                                    isOpen: true,
                                    state: { ...heroItem },
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                className="only-icon"
                                type="button"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć: <strong>${heroItem.title}</strong>?`,
                                    state: { id: heroItem.id },
                                    onConfirmHandler: deleteHeroItem,
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </StyledButtonsWrapper>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={addModalIsOpen} title="Dodawanie" fullscreen>
          <AddEditHeroItem
            onSuccess={() => {
              setAddModalIsOpen(false);
              fetchHeroItems();
            }}
            onDiscard={() => setAddModalIsOpen(false)}
          />
        </Modal>
        <Modal isOpen={editModalOptions.isOpen} title="Edycja" fullscreen>
          <AddEditHeroItem
            isEdit
            heroItem={editModalOptions.state}
            onSuccess={() => {
              setEditModalOptions({ isOpen: false });
              fetchHeroItems();
            }}
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={confirmModalOptions.onConfirmHandler}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default HeroItems;
