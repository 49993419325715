import React from "react";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import Icon from "@mdi/react";
import {
  mdiChevronLeft,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronRight,
  mdiDotsHorizontal
} from "@mdi/js";
import { Button } from "tabler-react";

const StyledPagerWrapper = styled.div`
  display: flex;
`;

const StyledCustomButton = styled(Button)`
  margin-right: 5px;
`;

const Pager = ({ totalCount, currentPage, limit, onClick }) => {
  const numberOfItems = Math.ceil(totalCount / limit);

  let items = [];
  let leftEllipsis = false;
  let rightEllipsis = false;
  if (numberOfItems > 5) {
    items = [currentPage];

    leftEllipsis = true;
    rightEllipsis = true;
    if (currentPage === 1) {
      leftEllipsis = false;
    } else if (currentPage - 1 === 1) {
      leftEllipsis = false;
      items = [currentPage - 1, ...items];
    } else {
      leftEllipsis = true;
      items = [currentPage - 1, ...items];
    }

    if (currentPage === numberOfItems) {
      rightEllipsis = false;
    } else if (currentPage + 1 === numberOfItems) {
      rightEllipsis = false;
      items = [...items, currentPage + 1];
    } else {
      rightEllipsis = true;
      items = [...items, currentPage + 1];
    }
  } else {
    for (let i = 1; i <= numberOfItems; i++) {
      items = [...items, i];
    }
  }

  const handleOnClick = item => {
    if (item !== currentPage) {
      onClick(item);
    }
  };

  return (
    <StyledPagerWrapper>
      <Tooltip
        title="Pierwsza strona"
        bottom
        arrow
        disabled={currentPage === 1}
      >
        <StyledCustomButton
          color="secondary"
          type="button"
          className="only-icon"
          disabled={currentPage === 1}
          onClick={() => handleOnClick(1)}
        >
          <Icon path={mdiChevronDoubleLeft} size={1} />
        </StyledCustomButton>
      </Tooltip>
      <Tooltip
        title="Poprzednia strona"
        bottom
        arrow
        disabled={currentPage === 1}
      >
        <StyledCustomButton
          color="secondary"
          type="button"
          className="only-icon"
          disabled={currentPage === 1}
          onClick={() => handleOnClick(currentPage - 1)}
        >
          <Icon path={mdiChevronLeft} size={1} />
        </StyledCustomButton>
      </Tooltip>
      {leftEllipsis && (
        <div style={{ marginRight: 5, paddingTop: 10 }}>
          <Icon path={mdiDotsHorizontal} size={1} />
        </div>
      )}
      {items.map(item => {
        return (
          <StyledCustomButton
            color={currentPage === item ? "primary" : "secondary"}
            type="button"
            className="only-icon"
            key={item}
            onClick={() => handleOnClick(item)}
            isCurrent={currentPage === item}
          >
            {item}
          </StyledCustomButton>
        );
      })}
      {rightEllipsis && (
        <div style={{ marginRight: 5, paddingTop: 10 }}>
          <Icon path={mdiDotsHorizontal} size={1} />
        </div>
      )}
      <Tooltip
        title="Następna strona"
        bottom
        arrow
        disabled={currentPage === numberOfItems}
      >
        <StyledCustomButton
          color="secondary"
          type="button"
          className="only-icon"
          disabled={currentPage === numberOfItems}
          onClick={() => handleOnClick(currentPage + 1)}
        >
          <Icon path={mdiChevronRight} size={1} />
        </StyledCustomButton>
      </Tooltip>
      <Tooltip
        title="Ostatnia strona"
        bottom
        arrow
        disabled={currentPage === numberOfItems}
      >
        <StyledCustomButton
          color="secondary"
          type="button"
          className="only-icon"
          disabled={currentPage === numberOfItems}
          onClick={() => handleOnClick(numberOfItems)}
          style={{ marginRight: 0 }}
        >
          <Icon path={mdiChevronDoubleRight} size={1} />
        </StyledCustomButton>
      </Tooltip>
    </StyledPagerWrapper>
  );
};

export default Pager;
