import React from "react";
import styled from "styled-components";

const StyledMessage = styled.div`
  text-align: center;
  padding: 20px;
`;

const ListNoDataMessage = ({ message = "Nie znaleziono danych." }) => {
  return <StyledMessage>{message}</StyledMessage>;
};

export default ListNoDataMessage;
