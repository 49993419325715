import Axios from "axios";

const EnrolmentsService = {
  getAll: async () => {
    const result = await Axios.get("/api/enrolments");

    return result.data;
  },
  delete: async id => {
    await Axios.delete(`/api/enrolments/${id}`);
  }
};

export default EnrolmentsService;
