import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import FormButtons from "../shared/form/FormButtons";
import ErrorMessage from "../shared/form/ErrorMessage";
import handleError from "../../utils/errorHandler";
import styled from "styled-components";
import { Button } from "tabler-react";
import FilesImagesDisplay from "./FilesImagesDisplay";
import Icon from "@mdi/react";
import { mdiCloudUploadOutline } from "@mdi/js";
import Loader from "../layout/Loader";
import { loadFiles } from "../../utils/fileUtils";
import UploadsService from "../../services/uploadsService";
import { withRouter } from "react-router-dom";

const StyledInputFileVisible = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;

  svg {
    fill: rgba(0, 0, 0, 0.3);
  }

  > div:nth-child(2) {
    color: rgba(0, 0, 0, 0.3);
    font-size: 30px;
    font-weight: 600;
  }

  > div:nth-child(3) {
    color: rgba(0, 0, 0, 0.3);
    font-size: 20px;
    font-weight: 600;
  }

  > div:nth-child(4) {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;

const StyledInputFile = styled(Field)`
  opacity: 0;
  height: 250px;
  width: 100%;
  position: relative;
  z-index: 2;
`;

const StyledFileWrapper = styled.div`
  height: 250px;
  width: 100%;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  position: relative;

  :hover {
    border: 2px dashed #467fcf;

    ${StyledInputFile} {
      cursor: pointer;
    }
  }
`;

const StyledFilesDisplayWrapper = styled.div`
  margin-top: 15px;
  height: calc(100vh - 430px);
  overflow-y: auto;
`;

const AddFileImages = ({ onSuccess, onDiscard, history }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const handleOnSubmit = async () => {
    setIsLoading(true);

    const filesToSend = files.map(file => {
      return {
        id: file.id,
        name: file.name,
        type: file.type,
        dataUrl: file.url
      };
    });

    try {
      await UploadsService.batchInsert(filesToSend);
      setIsLoading(false);
      onSuccess();
    } catch (error) {
      setIsLoading(false);
      console.log(error.response.data);
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else if (error.response && error.response.status === 413) {
        setValidationError(
          "Przynajmniej jeden z plików jest za duży. Spróbuj załadować pliki pojedynczo. Jeśli się nie uda - skontaktuj się z administratorem."
        );
      } else {
        handleError(error, history);
      }
    }
  };

  const handleOnFilesChange = async e => {
    setIsLoading(true);
    const selectedFiles = [...e.target.files];
    try {
      const result = await loadFiles(selectedFiles, {
        imageConfig: { maxHeight: 1280, maxWidth: 1280 }
      });
      setFiles([...files, ...result]);
      setValidationError(null);
    } catch (error) {
      setValidationError(error);
    }

    setIsLoading(false);
  };

  const handleOnRemove = file => {
    setFiles(files.filter(x => x.id !== file.id));
  };

  if (isLoading) {
    return <Loader />;
  }

  console.log(files);

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleOnSubmit}
      render={form => {
        return (
          <Form>
            <div className="form-group">
              <StyledFileWrapper>
                <StyledInputFile
                  type="file"
                  name="files"
                  id="files"
                  accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                  onChange={handleOnFilesChange}
                  multiple
                  formik
                />
                <StyledInputFileVisible>
                  <Icon path={mdiCloudUploadOutline} size={3} />
                  <div>Przeciągnij pliki tutaj</div>
                  <div>lub</div>
                  <div>
                    <Button
                      className="button-header"
                      color="primary"
                      onClick={() => {}}
                    >
                      Wybierz z dysku
                    </Button>
                  </div>
                </StyledInputFileVisible>
              </StyledFileWrapper>
              {files.length > 0 && (
                <div style={{ marginTop: 15 }}>
                  Wybrano plików: <strong>{files.length}</strong>.
                </div>
              )}
              <StyledFilesDisplayWrapper>
                <FilesImagesDisplay
                  files={files}
                  onRemoveClick={handleOnRemove}
                />
              </StyledFilesDisplayWrapper>
            </div>
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(AddFileImages);
