import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import {
  renderFormGroup,
  renderFormGroupCustomControl
} from "../shared/form/FormRenderHelpers";
import handleError from "../../utils/errorHandler";
import Editor from "../shared/Editor/Editor";
import { withRouter } from "react-router-dom";
import JobOffersService from "../../services/jobOffersService";

const Schema = Yup.object().shape({
  companyName: Yup.string()
    .max(200, "Nazwa firmy nie może przekraczać 200 znaków")
    .required("Nazwa firmy musi być uzupełniona"),
  email: Yup.string()
    .max(320, "Email nie może przekraczać 320 znaków")
    .required("Email musi być uzupełniony"),
  position: Yup.string()
    .max(100, "Nazwa stanowiska nie może przekraczać 100 znaków")
    .required("Stanowisko musi być uzupełnione"),
  address: Yup.string()
    .max(300, "Adres nie może przekraczać 300 znaków")
    .required("Adres musi być uzupełniony")
});

const EditJobOfferDetails = ({ onSuccess, onDiscard, history, jobOffer }) => {
  const saveJobOffer = async ({
    companyName,
    email,
    position,
    address,
    content
  }) => {
    try {
      await JobOffersService.edit(jobOffer.id, {
        ...jobOffer,
        companyName,
        email,
        position,
        address,
        content
      });
      onSuccess();
    } catch (error) {
      handleError(error, history);
    }
  };

  const initialValues = {
    companyName: jobOffer.companyName,
    email: jobOffer.email,
    position: jobOffer.position,
    address: jobOffer.address,
    content: jobOffer.content
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={saveJobOffer}
        render={form => {
          return (
            <Form>
              {renderFormGroup(form, "companyName", {
                label: "Nazwa firmy"
              })}
              {renderFormGroup(form, "email", {
                label: "Email"
              })}
              {renderFormGroup(form, "position", {
                label: "Stanowisko"
              })}
              {renderFormGroup(form, "address", {
                label: "Adres"
              })}
              {renderFormGroupCustomControl({
                label: "Treść ogłoszenia",
                renderControl: () => (
                  <Editor
                    settings={{
                      formats: [
                        "bold",
                        "underline",
                        "italic",
                        "strikeThrough",
                        "justifyLeft",
                        "justifyCenter",
                        "justifyRight",
                        "justifyFull",
                        "insertOrderedList",
                        "insertUnorderedList",
                        "color"
                      ]
                    }}
                    content={form.values.content}
                    onChange={content => form.setFieldValue("content", content)}
                  />
                )
              })}
              <FormButtons onDiscard={onDiscard}></FormButtons>
            </Form>
          );
        }}
      />
    </>
  );
};

export default withRouter(EditJobOfferDetails);
