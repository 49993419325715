import React from "react";
import { Formik, Form } from "formik";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroupCustomControl } from "../shared/form/FormRenderHelpers";
import Editor from "../shared/Editor/Editor";

const EditEnrolmentEmailContent = ({ onOk, onDiscard, content }) => {
  return (
    <Formik
      initialValues={{ content }}
      onSubmit={onOk}
      render={form => {
        return (
          <Form>
            {renderFormGroupCustomControl({
              label: "Treść",
              renderControl: () => (
                <Editor
                  settings={{
                    formats: [
                      "bold",
                      "underline",
                      "italic",
                      "strikeThrough",
                      "justifyLeft",
                      "justifyCenter",
                      "justifyRight",
                      "justifyFull",
                      "insertOrderedList",
                      "insertUnorderedList",
                      "color"
                    ]
                  }}
                  content={form.values.content}
                  onChange={content => form.setFieldValue("content", content)}
                />
              )
            })}
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default EditEnrolmentEmailContent;
