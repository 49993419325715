import React, { useState, useEffect } from 'react';
import SiteWrapper from '../layout/SiteWrapper';
import PageContent from '../layout/PageContent';
import { Grid, Button, Table, Card } from 'tabler-react';
import Loader from '../layout/Loader';
import handleError from '../../utils/errorHandler';
import PageHeader from '../layout/PageHeader';
import Icon from '@mdi/react';
import {
  mdiTrashCanOutline,
  mdiPlus,
  mdiNewspaperPlus,
  mdiBookOpenPageVariant,
} from '@mdi/js';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import ConfirmModal from '../modal/ConfirmModal';
import Modal from '../modal/Modal';
import DictionaryService from '../../services/dictionaryService';
import ListNoDataMessage from '../shared/ListNoDataMessage';
import moment from 'moment';
import AddBlogPostCategory from './AddBlogPostCategory';

const StyledButtonsCol = styled(Table.Col)`
  width: 75px;

  > div {
    text-align: center;
  }
`;

const BlogPostCategories = ({ history }) => {
  const [blogPostCategories, setBlogPostCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: '',
  });
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  const getBlogPostCategories = async () => {
    const blogPostCategories = await DictionaryService.getAll(
      'BLOG-POST-CATEGORY'
    );
    return blogPostCategories;
  };

  const fetchBlogPostCategories = async () => {
    setIsLoading(true);
    try {
      const postCategories = await getBlogPostCategories();

      setBlogPostCategories(postCategories);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchBlogPostCategories();
  }, []);

  const deleteBlogPostCategory = async ({ key }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await DictionaryService.delete(key);

      fetchBlogPostCategories();
    } catch (error) {
      handleError(error, history);
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Kategorie blog" icon={mdiBookOpenPageVariant}>
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Kategoria</Table.ColHeader>
                    <Table.ColHeader>Utworzone przez</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {blogPostCategories.map((blogPostCategory) => {
                    return (
                      <Table.Row key={blogPostCategory.key}>
                        <Table.Col>{blogPostCategory.value}</Table.Col>
                        <Table.Col>{blogPostCategory.createdByName}</Table.Col>
                        <Table.Col>
                          {moment(blogPostCategory.createdDate).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Table.Col>
                        <StyledButtonsCol>
                          <div>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć kategorię: <strong>${blogPostCategory.value}</strong>?`,
                                    state: { key: blogPostCategory.key },
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {blogPostCategories.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={addModalIsOpen} title="Dodaj kategorię">
          <AddBlogPostCategory
            onSuccess={() => {
              setAddModalIsOpen(false);
              fetchBlogPostCategories();
            }}
            onDiscard={() => setAddModalIsOpen(false)}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={deleteBlogPostCategory}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default BlogPostCategories;
