import React, { useState, useEffect } from "react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import { Grid, Button, Table, Card } from "tabler-react";
import Loader from "../layout/Loader";
import handleError from "../../utils/errorHandler";
import PageHeader from "../layout/PageHeader";
import Icon from "@mdi/react";
import {
  mdiTrashCanOutline,
  mdiPlus,
  mdiHelpCircleOutline,
  mdiFileCheckOutline,
  mdiFileCancelOutline,
  mdiPencilOutline
} from "@mdi/js";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import ConfirmModal from "../modal/ConfirmModal";
import Modal from "../modal/Modal";
import ListNoDataMessage from "../shared/ListNoDataMessage";
import moment from "moment";
import FaqService from "../../services/faqService";
import AddEditFaq from "./AddEditFaq";
import Status from "../shared/Status";
import StyledLink from "../shared/StyledLink";

const StyledButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 10px;
  }
`;

const StyledButtonsCol = styled(Table.Col)`
  width: 100px;
`;

const Faq = ({ history }) => {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: ""
  });
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalOptions, setEditModalOptions] = useState({ isOpen: false });

  const getFaqs = async () => {
    const faqs = await FaqService.getAll();
    return faqs;
  };

  const fetchFaqs = async () => {
    setIsLoading(true);
    try {
      const faqs = await getFaqs();

      setFaqs(faqs);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const deleteFaq = async ({ id }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await FaqService.delete(id);

      await fetchFaqs();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleRelease = async (id, title) => {
    try {
      await FaqService.release(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchFaqs();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleArchive = async id => {
    try {
      await FaqService.archive(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchFaqs();
    } catch (error) {
      handleError(error, history);
    }
  };

  const renderStatusButton = faq => {
    let title = "";
    let message = "";
    let onConfirmHandler = null;
    let color = "";
    let icon = null;

    switch (faq.status) {
      case "Szkic":
        title = "Opublikuj";
        message = `Czy na pewno chcesz zmienić status na <strong>Opublikowane</strong>?`;
        onConfirmHandler = () => handleRelease(faq.id);
        color = "primary";
        icon = mdiFileCheckOutline;
        break;
      case "Opublikowane":
        title = "Archiwizuj";
        message = `Czy na pewno chcesz zmienić status na <strong>Zarchwizowane</strong>?`;
        onConfirmHandler = () => handleArchive(faq.id);
        color = "warning";
        icon = mdiFileCancelOutline;
        break;
      case "Zarchiwizowane":
        title = "Opublikuj ponownie";
        message = `Czy na pewno chcesz przywrócić status <strong>Opublikowane</strong>?`;
        onConfirmHandler = () => handleRelease(faq.id);
        color = "gray-dark";
        icon = mdiFileCheckOutline;
        break;
      default:
        break;
    }
    return (
      <Tooltip title={title} position="bottom" arrow>
        <Button
          color={color}
          type="button"
          className="only-icon"
          onClick={() =>
            setConfirmModalOptions({
              isOpen: true,
              message,
              state: { id: faq.id },
              onConfirmHandler
            })
          }
        >
          <Icon path={icon} size={0.8} color="white" />
        </Button>
      </Tooltip>
    );
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader
          title="FAQ (Najczęściej zadawane pytania)"
          icon={mdiHelpCircleOutline}
        >
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Pytanie</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Utworzone przez</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader>Data modyfikacji</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {faqs.map(faq => {
                    return (
                      <Table.Row key={faq.id}>
                        <Table.Col>
                          <StyledLink
                            onClick={() =>
                              setEditModalOptions({
                                isOpen: true,
                                state: { ...faq }
                              })
                            }
                          >
                            <strong>{faq.question}</strong>
                          </StyledLink>
                        </Table.Col>
                        <Table.Col>
                          <Status value={faq.status} />
                        </Table.Col>
                        <Table.Col>{faq.createdByName}</Table.Col>
                        <Table.Col>
                          {moment(faq.createdDate).format("YYYY-MM-DD HH:mm")}
                        </Table.Col>
                        <Table.Col>{faq.updatedByName}</Table.Col>
                        <Table.Col>
                          {moment(faq.updatedDate).format("YYYY-MM-DD HH:mm")}
                        </Table.Col>
                        <StyledButtonsCol>
                          <StyledButtonsWrapper>
                            {renderStatusButton(faq)}
                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setEditModalOptions({
                                    isOpen: true,
                                    state: { ...faq }
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                className="only-icon"
                                type="button"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć pytanie: <strong>${faq.question}</strong>?`,
                                    state: { id: faq.id },
                                    onConfirmHandler: deleteFaq
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </StyledButtonsWrapper>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {faqs.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={addModalIsOpen} fullscreen title="Dodaj pytanie">
          <AddEditFaq
            onSuccess={() => {
              setAddModalIsOpen(false);
              fetchFaqs();
            }}
            onDiscard={() => setAddModalIsOpen(false)}
          />
        </Modal>
        <Modal
          isOpen={editModalOptions.isOpen}
          fullscreen
          title="Edytuj pytanie"
        >
          <AddEditFaq
            isEdit
            faq={editModalOptions.state}
            onSuccess={() => {
              setEditModalOptions({ isOpen: false });
              fetchFaqs();
            }}
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={confirmModalOptions.onConfirmHandler}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default Faq;
