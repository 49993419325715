import React, { useState, useEffect } from 'react';
import SiteWrapper from '../layout/SiteWrapper';
import PageContent from '../layout/PageContent';
import { Grid, Button, Table, Card } from 'tabler-react';
import Loader from '../layout/Loader';
import handleError from '../../utils/errorHandler';
import PageHeader from '../layout/PageHeader';
import Icon from '@mdi/react';
import {
  mdiTrashCanOutline,
  mdiPlus,
  mdiNewspaperPlus,
  mdiBookOpenPageVariant,
  mdiPencilOutline,
  mdiCogs,
} from '@mdi/js';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import ConfirmModal from '../modal/ConfirmModal';
import Modal from '../modal/Modal';
import DictionaryService from '../../services/dictionaryService';
import ListNoDataMessage from '../shared/ListNoDataMessage';
import moment from 'moment';
import EditOtherSetting from './EditOtherSetting';

const StyledButtonsCol = styled(Table.Col)`
  width: 75px;

  > div {
    text-align: center;
  }
`;

const OtherSettings = ({ history }) => {
  const [otherSettings, setOtherSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editModalOptions, setEditModalOptions] = React.useState({
    isOpen: false,
  });
  const getSettings = async () => {
    const settings = await DictionaryService.getAll('OTHER-SETTINGS');
    return settings;
  };

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const settings = await getSettings();

      setOtherSettings(settings);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Inne ustawienia" icon={mdiCogs}></PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Opis</Table.ColHeader>
                    <Table.ColHeader>Wartość</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader>Data zmodyfikowania</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {otherSettings.map((os) => {
                    return (
                      <Table.Row key={os.key}>
                        <Table.Col>{os.description}</Table.Col>
                        <Table.Col>{os.value}</Table.Col>
                        <Table.Col>{os.updatedByName}</Table.Col>
                        <Table.Col>
                          {moment(os.updatedDate).format('YYYY-MM-DD HH:mm')}
                        </Table.Col>
                        <StyledButtonsCol>
                          <div>
                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setEditModalOptions({
                                    isOpen: true,
                                    state: {
                                      key: os.key,
                                      value: os.value,
                                      label: os.description,
                                      version: os.version,
                                    },
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {otherSettings.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={editModalOptions.isOpen} title="Edytuj">
          <EditOtherSetting
            state={editModalOptions.state}
            onSuccess={() => {
              setEditModalOptions({ isOpen: false });
              fetchSettings();
            }}
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          />
        </Modal>
      </PageContent>
    </SiteWrapper>
  );
};

export default OtherSettings;
