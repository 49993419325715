import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";
import { createGuid } from "../../utils/guidUtils";
import { mdiLink } from "@mdi/js";
import LinkSelector from "../link-selector/LinkSelector";

const Schema = Yup.object().shape({
  name: Yup.string().required("Nazwa musi być uzupełniona."),
  href: Yup.string()
});

const AddEditMenuItem = ({ onConfirm, isEdit, state, onDiscard }) => {
  const [linkModalIsOpen, setLinkModalOpen] = useState(false);

  if (isEdit && !state) {
    return <></>;
  }

  const onSubmit = ({ name, href }) => {
    onConfirm({ name, href, id: createGuid() });
  };

  const initialValues = {
    name: isEdit ? state.name : "",
    href: isEdit ? state.href : ""
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Schema}
      render={form => {
        return (
          <Form>
            {renderFormGroup(form, "name", {
              label: "Nazwa",
              placeholder: "Wprowadź nazwę..."
            })}
            {renderFormGroup(form, "href", {
              label: "Adres",
              placeholder: "Wprowadź lub wybierz adres",
              inputButton: {
                icon: mdiLink,
                onClick: () => setLinkModalOpen(true)
              }
            })}
            {linkModalIsOpen && (
              <LinkSelector
                isOpen={linkModalIsOpen}
                setHref={href => form.setFieldValue("href", href)}
                onDiscard={() => setLinkModalOpen(false)}
              />
            )}
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default AddEditMenuItem;
