import React, { useContext } from "react";
import { Route, Redirect } from "react-router";
import routes from "../../routes";
import { UserContext } from "../../context/UserContext";
import Loader from "../layout/Loader";
import LoginPage from "../shared-pages/LoginPage";

const PrivateRoute = ({ location: { pathname }, ...props }) => {
  const { isAuthenticated } = useContext(UserContext);
  if (isAuthenticated === null) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Route {...props} />;
  } else {
    return (
      <Redirect
        //to={routes.login}
        to={{ pathname: routes.login, state: { ref: pathname } }}
        component={LoginPage}
      />
    );
  }
};

export default PrivateRoute;
