import React from "react";
import Loader from "../layout/Loader";
import PageHeader from "../layout/PageHeader";
import { Grid, Button, Card } from "tabler-react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import Icon from "@mdi/react";
import {
  mdiMenu,
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiChevronUp,
  mdiChevronDown,
  mdiPlus,
  mdiSubdirectoryArrowRight
} from "@mdi/js";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import ConfirmModal from "../modal/ConfirmModal";
import Modal from "../modal/Modal";
import AddEditMenuItem from "./AddEditMenuItem";
import DictionaryService from "../../services/dictionaryService";
import handleError from "../../utils/errorHandler";

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
`;

const StyledAction = styled.div`
  background: rgba(0, 0, 0, 0.02);
  height: 60px;
  padding: 0 20px;
  border-right: 1px solid #dee2e6;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledItem = styled.div`
  font-weight: 600;
  padding: 0 10px;
`;

const StyledButtons = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    text-align: center;

    button {
      margin-right: 15px;
    }
  }
`;

const ChildrenRow = styled.div`
  display: flex;
  border-bottom: 1px solid #dee2e6;
  margin-left: 69px;
  border-left: 1px solid #dee2e6;
  display: flex;
  align-items: center;

  > div:nth-child(2) {
    border-left: 1px solid #dee2e6;
  }
`;

const StyledSubDirectoryIcon = styled.div`
  padding: 0 20px;
`;

const Menu = ({ history }) => {
  const [menu, setMenu] = React.useState(null);
  const [currentVersion, setCurrentVersion] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = React.useState({
    isOpen: false,
    message: ""
  });
  const [addModalOptions, setAddModalOptions] = React.useState({
    isOpen: false
  });
  const [editModalOptions, setEditModalOptions] = React.useState({
    isOpen: false
  });

  const getMenuData = async () => {
    const menuStructure = await DictionaryService.get("MENU-STRUCTURE");
    setCurrentVersion(menuStructure.version);
    return JSON.parse(menuStructure.value);
  };

  const saveMenuData = async menuToSave => {
    await DictionaryService.update(
      "MENU-STRUCTURE",
      JSON.stringify(menuToSave),
      currentVersion
    );
  };

  const fetchMenu = async () => {
    setIsLoading(true);
    try {
      const menuStructure = await getMenuData();

      setMenu(menuStructure);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  React.useEffect(() => {
    fetchMenu();
  }, []);

  const deleteNode = async (id, parentNodeId) => {
    let newMenu = [...menu];

    if (parentNodeId === null) {
      newMenu = newMenu.filter(node => node.id !== id);
    } else {
      newMenu.forEach(node => {
        if (node.id === parentNodeId) {
          node.children = node.children.filter(cnode => cnode.id !== id);
        }
      });
    }

    setConfirmModalOptions({ isOpen: false });
    await saveMenuData(newMenu);
    await fetchMenu();
  };

  const moveDir = {
    UP: "up",
    DOWN: "down"
  };

  const moveNode = async (dir, id, parentNodeId) => {
    const newMenu = [...menu];

    if (parentNodeId === null) {
      const index = newMenu.findIndex(node => node.id === id);
      if (dir === moveDir.UP) {
        [newMenu[index - 1], newMenu[index]] = [
          newMenu[index],
          newMenu[index - 1]
        ];
      } else {
        [newMenu[index], newMenu[index + 1]] = [
          newMenu[index + 1],
          newMenu[index]
        ];
      }
    } else {
      newMenu.forEach(node => {
        if (node.id === parentNodeId) {
          const index = node.children.findIndex(cnode => cnode.id === id);
          if (dir === moveDir.UP) {
            [node.children[index - 1], node.children[index]] = [
              node.children[index],
              node.children[index - 1]
            ];
          } else {
            [node.children[index], node.children[index + 1]] = [
              node.children[index + 1],
              node.children[index]
            ];
          }
        }
      });
    }

    setConfirmModalOptions({ isOpen: false });
    await saveMenuData(newMenu);
    await fetchMenu();
  };

  const addNode = async (node, parentNodeId) => {
    const newMenu = [...menu];

    if (parentNodeId === null) {
      newMenu.push({ ...node, children: [] });
    } else {
      newMenu.forEach(n => {
        if (n.id === parentNodeId) {
          n.children.push(node);
        }
      });
    }

    setAddModalOptions({ isOpen: false });
    await saveMenuData(newMenu);
    await fetchMenu();
  };

  const editNode = async (node, id, parentNodeId) => {
    const newMenu = [...menu];

    if (parentNodeId === null) {
      const index = newMenu.findIndex(n => n.id === id);
      newMenu[index].name = node.name;
      newMenu[index].href = node.href;
    } else {
      newMenu.forEach(n => {
        if (n.id === parentNodeId) {
          const index = n.children.findIndex(x => x.id === id);
          n.children[index].name = node.name;
          n.children[index].href = node.href;
        }
      });
    }

    setEditModalOptions({ isOpen: false });
    await saveMenuData(newMenu);
    await fetchMenu();
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Konfiguracja menu" icon={mdiMenu}>
          <Button
            color="success"
            onClick={() =>
              setAddModalOptions({ isOpen: true, parentNodeId: null })
            }
          >
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              {menu.map((x, xindex) => (
                <div key={x.id}>
                  <StyledRow>
                    <StyledAction>
                      <Tooltip title="Przesuń do góry" position="bottom" arrow>
                        <Button
                          color="info"
                          type="button"
                          className="only-icon"
                          disabled={xindex === 0}
                          onClick={() =>
                            setConfirmModalOptions({
                              isOpen: true,
                              message: `Czy na pewno chcesz przesunąć pozycję menu: <strong>${x.name}</strong> do góry?`,
                              onConfirmHandler: () =>
                                moveNode(moveDir.UP, x.id, null)
                            })
                          }
                        >
                          <Icon path={mdiChevronUp} size={0.8} color="white" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Przesuń do dołu" position="bottom" arrow>
                        <Button
                          color="info"
                          type="button"
                          className="only-icon"
                          disabled={xindex === menu.length - 1}
                          onClick={() =>
                            setConfirmModalOptions({
                              isOpen: true,
                              message: `Czy na pewno chcesz przesunąć pozycję menu: <strong>${x.name}</strong> do dołu?`,
                              onConfirmHandler: () =>
                                moveNode(moveDir.DOWN, x.id, null)
                            })
                          }
                        >
                          <Icon
                            path={mdiChevronDown}
                            size={0.8}
                            color="white"
                          />
                        </Button>
                      </Tooltip>
                    </StyledAction>
                    <StyledItem>
                      <div>{x.name}</div>
                    </StyledItem>
                    <StyledButtons>
                      <Tooltip title="Dodaj" position="bottom" arrow>
                        <Button
                          color="success"
                          type="button"
                          className="only-icon"
                          onClick={() =>
                            setAddModalOptions({
                              isOpen: true,
                              parentNodeId: x.id
                            })
                          }
                        >
                          <Icon path={mdiPlus} size={0.8} color="white" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Edytuj" position="bottom" arrow>
                        <Button
                          color="secondary"
                          type="button"
                          className="only-icon"
                          onClick={() =>
                            setEditModalOptions({
                              isOpen: true,
                              state: { name: x.name, href: x.href, id: x.id },
                              parentNodeId: null
                            })
                          }
                        >
                          <Icon
                            path={mdiPencilOutline}
                            size={0.8}
                            color="#222222"
                          />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Usuń" position="bottom" arrow>
                        <Button
                          color="danger"
                          className="only-icon"
                          type="button"
                          onClick={() =>
                            setConfirmModalOptions({
                              isOpen: true,
                              message: `Czy na pewno chcesz usunąć pozycję menu: <strong>${x.name}</strong>?`,
                              onConfirmHandler: () => deleteNode(x.id, null)
                            })
                          }
                        >
                          <Icon
                            path={mdiTrashCanOutline}
                            size={0.8}
                            color="white"
                          />
                        </Button>
                      </Tooltip>
                    </StyledButtons>
                  </StyledRow>
                  <div>
                    {x.children.map((c, index) => (
                      <ChildrenRow key={c.id}>
                        <StyledSubDirectoryIcon>
                          <Icon
                            path={mdiSubdirectoryArrowRight}
                            size={0.8}
                            color="#222222"
                          />
                        </StyledSubDirectoryIcon>
                        <StyledAction>
                          <Tooltip
                            title="Przesuń do góry"
                            position="bottom"
                            arrow
                          >
                            <Button
                              color="info"
                              type="button"
                              className="only-icon"
                              disabled={index === 0}
                              onClick={() =>
                                setConfirmModalOptions({
                                  isOpen: true,
                                  message: `Czy na pewno chcesz przesunąć pozycję menu: <strong>${c.name}</strong> do góry?`,
                                  onConfirmHandler: () =>
                                    moveNode(moveDir.UP, c.id, x.id)
                                })
                              }
                            >
                              <Icon
                                path={mdiChevronUp}
                                size={0.8}
                                color="white"
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Przesuń do dołu"
                            position="bottom"
                            arrow
                          >
                            <Button
                              color="info"
                              type="button"
                              className="only-icon"
                              disabled={index === x.children.length - 1}
                              onClick={() =>
                                setConfirmModalOptions({
                                  isOpen: true,
                                  message: `Czy na pewno chcesz przesunąć pozycję menu: <strong>${c.name}</strong> do dołu?`,
                                  onConfirmHandler: () =>
                                    moveNode(moveDir.DOWN, c.id, x.id)
                                })
                              }
                            >
                              <Icon
                                path={mdiChevronDown}
                                size={0.8}
                                color="white"
                              />
                            </Button>
                          </Tooltip>
                        </StyledAction>
                        <StyledItem>{c.name}</StyledItem>
                        <StyledButtons>
                          <Tooltip title="Edytuj" position="bottom" arrow>
                            <Button
                              color="secondary"
                              type="button"
                              className="only-icon"
                              onClick={() =>
                                setEditModalOptions({
                                  isOpen: true,
                                  state: {
                                    name: c.name,
                                    href: c.href,
                                    id: c.id
                                  },
                                  parentNodeId: x.id
                                })
                              }
                            >
                              <Icon
                                path={mdiPencilOutline}
                                size={0.8}
                                color="#222222"
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Usuń" position="bottom" arrow>
                            <Button
                              color="danger"
                              className="only-icon"
                              type="button"
                              onClick={() =>
                                setConfirmModalOptions({
                                  isOpen: true,
                                  message: `Czy na pewno chcesz usunąć pozycję menu: <strong>${c.name}</strong>?`,
                                  onConfirmHandler: () => deleteNode(c.id, x.id)
                                })
                              }
                            >
                              <Icon
                                path={mdiTrashCanOutline}
                                size={0.8}
                                color="white"
                              />
                            </Button>
                          </Tooltip>
                        </StyledButtons>
                      </ChildrenRow>
                    ))}
                  </div>
                </div>
              ))}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={addModalOptions.isOpen} title="Dodaj pozycję">
          <AddEditMenuItem
            onSuccess={() => {
              setAddModalOptions({ isOpen: false });
            }}
            onConfirm={node => addNode(node, addModalOptions.parentNodeId)}
            onDiscard={() => setAddModalOptions({ isOpen: false })}
          />
        </Modal>
        <Modal isOpen={editModalOptions.isOpen} title="Edytuj pozycję">
          <AddEditMenuItem
            onSuccess={() => {
              setEditModalOptions({ isOpen: false });
            }}
            state={editModalOptions.state}
            isEdit
            onConfirm={node =>
              editNode(
                node,
                editModalOptions.state.id,
                editModalOptions.parentNodeId
              )
            }
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          onConfirm={confirmModalOptions.onConfirmHandler}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default Menu;
