import React, { useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import Modal from '../../modal/Modal';
import { Button, Form } from 'tabler-react';
import styled from 'styled-components';
import { replaceSelectedText } from './EditorHelper';

const StyledColors = styled.div`
  .col {
    margin-right: 5px;
  }

  .bg-black {
    background-color: black;
  }
`;

const EditorColor = ({ icon, onEnd }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const selectedRange = React.useRef(null);

  const handleOnClick = () => {
    selectedRange.current = document.getSelection().getRangeAt(0);
    setIsOpen(true);
  };

  const onSelect = (color) => {
    console.log(color);
    setIsOpen(false);

    const wrapper = document.createElement('span');
    wrapper.style = 'color: ' + color;

    replaceSelectedText(selectedRange.current, wrapper);
    onEnd();
  };

  console.log(selectedRange.current);

  return (
    <>
      <Button
        color="secondary"
        type="button"
        className="only-icon"
        onClick={handleOnClick}
      >
        <Icon path={icon} size={0.8} color="#222222" />
      </Button>
      <Modal isOpen={isOpen}>
        <StyledColors>
          <Form.Group label="Wybierz kolor">
            <Form.ColorCheck>
              <Form.ColorCheckItem
                onClick={() => onSelect('initial')}
                color="black"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#45aaf2')}
                color="azure"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#6574cd')}
                color="indigo"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#a55eea')}
                color="purple"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#f66d9b')}
                color="pink"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#cd201f')}
                color="red"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#fd9644')}
                color="orange"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#7bd235')}
                color="lime"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#5eba00')}
                color="green"
              />
              <Form.ColorCheckItem
                onClick={() => onSelect('#2bcbba')}
                color="teal"
              />
            </Form.ColorCheck>
          </Form.Group>
        </StyledColors>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="button"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            <Icon path={mdiClose} size={0.8} color="#495057" />
            Anuluj
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditorColor;
