import React from "react";

const InputGroup = ({ renderLabel, children, renderError }) => {
  return (
    <div className="form-group">
      {renderLabel && renderLabel()}
      <div className="input-group">{children}</div>
      {renderError && renderError()}
    </div>
  );
};

export default InputGroup;
