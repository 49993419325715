import React, { useState, useEffect } from "react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import { Grid, Button, Table, Card } from "tabler-react";
import Loader from "../layout/Loader";
import handleError from "../../utils/errorHandler";
import PageHeader from "../layout/PageHeader";
import Icon from "@mdi/react";
import { mdiTrashCanOutline, mdiPlus, mdiNewspaperPlus } from "@mdi/js";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import ConfirmModal from "../modal/ConfirmModal";
import Modal from "../modal/Modal";
import DictionaryService from "../../services/dictionaryService";
import ListNoDataMessage from "../shared/ListNoDataMessage";
import AddPostCategory from "./AddPostCategory";
import moment from "moment";

const StyledButtonsCol = styled(Table.Col)`
  width: 75px;

  > div {
    text-align: center;
  }
`;

const PostCategories = ({ history }) => {
  const [postCategories, setPostCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: ""
  });
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  const getPostCategories = async () => {
    const postCategories = await DictionaryService.getAll("POST-CATEGORY");
    return postCategories;
  };

  const fetchPostCategories = async () => {
    setIsLoading(true);
    try {
      const postCategories = await getPostCategories();

      setPostCategories(postCategories);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchPostCategories();
  }, []);

  const deletePostCategory = async ({ key }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await DictionaryService.delete(key);

      fetchPostCategories();
    } catch (error) {
      handleError(error, history);
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Kategorie aktualności" icon={mdiNewspaperPlus}>
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Kategoria</Table.ColHeader>
                    <Table.ColHeader>Utworzone przez</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {postCategories.map(postCategory => {
                    return (
                      <Table.Row key={postCategory.key}>
                        <Table.Col>{postCategory.value}</Table.Col>
                        <Table.Col>{postCategory.createdByName}</Table.Col>
                        <Table.Col>
                          {moment(postCategory.createdDate).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Table.Col>
                        <StyledButtonsCol>
                          <div>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć kategorię: <strong>${postCategory.value}</strong>?`,
                                    state: { key: postCategory.key }
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {postCategories.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={addModalIsOpen} title="Dodaj kategorię">
          <AddPostCategory
            onSuccess={() => {
              setAddModalIsOpen(false);
              fetchPostCategories();
            }}
            onDiscard={() => setAddModalIsOpen(false)}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={deletePostCategory}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default PostCategories;
