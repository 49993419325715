import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DictionaryService from '../../services/dictionaryService';
import FormButtons from '../shared/form/FormButtons';
import { renderFormGroup } from '../shared/form/FormRenderHelpers';
import ErrorMessage from '../shared/form/ErrorMessage';
import handleError from '../../utils/errorHandler';
import slugify from 'slugify';

const Schema = Yup.object().shape({
  categoryName: Yup.string().required('Nazwa kategorii musi być uzupełniona.'),
});

const AddPostCategory = ({ onSuccess, onDiscard, history }) => {
  const [validationError, setValidationError] = useState(null);

  const addPostCategory = async ({ categoryName }) => {
    try {
      await DictionaryService.add(
        `POST-CATEGORY-${slugify(categoryName, {
          remove: /[*+~.()/'"!:;@]/g,
        }).toUpperCase()}`,
        categoryName,
        1
      );
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  const initialValues = {
    categoryName: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={addPostCategory}
      validationSchema={Schema}
      render={(form) => {
        return (
          <Form>
            {renderFormGroup(form, 'categoryName', {
              label: 'Nazwa kategorii',
              placeholder: 'Wprowadź nazwę kategorii...',
            })}
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default AddPostCategory;
