export const getPathWithParam = (
  route,
  value,
  parameterPlaceholder = ':id'
) => {
  return route.replace(parameterPlaceholder, value);
};

export default {
  home: '/',
  syncv2: '/sync-v2',
  login: '/zaloguj',
  changePassword: '/zmiana-hasla',
  users: '/uzytkownicy',
  userRoles: '/role-uzytkownikow',
  postCategories: '/kategorie-aktualnosci',
  openingHours: '/godziny-otwarcia',
  error500: '/blad-serwera',
  error403: '/blad-uprawnien',
  error404: '/blad-nie-znaleziono-strony',
  error400: '/blad',
  filesImages: '/pliki-i-zdjecia',
  posts: '/aktualnosci',
  blogPostCategories: '/kategorie-blog',
  blogPosts: '/blog',
  faq: '/faq',
  menu: '/menu',
  pages: '/podstrony',
  hero: '/wyroznione',
  enrolmentCourses: '/zapisy-kierunki',
  enrolmentEmailSettings: '/zapisy-ustawienia-email',
  enrolments: '/zapisy-osoby',
  jobOffers: '/ogloszenia-o-prace',
  tables: '/tabele',
  otherSettings: '/inne-ustawienia',
};
