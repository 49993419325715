import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import {
  renderFormGroup,
  renderFormGroupCustomControl
} from "../shared/form/FormRenderHelpers";
import ErrorMessage from "../shared/form/ErrorMessage";
import handleError from "../../utils/errorHandler";
import Editor from "../shared/Editor/Editor";
import { withRouter } from "react-router-dom";
import FaqService from "../../services/faqService";

const Schema = Yup.object().shape({
  question: Yup.string()
    .max(300, "Pytanie jest za długie. Maksymalna ilość znaków to 300.")
    .required("Pytanie musi być uzupełnione.")
});

const AddEditFaq = ({ onSuccess, onDiscard, history, faq, isEdit }) => {
  if (isEdit && !faq) {
    return <></>;
  }

  const [validationError, setValidationError] = useState(null);

  const saveFaq = async faqModel => {
    try {
      if (isEdit) {
        await FaqService.edit(faq.id, faqModel);
      } else {
        await FaqService.add(faqModel);
      }
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  const initialValues = {
    question: isEdit ? faq.question : "",
    content: isEdit ? faq.content : ""
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={saveFaq}
        render={form => {
          return (
            <Form>
              {renderFormGroup(form, "question", {
                label: "Pytanie",
                placeholder: "Wprowadź pytanie..."
              })}
              {renderFormGroupCustomControl({
                label: "Odpowiedź",
                renderControl: () => (
                  <Editor
                    content={form.values.content}
                    onChange={content => form.setFieldValue("content", content)}
                  />
                )
              })}
              <ErrorMessage show={validationError}>
                {validationError}
              </ErrorMessage>
              <FormButtons onDiscard={onDiscard}></FormButtons>
            </Form>
          );
        }}
      />
    </>
  );
};

export default withRouter(AddEditFaq);
