import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import DictionaryService from "../../services/dictionaryService";
import FormButtons from "../shared/form/FormButtons";
import ErrorMessage from "../shared/form/ErrorMessage";
import handleError from "../../utils/errorHandler";
import { Form as TbForm } from "tabler-react";
import InputGroup from "../shared/form/InputGroup";
import Label from "../shared/form/Label";
import { withRouter } from "react-router-dom";

const EditOpeningHours = ({ onSuccess, onDiscard, history, state }) => {
  const [validationError, setValidationError] = useState(null);

  const updateOpeningHours = async ({ from, to }) => {
    const value = from && to ? `${from} - ${to}` : "nieczynne";

    try {
      await DictionaryService.update(state.key, value, state.version);
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  if (!state) return <></>;

  const initialValues = {
    from: state.from,
    to: state.to
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateOpeningHours}
      render={({ values }) => {
        return (
          <Form>
            <InputGroup renderLabel={() => <Label id="from">Od</Label>}>
              <Field name="from">
                {({ field, form }) => (
                  <TbForm.MaskedInput
                    id="from"
                    mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                    onChange={e => form.setFieldValue("from", e.target.value)}
                    value={values.from}
                    className="form-control"
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup renderLabel={() => <Label id="from">Do</Label>}>
              <Field name="to">
                {({ field, form }) => (
                  <TbForm.MaskedInput
                    id="to"
                    mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                    onChange={e => form.setFieldValue("to", e.target.value)}
                    value={values.to}
                    className="form-control"
                  />
                )}
              </Field>
            </InputGroup>

            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(EditOpeningHours);
