import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormButtons from '../shared/form/FormButtons';
import {
  renderFormGroup,
  renderFormGroupCustomControl,
} from '../shared/form/FormRenderHelpers';
import ErrorMessage from '../shared/form/ErrorMessage';
import handleError from '../../utils/errorHandler';
import slugify from 'slugify';
import Loader from '../layout/Loader';
import DictionaryService from '../../services/dictionaryService';
import Modal from '../modal/Modal';
import FileImageSelector from '../file-image-selector/FileImageSelector';
import styled from 'styled-components';
import {
  mdiImageSearchOutline,
  mdiImageFilter,
  mdiPlus,
  mdiAttachment,
} from '@mdi/js';
import Icon from '@mdi/react';
import FilesImagesDisplay from '../file-images/FilesImagesDisplay';
import AttachmentsList from '../shared/AttachmentsList';
import Editor from '../shared/Editor/Editor';
import PostsService from '../../services/postsService';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import BlogPostsService from '../../services/blogPostsService';

const StyledSelectFileImage = styled.div`
  display: block;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: ${({ isSelected }) => (isSelected && '200px') || '122px'};
  width: ${({ isSelected }) => (isSelected && '200px') || '122px'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;

  :hover {
    cursor: pointer;
    border: 1px solid #6574cd;
  }
`;

const StyledWrapperMainPhotoPreview = styled.div`
  > div > div {
    margin: 0;
  }
`;

const StyledWrapperPhotosPreview = styled.div`
  > div {
    justify-content: flex-start;

    > div {
      margin: 0;
      margin-right: 5px;
      margin-top: 5px;

      > div {
        width: 100px;
        height: 100px;
      }
    }
  }
`;

const Schema = Yup.object().shape({
  title: Yup.string()
    .max(300, 'Podany tytuł jest za długi. Maksymalna ilość znaków to 300.')
    .required('Tytuł musi być uzupełniony.'),
  mainPhotoId: Yup.string().required('Obrazek wyróżniający musi być wybrany.'),
  visibleDate: Yup.string().required('Data musi być wybrana.'),
  categoryKey: Yup.string().required('Kategoria musi być wybrana.'),
});

const AddEditBlogPost = ({
  onSuccess,
  onDiscard,
  history,
  blogPost,
  isEdit,
}) => {
  if (isEdit && !blogPost) {
    return <></>;
  }

  const [validationError, setValidationError] = useState(null);
  const [categories, setCategories] = useState(null);
  const [fileSelectorModalOptions, setFileSelectorModalOptions] = useState({
    isOpen: false,
  });
  const [mainPhoto, setMainPhoto] = useState(
    isEdit ? blogPost.mainPhoto : null
  );
  const [photos, setPhotos] = useState(
    isEdit && blogPost.photos ? JSON.parse(blogPost.photos) : []
  );
  const [attachments, setAttachments] = useState(
    isEdit && blogPost.attachments ? JSON.parse(blogPost.attachments) : []
  );

  const saveBlogPost = async (postModel) => {
    const postToSend = { ...postModel };
    postToSend.slug = slugify(postToSend.title.toLowerCase(), {
      remove: /[*+~.()/'"!:;@]/g,
    });
    postToSend.visibleDate = moment(postToSend.visibleDate).format(
      'YYYY-MM-DD'
    );

    try {
      if (isEdit) {
        await BlogPostsService.edit(blogPost.id, postToSend);
      } else {
        await BlogPostsService.add(postToSend);
      }
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  React.useEffect(() => {
    const fetchCategories = async () => {
      try {
        const blogPostCategories = await DictionaryService.getAll(
          'BLOG-POST-CATEGORY'
        );
        setCategories(blogPostCategories);
      } catch (error) {
        handleError(error, history);
      }
    };
    fetchCategories();
  }, []);

  const handleOnApproveSelectMultipleAttachments = (files, form) => {
    setAttachments(files);
    form.setFieldValue(
      'attachments',
      JSON.stringify(
        files.map((x) => ({ id: x.id, name: x.name, url: x.url, type: x.type }))
      )
    );
    setFileSelectorModalOptions({ isOpen: false });
  };

  const handleOnSelectMultipleAttachments = (file, files, form) => {
    const index = files ? files.findIndex((x) => x.id === file.id) : -1;
    let selectedFiles = [];
    if (index === -1) {
      selectedFiles = [...files, file];
    } else {
      selectedFiles = files.filter((x) => x.id !== file.id);
    }

    setFileSelectorModalOptions({
      isOpen: true,
      selectedFiles,
      type: ['pdf', 'word'],
      onSelect: (file, files) =>
        handleOnSelectMultipleAttachments(file, files, form),
      onApprove: (files) =>
        handleOnApproveSelectMultipleAttachments(files, form),
    });
  };

  const handleOnApproveSelectMultiplePhotos = (selectedImages, form) => {
    setPhotos(selectedImages);
    form.setFieldValue(
      'photos',
      JSON.stringify(
        selectedImages.map((x) => ({
          id: x.id,
          url: x.url,
          type: x.type,
        }))
      )
    );
    setFileSelectorModalOptions({ isOpen: false });
  };

  const handleOnSelectMultiplePhotos = (img, selectedImages, form) => {
    const index = selectedImages
      ? selectedImages.findIndex((x) => x.id === img.id)
      : -1;
    let selectedFiles = [];
    if (index === -1) {
      selectedFiles = [...selectedImages, img];
    } else {
      selectedFiles = selectedImages.filter((x) => x.id !== img.id);
    }

    setFileSelectorModalOptions({
      isOpen: true,
      selectedFiles,
      onSelect: (file, files) =>
        handleOnSelectMultiplePhotos(file, files, form),
      onApprove: (files) => handleOnApproveSelectMultiplePhotos(files, form),
    });
  };

  const handleOnSelectMainPhoto = (img, form) => {
    if (mainPhoto && mainPhoto.id === img.id) {
      form.setFieldValue('mainPhotoId', '');
      setMainPhoto(null);
      setFileSelectorModalOptions({
        isOpen: true,
        selectedFiles: [],
        onSelect: (file) => handleOnSelectMainPhoto(file, form),
      });
    } else {
      form.setFieldValue('mainPhotoId', img.id);
      setMainPhoto(img);
      setFileSelectorModalOptions({ isOpen: false });
    }
  };

  if (!categories) {
    return <Loader />;
  }

  if (isEdit && !blogPost) {
    return <></>;
  }

  const initialValues = {
    title: isEdit ? blogPost.title : '',
    content: isEdit ? blogPost.content : '',
    categoryKey: isEdit ? blogPost.categoryKey : categories[0].key,
    isDisplayedOnMainPage: isEdit ? blogPost.isDisplayedOnMainPage : true,
    mainPhotoId: isEdit ? blogPost.mainPhoto.id : '',
    photos: isEdit && blogPost.photos ? blogPost.photos : '',
    attachments: isEdit && blogPost.attachments ? blogPost.attachments : '',
    visibleDate: isEdit ? moment(blogPost.visibleDate)._d : new Date(),
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={saveBlogPost}
        render={(form) => {
          return (
            <Form>
              {renderFormGroup(form, 'title', {
                label: 'Tytuł',
                placeholder: 'Wprowadź tytu wpisu...',
              })}
              {renderFormGroup(form, 'categoryKey', {
                label: 'Kategoria',
                type: 'select',
                options: categories.map((cat) => (
                  <option key={cat.key} value={cat.key}>
                    {cat.value}
                  </option>
                )),
              })}
              {renderFormGroup(form, 'visibleDate', {
                label: 'Data z jaką ma być prezentowany wpis',
                type: 'datepicker',
              })}
              {renderFormGroupCustomControl({
                label: 'Zdjęcie wyróżniające',
                renderControl: () => (
                  <>
                    {!mainPhoto && (
                      <StyledSelectFileImage
                        onClick={() =>
                          setFileSelectorModalOptions({
                            isOpen: true,
                            selectedFiles: mainPhoto ? [mainPhoto] : [],
                            onSelect: (file) =>
                              handleOnSelectMainPhoto(file, form),
                          })
                        }
                        isSelected={mainPhoto !== null}
                      >
                        <Icon
                          path={mdiImageSearchOutline}
                          size={2}
                          color="#495057"
                        />
                      </StyledSelectFileImage>
                    )}
                    {mainPhoto && (
                      <StyledWrapperMainPhotoPreview>
                        <FilesImagesDisplay
                          files={[mainPhoto]}
                          onEditClick={() =>
                            setFileSelectorModalOptions({
                              isOpen: true,
                              selectedFiles: mainPhoto ? [mainPhoto] : [],
                              onSelect: (file) =>
                                handleOnSelectMainPhoto(file, form),
                            })
                          }
                          onRemoveClick={() => {
                            form.setFieldValue('mainPhotoId', '');
                            setMainPhoto(null);
                          }}
                        />
                      </StyledWrapperMainPhotoPreview>
                    )}
                    <ErrorMessage
                      show={form.errors.mainPhotoId && form.touched.mainPhotoId}
                    >
                      {form.errors.mainPhotoId}
                    </ErrorMessage>
                  </>
                ),
              })}
              {renderFormGroupCustomControl({
                label: 'Treść',
                renderControl: () => (
                  <Editor
                    content={form.values.content}
                    onChange={(content) =>
                      form.setFieldValue('content', content)
                    }
                  />
                ),
              })}
              {renderFormGroupCustomControl({
                label: 'Galeria zdjęć',
                renderControl: () => (
                  <>
                    {photos.length === 0 && (
                      <StyledSelectFileImage
                        onClick={() =>
                          setFileSelectorModalOptions({
                            isOpen: true,
                            selectedFiles: photos,
                            onSelect: (file, files) =>
                              handleOnSelectMultiplePhotos(file, files, form),
                            onApprove: (files) =>
                              handleOnApproveSelectMultiplePhotos(files, form),
                          })
                        }
                      >
                        <Icon path={mdiImageFilter} size={2} color="#495057" />
                      </StyledSelectFileImage>
                    )}
                    {photos.length > 0 && (
                      <>
                        <StyledSelectFileImage
                          style={{ margin: '5px 5px 0 0' }}
                          onClick={() =>
                            setFileSelectorModalOptions({
                              isOpen: true,
                              selectedFiles: photos,
                              onSelect: (file, files) =>
                                handleOnSelectMultiplePhotos(file, files, form),
                              onApprove: (files) =>
                                handleOnApproveSelectMultiplePhotos(
                                  files,
                                  form
                                ),
                            })
                          }
                        >
                          <Icon path={mdiPlus} size={2} color="#495057" />
                        </StyledSelectFileImage>
                        <StyledWrapperPhotosPreview>
                          <FilesImagesDisplay
                            files={photos}
                            onRemoveClick={(file) => {
                              const newPhotos = photos.filter(
                                (x) => x.id !== file.id
                              );
                              setPhotos(newPhotos);
                              form.setFieldValue(
                                'photos',
                                JSON.stringify(
                                  newPhotos.map((x) => ({
                                    id: x.id,
                                    url: x.url,
                                    type: x.type,
                                  }))
                                )
                              );
                            }}
                          />
                        </StyledWrapperPhotosPreview>
                      </>
                    )}
                  </>
                ),
              })}
              {renderFormGroupCustomControl({
                label: 'Załączniki',
                renderControl: () => (
                  <>
                    <StyledSelectFileImage
                      onClick={() =>
                        setFileSelectorModalOptions({
                          isOpen: true,
                          selectedFiles: attachments,
                          type: ['pdf', 'word'],
                          onSelect: (file, files) =>
                            handleOnSelectMultipleAttachments(
                              file,
                              files,
                              form
                            ),
                          onApprove: (files) =>
                            handleOnApproveSelectMultipleAttachments(
                              files,
                              form
                            ),
                        })
                      }
                    >
                      <Icon path={mdiAttachment} size={2} color="#495057" />
                    </StyledSelectFileImage>
                    {attachments.length > 0 && (
                      <AttachmentsList
                        attachments={attachments}
                        onRemoveClick={(id) => {
                          const newAttachments = attachments.filter(
                            (x) => x.id !== id
                          );
                          setAttachments(newAttachments);
                          form.setFieldValue(
                            'attachments',
                            JSON.stringify(
                              newAttachments.map((x) => ({
                                id: x.id,
                                name: x.name,
                                url: x.url,
                                type: x.type,
                              }))
                            )
                          );
                        }}
                      />
                    )}
                  </>
                ),
              })}
              <ErrorMessage show={validationError}>
                {validationError}
              </ErrorMessage>
              <FormButtons onDiscard={onDiscard}></FormButtons>
            </Form>
          );
        }}
      />
      <Modal isOpen={fileSelectorModalOptions.isOpen} fullscreen>
        <FileImageSelector
          selectedFiles={fileSelectorModalOptions.selectedFiles}
          onSelect={fileSelectorModalOptions.onSelect}
          onApprove={fileSelectorModalOptions.onApprove}
          onDiscard={() => setFileSelectorModalOptions({ isOpen: false })}
          type={fileSelectorModalOptions.type || ['image']}
        />
      </Modal>
    </>
  );
};

export default withRouter(AddEditBlogPost);
