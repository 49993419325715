import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";
import UsersService from "../../services/usersService";
import ErrorMessage from "../shared/form/ErrorMessage";
import { withRouter } from "react-router-dom";
import { mdiUndo } from "@mdi/js";
import { Button } from "tabler-react";
import Icon from "@mdi/react";
import handleError from "../../utils/errorHandler";

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .max(30, "Podana nazwa jest za długa. Maksymalna ilość znaków to 200.")
    .required("Imię musi być uzupełnione."),
  lastName: Yup.string()
    .max(40, "Podana nazwa jest za długa. Maksymalna ilość znaków to 200.")
    .required("Nazwisko musi być uzupełnione."),
  login: Yup.string()
    .max(30, "Podana nazwa jest za długa. Maksymalna ilość znaków to 200.")
    .required("Login musi być uzupełniony."),
  role: Yup.string().required("Rola musi być wybrana.")
});

const EditUserDataForm = ({ onDiscard, onSuccess, undo, history, user }) => {
  const [validationError, setValidationError] = useState(null);

  const editUserData = async userData => {
    try {
      await UsersService.updateData(user.id, { ...userData });
      onDiscard();
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  if (!user) return <></>;

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    login: user.login,
    role: user.role === "Administrator" ? "1" : "2"
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={editUserData}
      validationSchema={Schema}
      render={form => {
        return (
          <Form>
            {renderFormGroup(form, "firstName", {
              label: "Imię",
              placeholder: "Wprowadź imię..."
            })}
            {renderFormGroup(form, "lastName", {
              label: "Nazwisko",
              placeholder: "Wprowadź nazwisko..."
            })}
            {renderFormGroup(form, "login", {
              label: "Login",
              placeholder: "Wprowadź login..."
            })}
            {renderFormGroup(form, "role", {
              label: "Rola",
              placeholder: "Wybierz rolę...",
              type: "select",
              options: [
                <option key={1} value="1">
                  Administrator
                </option>,
                <option key={2} value="2">
                  Redaktor
                </option>
              ]
            })}
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}>
              <Button type="button" color="secondary" onClick={undo}>
                <Icon path={mdiUndo} size={0.8} color="#495057" />
                Cofnij
              </Button>
            </FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(EditUserDataForm);
