import React from "react";
import InputGroup from "./InputGroup";
import Label from "./Label";
import ErrorMessage from "./ErrorMessage";
import Input from "./Input";
import { Field } from "formik";
import DatePickerControl from "./DatePickerControl";
import { Button } from "tabler-react";
import Icon from "@mdi/react";

export const renderFormGroup = (
  { errors, touched, values },
  field,
  {
    label = "",
    placeholder = "",
    type = "text",
    disabled = false,
    options = null,
    inputAppend = null,
    onChangeCustom = null,
    inputButton = null
  }
) => {
  return (
    <InputGroup
      renderLabel={() => <Label id={field}>{label}</Label>}
      renderError={() => (
        <ErrorMessage show={errors[field] && touched[field]}>
          {errors[field]}
        </ErrorMessage>
      )}
    >
      {type === "select" && (
        <Field
          component="select"
          id={field}
          name={field}
          className={`form-control ${
            errors[field] && touched[field] ? "is-invalid" : ""
          }`}
          placeholder={placeholder}
          disabled={disabled}
        >
          {options}
        </Field>
      )}
      {(type === "text" || type === "password") && (
        <>
          <Input
            id={field}
            type={type}
            name={field}
            errors={errors[field]}
            touched={touched[field]}
            placeholder={placeholder}
            disabled={disabled}
          />
          {inputAppend && (
            <span className="input-group-append">
              <span className="input-group-text">{inputAppend}</span>
            </span>
          )}
          {inputButton && (
            <Button
              color="primary"
              type="button"
              className="only-icon"
              onClick={() => inputButton.onClick()}
            >
              <Icon path={inputButton.icon} size={0.8} color="white" />
            </Button>
          )}
        </>
      )}
      {type === "textarea" && (
        <Field
          component="textarea"
          id={field}
          name={field}
          className={`form-control ${
            errors[field] && touched[field] ? "is-invalid" : ""
          }`}
          placeholder={placeholder}
          disabled={disabled}
        >
          {options}
        </Field>
      )}
      {type === "checkbox" &&
        options.map(option => (
          <label
            className="custom-control custom-checkbox custom-control-inline"
            key={option.name}
          >
            <Field name={option.name}>
              {({ field, form }) => (
                <input
                  id={option.name}
                  type="checkbox"
                  name={option.name}
                  checked={option.checked}
                  onChange={e => onChangeCustom(field, e.target.checked, form)}
                  className="custom-control-input custom-control custom-checkbox custom-control-inline"
                />
              )}
            </Field>
            <span className="custom-control-label">{option.label}</span>
          </label>
        ))}
      {type === "datepicker" && (
        <Field name={field}>
          {({ field: f, form }) => (
            <DatePickerControl
              id={field}
              selected={values[field]}
              onChange={date => form.setFieldValue(field, date)}
              dateFormat={"yyyy-MM-dd"}
              locale="pl-PL"
              placeholderText="YYYY-MM-DD"
            />
          )}
        </Field>
      )}
    </InputGroup>
  );
};

export const renderFormGroupCustomControl = ({ label = "", renderControl }) => {
  return (
    <InputGroup renderLabel={() => <Label>{label}</Label>}>
      {renderControl()}
    </InputGroup>
  );
};

export const renderDisplayField = (label, value) => {
  return (
    <>
      <Label>{label}</Label>
      <div style={{ marginBottom: "1rem" }}>{value}</div>
    </>
  );
};
