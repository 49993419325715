import Axios from "axios";

const DictionaryService = {
  get: async key => {
    const result = await Axios.get(`/api/dictionary/${key}`);

    return result.data;
  },
  getAll: async partialKey => {
    const result = await Axios.get("/api/dictionary", {
      params: { partialKey }
    });

    return result.data;
  },
  delete: async key => {
    await Axios.delete(`/api/dictionary/${key}`);
  },
  add: async (key, value, version) => {
    await Axios.post("/api/dictionary", { key, value, version });
  },
  batchUpdate: async items => {
    await Axios.patch("/api/dictionary/batchUpdate", items);
  },
  update: async (key, value, version) => {
    await Axios.patch(`/api/dictionary/${key}`, { key, value, version });
  }
};

export default DictionaryService;
