import React, { useState, useEffect } from "react";
import { Card, Button, Grid } from "tabler-react";
import Icon from "@mdi/react";
import { Tooltip } from "react-tippy";
import { mdiPencilOutline, mdiEmailOutline } from "@mdi/js";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import PageHeader from "../layout/PageHeader";
import Loader from "../layout/Loader";
import styled from "styled-components";
import DictionaryService from "../../services/dictionaryService";
import handleError from "../../utils/errorHandler";
import Modal from "../modal/Modal";
import EditEnrolmentEmail from "./EditEnrolmentEmail";
import EditEnrolmentEmailContent from "./EditEnrolmentEmailContent";

const StyledTitle = styled.div`
  color: #9aa0ac;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const KEY_EMAIL_SETTINGS = "ENROLMENT-EMAIL-SETTINGS";

const EnrolmentEmailSettings = ({ history }) => {
  const [emailSettings, setEmailSettings] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editModalOptions, setEditModalOptions] = useState({
    isOpen: false
  });

  const fetchSettings = async () => {
    try {
      const settings = await DictionaryService.get(KEY_EMAIL_SETTINGS);
      setEmailSettings(JSON.parse(settings.value));
      setCurrentVersion(settings.version);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const saveSettings = async (email, content) => {
    setEditModalOptions({ isOpen: false });

    if (content === null) {
      try {
        setIsLoading(true);
        await DictionaryService.update(
          KEY_EMAIL_SETTINGS,
          JSON.stringify({ email, content: emailSettings.content }),
          currentVersion
        );
        await fetchSettings();
      } catch (error) {
        setIsLoading(false);
        handleError(error, history);
      }
    }

    if (email === null) {
      try {
        setIsLoading(true);
        await DictionaryService.update(
          KEY_EMAIL_SETTINGS,
          JSON.stringify({ email: emailSettings.email, content }),
          currentVersion
        );
        await fetchSettings();
      } catch (error) {
        setIsLoading(false);
        handleError(error, history);
      }
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader
          title="Ustawienia email"
          icon={mdiEmailOutline}
        ></PageHeader>
        {/* <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <StyledTitle>Adres email</StyledTitle>
                </Card.Title>
                <Card.Options>
                  <Tooltip title="Edytuj" position="bottom" arrow>
                    <Button
                      color="secondary"
                      type="button"
                      className="only-icon"
                      onClick={() =>
                        setEditModalOptions({
                          isOpen: true,
                          email: emailSettings.email,
                          type: "email"
                        })
                      }
                    >
                      <Icon
                        path={mdiPencilOutline}
                        size={0.8}
                        color="#222222"
                      />
                    </Button>
                  </Tooltip>
                </Card.Options>
              </Card.Header>
              <Card.Body>{emailSettings.email}</Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row> */}
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <StyledTitle>Treść automatycznej odpowiedzi</StyledTitle>
                </Card.Title>
                <Card.Options>
                  <Tooltip title="Edytuj" position="bottom" arrow>
                    <Button
                      color="secondary"
                      type="button"
                      className="only-icon"
                      onClick={() =>
                        setEditModalOptions({
                          isOpen: true,
                          content: emailSettings.content,
                          type: "content"
                        })
                      }
                    >
                      <Icon
                        path={mdiPencilOutline}
                        size={0.8}
                        color="#222222"
                      />
                    </Button>
                  </Tooltip>
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={{ __html: emailSettings.content }}
                ></div>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal
          isOpen={editModalOptions.isOpen}
          title={
            editModalOptions.type === "email"
              ? "Edytuj adres email"
              : "Edytuj treść automatycznej odpowiedzi"
          }
        >
          {editModalOptions.type === "email" && (
            <EditEnrolmentEmail
              onOk={({ email }) => saveSettings(email, null)}
              onDiscard={() => setEditModalOptions({ isOpen: false })}
              email={editModalOptions.email}
            ></EditEnrolmentEmail>
          )}
          {editModalOptions.type === "content" && (
            <EditEnrolmentEmailContent
              onOk={({ content }) => saveSettings(null, content)}
              onDiscard={() => setEditModalOptions({ isOpen: false })}
              content={editModalOptions.content}
            ></EditEnrolmentEmailContent>
          )}
        </Modal>
      </PageContent>
    </SiteWrapper>
  );
};

export default EnrolmentEmailSettings;
