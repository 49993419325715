import Axios from "axios";

const AccountService = {
  login: async (login, password) => {
    return await Axios.post("/api/account/login", { login, password });
  },
  getUserInfo: async () => {
    const result = await Axios.get("/api/account/getUserInfo");

    return result.data;
  },
  logout: async () => {
    await Axios.post("/api/account/logout");
  },
  extendSession: async () => {
    await Axios.post("/api/account/extendSession");
  }
};

export default AccountService;
