import React from "react";
import { Grid, Button, Card, Table } from "tabler-react";
import PageContent from "./components/layout/PageContent";
import SiteWrapper from "./components/layout/SiteWrapper";
import styled from "styled-components";
import Loader from "./components/layout/Loader";
import handleError from "./utils/errorHandler";
import { mdiSync } from "@mdi/js";
import PageHeader from "./components/layout/PageHeader";
import CiService from "./services/ciService";
import moment from "moment";
import { StyledStatus } from "./components/shared/Status";

const StyledTitle = styled.div`
  color: #9aa0ac;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
`;

const StyledTableRow = styled(Table.Row)`
  th,
  td {
    text-align: center;
  }
`;

const Home = ({ history }) => {
  const [syncButtonIsDisabled, setSyncButtonIsDisabled] = React.useState(true);
  const [builds, setBuilds] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchBuilds = async () => {
    setIsLoading(true);
    try {
      const builds = await CiService.getBuilds();
      setBuilds(builds);

      if (
        (builds.length > 0 && builds[0].status === "running") ||
        builds[0].status === "queued"
      ) {
        setSyncButtonIsDisabled(true);
      } else {
        setSyncButtonIsDisabled(false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      handleError(error, history);
    }
  };

  React.useEffect(() => {
    fetchBuilds();
  }, []);

  const handleSync = async () => {
    setSyncButtonIsDisabled(true);
    try {
      await CiService.triggerBuild();

      await fetchBuilds();
    } catch (error) {
      handleError(error, history);
    }
  };

  const getDuration = build => {
    if (!build.stop_time || !build.start_time) return "—";

    const startTime = moment(build.start_time);
    const stopTime = moment(build.stop_time);
    const duration = moment.duration(stopTime.diff(startTime));
    const seconds = duration.asSeconds();
    const minutes = Math.floor(seconds / 60);

    let result = minutes > 0 ? minutes + "m " : "";
    result += Math.floor(seconds - minutes * 60) + "s";

    return result;
  };

  const renderStatus = status => {
    let value = "";
    let background = "";
    switch (status) {
      case "success":
        value = "Sukces";
        background = "#5eba00";
        break;
      case "running":
      case "queued":
        value = "W trakcie";
        background = "#467fcf";
        break;
      case "failed":
        value = "Błąd";
        background = "#cd201f";
        break;
      case "canceled":
        value = "Anulowany";
        background = "#343a40";
        break;
      default:
        break;
    }

    return <StyledStatus style={{ background }}>{value}</StyledStatus>;
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Synchronizacja" icon={mdiSync}></PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <StyledTitle>Synchronizacja</StyledTitle>
                </Card.Title>
                <Card.Options>
                  <div style={{ marginRight: 15 }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={async () => {
                        await fetchBuilds();
                      }}
                    >
                      Odśwież
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="success"
                      type="button"
                      disabled={syncButtonIsDisabled}
                      onClick={handleSync}
                    >
                      Synchronizuj
                    </Button>
                  </div>
                </Card.Options>
              </Card.Header>
            </Card>
            <div>
              <strong>Ostatnie 20 synchronizacji:</strong>
            </div>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <StyledTableRow>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>
                      Data rozpoczęcia synchronizacji
                    </Table.ColHeader>
                    <Table.ColHeader>
                      Data zakończenia synchronizacji
                    </Table.ColHeader>
                    <Table.ColHeader>Czas trwania</Table.ColHeader>
                  </StyledTableRow>
                </Table.Header>
                <Table.Body>
                  {builds.map(build => (
                    <StyledTableRow key={"id_" + build.start_time}>
                      <Table.Col>{renderStatus(build.status)}</Table.Col>
                      <Table.Col>
                        {build.start_time
                          ? moment(build.start_time).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : "—"}
                      </Table.Col>
                      <Table.Col>
                        {build.stop_time
                          ? moment(build.stop_time).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : "—"}
                      </Table.Col>
                      <Table.Col>{getDuration(build)}</Table.Col>
                    </StyledTableRow>
                  ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </PageContent>
    </SiteWrapper>
  );
};

export default Home;
