import {
  mdiAccountGroupOutline,
  mdiClockOutline,
  mdiMenu,
  mdiAttachment,
  mdiNewspaper,
  mdiFileDocumentBoxOutline,
  mdiNewspaperPlus,
  mdiHelpCircleOutline,
  mdiStarOutline,
  mdiEmailOutline,
  mdiClipboardArrowDownOutline,
  mdiFolderAccountOutline,
  mdiClipboardOutline,
  mdiSync,
  mdiTable,
  mdiBookOpenPageVariant,
  mdiBookOpenVariant,
  mdiCogs,
} from '@mdi/js';

const MenuData = [
  {
    categoryName: 'Ogólne',
    permission: ['Administrator', 'Redaktor'],
    items: [
      {
        name: 'Synchronizacja',
        icon: mdiSync,
        path: '/',
      },
    ],
  },
  {
    categoryName: 'Zarządzanie treścią',
    permission: ['Administrator', 'Redaktor'],
    items: [
      {
        name: 'Pliki i zdjęcia',
        icon: mdiAttachment,
        path: '/pliki-i-zdjecia',
      },
      {
        name: 'Wyróżnione',
        icon: mdiStarOutline,
        path: '/wyroznione',
      },
      {
        name: 'Kategorie aktualności',
        icon: mdiNewspaperPlus,
        path: '/kategorie-aktualnosci',
      },
      {
        name: 'Aktualności',
        icon: mdiNewspaper,
        path: '/aktualnosci',
      },
      {
        name: 'Kategorie blog',
        icon: mdiBookOpenPageVariant,
        path: '/kategorie-blog',
      },
      {
        name: 'Blog',
        icon: mdiBookOpenVariant,
        path: '/blog',
      },
      {
        name: 'Podstrony',
        icon: mdiFileDocumentBoxOutline,
        path: '/podstrony',
      },
      {
        name: 'Tabele',
        icon: mdiTable,
        path: '/tabele',
      },
      {
        name: 'Inne ustawienia',
        icon: mdiCogs,
        path: '/inne-ustawienia',
      },
      {
        name: 'Godziny otwarcia',
        icon: mdiClockOutline,
        path: '/godziny-otwarcia',
      },
      {
        name: 'FAQ',
        icon: mdiHelpCircleOutline,
        path: '/faq',
      },
      {
        name: 'Konfiguracja menu',
        icon: mdiMenu,
        path: '/menu',
      },
    ],
  },
  {
    categoryName: 'Zapisy',
    permission: ['Administrator', 'Redaktor'],
    items: [
      {
        name: 'Kierunki',
        icon: mdiClipboardArrowDownOutline,
        path: '/zapisy-kierunki',
      },
      {
        name: 'Ustawienia email',
        icon: mdiEmailOutline,
        path: '/zapisy-ustawienia-email',
      },
      {
        name: 'Zapisane osoby',
        icon: mdiFolderAccountOutline,
        path: '/zapisy-osoby',
      },
    ],
  },
  {
    categoryName: 'Ogłoszenia o pracę',
    permission: ['Administrator', 'Redaktor'],
    items: [
      {
        name: 'Lista ogłoszeń',
        icon: mdiClipboardOutline,
        path: '/ogloszenia-o-prace',
      },
    ],
  },
  {
    categoryName: 'Administracja',
    permission: ['Administrator'],
    items: [
      {
        name: 'Użytkownicy',
        icon: mdiAccountGroupOutline,
        path: '/uzytkownicy',
      },
      {
        name: 'Synchronizacja',
        icon: mdiSync,
        path: '/sync-v2',
      },
    ],
  },
];

export default MenuData;
