import React from "react";
import Icon from "@mdi/react";
import Modal from "../../modal/Modal";
import FileImageSelector from "../../file-image-selector/FileImageSelector";
import { Button } from "tabler-react";

const EditorImage = ({ icon, onEnd }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedNode, setSelectedNode] = React.useState(null);

  const handleOnClick = () => {
    setIsOpen(true);

    setSelectedNode(document.getSelection().anchorNode);
  };

  const onSelect = file => {
    setIsOpen(false);

    const node = document.createElement("div");
    node.innerHTML = `<img src="${window.location.origin + file.url}" alt="${
      file.name
    }" />`;

    selectedNode.appendChild(node);
    onEnd();
  };

  return (
    <>
      <Button
        color="secondary"
        type="button"
        className="only-icon"
        onClick={handleOnClick}
      >
        <Icon path={icon} size={0.8} color="#222222" />
      </Button>
      <Modal isOpen={isOpen} fullscreen>
        <FileImageSelector
          selectedFiles={[]}
          onSelect={onSelect}
          onDiscard={() => setIsOpen(false)}
          type={["image"]}
        />
      </Modal>
    </>
  );
};

export default EditorImage;
