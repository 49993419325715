import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import PageContent from "../layout/PageContent";
import PageHeader from "../layout/PageHeader";
import { mdiLockOutline } from "@mdi/js";
import SiteWrapper from "../layout/SiteWrapper";
import Modal from "../modal/Modal";
import EditUserPasswordForm from "../users/EditUserPasswordForm";
import { Button } from "tabler-react";
import Icon from "@mdi/react";

const ChangePassword = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const { user } = useContext(UserContext);

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Zmień hasło" icon={mdiLockOutline}>
          <Button color="success" onClick={() => setModalIsOpen(true)}>
            <Icon path={mdiLockOutline} size={0.8} color="white" />
            Zmień
          </Button>
        </PageHeader>
      </PageContent>
      <Modal isOpen={modalIsOpen} title="Zmień hasło">
        <EditUserPasswordForm
          onDiscard={() => setModalIsOpen(false)}
          user={{ id: user.id }}
        />
      </Modal>
    </SiteWrapper>
  );
};

export default ChangePassword;
