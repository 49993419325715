import Axios from "axios";

const HeroItemsService = {
  get: async id => {
    const result = await Axios.get(`/api/heroItems/${id}`);

    return result.data;
  },
  getAll: async filters => {
    const result = await Axios.get("/api/heroItems", {
      params: { ...filters }
    });

    return result.data;
  },
  add: async post => {
    await Axios.post("/api/heroItems", post);
  },
  edit: async (id, post) => {
    await Axios.patch(`/api/heroItems/${id}`, post);
  },
  delete: async id => {
    await Axios.delete(`/api/heroItems/${id}`);
  },
  release: async id => {
    await Axios.patch(`/api/heroItems/${id}/release`);
  },
  archive: async id => {
    await Axios.patch(`/api/heroItems/${id}/archive`);
  },
  moveUp: async id => {
    await Axios.patch(`/api/heroItems/${id}/UpOrderIndex`);
  },
  moveDown: async id => {
    await Axios.patch(`/api/heroItems/${id}/DownOrderIndex`);
  }
};

export default HeroItemsService;
