import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";
import UsersService from "../../services/usersService";
import ErrorMessage from "../shared/form/ErrorMessage";
import handleError from "../../utils/errorHandler";

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .max(30, "Podana nazwa jest za długa. Maksymalna ilość znaków to 30.")
    .required("Imię musi być uzupełnione."),
  lastName: Yup.string()
    .max(40, "Podana nazwa jest za długa. Maksymalna ilość znaków to 40.")
    .required("Nazwisko musi być uzupełnione."),
  login: Yup.string()
    .max(30, "Podana nazwa jest za długa. Maksymalna ilość znaków to 30.")
    .required("Login musi być uzupełniony."),
  password: Yup.string()
    .matches(
      /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/,
      "Hasło musi zawierać min. 6 znaków, a w tym conajmniej 1 dużą literę, 1 małą literę, liczbę oraz znak specjalny (!@#$%^&*)"
    )
    .required("Hasło musi być uzupełnione"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Hasła nie pasują do siebie"
  ),
  role: Yup.string().required("Rola musi być wybrana.")
});

const AddUserForm = ({ onSuccess, onDiscard, history }) => {
  const [validationError, setValidationError] = useState(null);

  const addUser = async user => {
    try {
      await UsersService.add(user);
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    login: "",
    password: "",
    confirmPassword: "",
    role: 2
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={addUser}
      validationSchema={Schema}
      render={form => {
        return (
          <Form>
            {renderFormGroup(form, "firstName", {
              label: "Imię",
              placeholder: "Wprowadź imię..."
            })}
            {renderFormGroup(form, "lastName", {
              label: "Nazwisko",
              placeholder: "Wprowadź nazwisko..."
            })}
            {renderFormGroup(form, "login", {
              label: "Login",
              placeholder: "Wprowadź login..."
            })}
            {renderFormGroup(form, "password", {
              label: "Hasło",
              placeholder: "Wprowadź hasło...",
              type: "password"
            })}
            {renderFormGroup(form, "confirmPassword", {
              label: "Powtórz hasło",
              placeholder: "Wprowadź hasło jeszcze raz...",
              type: "password",
              disabled: form.errors.password || !form.touched.password
            })}
            {renderFormGroup(form, "role", {
              label: "Rola",
              placeholder: "Wybierz rolę...",
              type: "select",
              options: [
                <option key={1} value={1}>
                  Administrator
                </option>,
                <option key={2} value={2}>
                  Redaktor
                </option>
              ]
            })}
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default AddUserForm;
