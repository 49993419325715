import Axios from 'axios';

const BlogPostsService = {
  get: async (id) => {
    const result = await Axios.get(`/api/blogposts/${id}`);

    return result.data;
  },
  getAll: async (page, limit, filters) => {
    const result = await Axios.get('/api/blogposts', {
      params: { limit, page, ...filters },
    });

    return result.data;
  },
  add: async (post) => {
    await Axios.post('/api/blogposts', post);
  },
  edit: async (id, post) => {
    await Axios.patch(`/api/blogposts/${id}`, post);
  },
  delete: async (id) => {
    await Axios.delete(`/api/blogposts/${id}`);
  },
  release: async (id) => {
    await Axios.patch(`/api/blogposts/${id}/release`);
  },
  archive: async (id) => {
    await Axios.patch(`/api/blogposts/${id}/archive`);
  },
};

export default BlogPostsService;
