import React, { useContext } from "react";
import "./Sidebar.css";
import { NavLink, withRouter, Link } from "react-router-dom";
import MenuData from "./MenuData";
import { UserContext } from "../../context/UserContext";
import routes from "../../routes";
import Icon from "@mdi/react";
import styled from "styled-components";
import { mdiLogoutVariant, mdiLockOutline } from "@mdi/js";
import Countdown from "react-countdown-now";
import moment from "moment";
import logo from "../../logo.png";
import { useToasts } from "react-toast-notifications";

const StyledLi = styled.li.attrs({ className: "sidebar-dropdown" })`
  border-left: ${({ active }) =>
    (active && "3px solid #feb300 !important") ||
    "3px solid transparent !important"};

  a {
    color: ${({ active }) => (active && "#feb300") || "#fff"};
  }

  svg {
    fill: ${({ active }) => (active && "#feb300") || "#fff"};
  }

  background: ${({ active }) => active && "rgba(255, 255, 255, 0.1)"};
`;

const StyledNavItem = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
  }
`;

const StyledHeader = styled.div`
  > div:last-child {
    color: white;
    font-size: 17px;
    margin-top: 15px;
    font-weight: 600;
  }

  > img {
    width: 140px;
  }
`;

const StyledCountdown = styled.div`
  color: white;
  margin-left: 23px;
  margin-bottom: 20px;
`;

const Sidebar = ({ match: { path }, history }) => {
  const { user, logOut } = useContext(UserContext);
  const { removeAllToasts } = useToasts();

  const logOutHandler = () => {
    removeAllToasts();
    logOut();
    history.push(routes.login);
  };

  return (
    <nav id="sidebar" className="sidebar-wrapper">
      <div className="sidebar-content">
        <div className="sidebar-brand">
          <Link to={routes.home}>
            <div className="text-center">
              <StyledHeader>
                <img src={logo} alt="logo" />
                <div>SYSTEM OBSŁUGI STRONY PWSM</div>
              </StyledHeader>
            </div>
          </Link>
        </div>
        <div className="sidebar-menu">
          <ul>
            <li className="header-menu">
              <span style={{ fontSize: 16 }}>{user.name}</span>
            </li>
            <StyledCountdown>
              Koniec sesji za:{" "}
              <Countdown
                date={moment(user.expirationDate)["_d"]}
                onComplete={logOutHandler}
                renderer={({ minutes, seconds, completed }) => {
                  if (completed) {
                    // Render a completed state
                    return <span />;
                  } else {
                    // Render a countdown
                    return (
                      <span>
                        <strong>
                          {minutes < 10 ? "0" + minutes : minutes} min i{" "}
                          {seconds < 10 ? "0" + seconds : seconds} sek
                        </strong>
                      </span>
                    );
                  }
                }}
              />
            </StyledCountdown>
            <StyledLi active={path === routes.changePassword}>
              <NavLink to="/zmiana-hasla">
                <StyledNavItem>
                  <Icon path={mdiLockOutline} size={0.9} />
                  <span>Zmień hasło</span>
                </StyledNavItem>
              </NavLink>
            </StyledLi>
            <StyledLi active={false} onClick={logOutHandler}>
              <NavLink to="/">
                <StyledNavItem>
                  <Icon path={mdiLogoutVariant} size={0.9} />
                  <span>Wyloguj się</span>
                </StyledNavItem>
              </NavLink>
            </StyledLi>
          </ul>
        </div>
        <div className="sidebar-menu">
          <ul>
            {MenuData.map(
              category =>
                category.permission.includes(user.role) && (
                  <React.Fragment key={category.categoryName}>
                    <li className="header-menu">
                      <span>{category.categoryName}</span>
                    </li>
                    {category.items.map(item => (
                      <React.Fragment key={item.name}>
                        <StyledLi active={path === item.path}>
                          <NavLink to={item.path}>
                            <StyledNavItem>
                              <Icon path={item.icon} size={0.9} />
                              <span>{item.name}</span>
                            </StyledNavItem>
                          </NavLink>
                        </StyledLi>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Sidebar);
