import React, { useState, useEffect } from "react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import { Grid, Button, Table, Card } from "tabler-react";
import Loader from "../layout/Loader";
import handleError from "../../utils/errorHandler";
import PageHeader from "../layout/PageHeader";
import Icon from "@mdi/react";
import { mdiPencilOutline, mdiClipboardOutline } from "@mdi/js";
import styled from "styled-components";
import ListNoDataMessage from "../shared/ListNoDataMessage";
import moment from "moment";
import { Tooltip } from "react-tippy";
import JobOffersService from "../../services/jobOffersService";
import { StyledStatus } from "../shared/Status";
import routes from "../../routes";

const StyledButtonsCol = styled(Table.Col)`
  width: 75px;

  > div {
    text-align: center;
  }
`;

export const renderStatus = status => {
  const style = {};
  if (status === "Nowe") {
    style.background = "#467fcf";
  } else if (status === "Zaakceptowane") {
    style.background = "#5eba00";
  } else if (status === "Odrzucone") {
    style.background = "#cd201f";
  }

  return <StyledStatus style={style}>{status}</StyledStatus>;
};

export const renderDate = date => {
  const formattedDate = moment(date).format("YYYY-MM-DD HH:mm");

  if (formattedDate === "1900-01-01 00:00") return "—";

  return formattedDate;
};

const JobOffers = ({ history }) => {
  const [jobOffers, setJobOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getJobOffers = async () => {
    const jobOffers = await JobOffersService.getAll();
    return jobOffers;
  };

  const fetchJobOffers = async () => {
    setIsLoading(true);
    try {
      const jobOffers = await getJobOffers();

      setJobOffers(jobOffers);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchJobOffers();
  }, []);

  const renderDate = date => {
    const formattedDate = moment(date).format("YYYY-MM-DD HH:mm");

    if (formattedDate === "1900-01-01 00:00") return "—";

    return formattedDate;
  };

  const renderStatus = status => {
    const style = {};
    if (status === "Nowe") {
      style.background = "#467fcf";
    } else if (status === "Zaakceptowane") {
      style.background = "#5eba00";
    } else if (status === "Odrzucone") {
      style.background = "#cd201f";
    }

    return <StyledStatus style={style}>{status}</StyledStatus>;
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader
          title="Lista ogłoszeń"
          icon={mdiClipboardOutline}
        ></PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Nazwa firmy</Table.ColHeader>
                    <Table.ColHeader>Email</Table.ColHeader>
                    <Table.ColHeader>Stanowisko</Table.ColHeader>
                    <Table.ColHeader>Adres</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Data akceptacji</Table.ColHeader>
                    <Table.ColHeader>Data wygaśnięcia</Table.ColHeader>
                    <Table.ColHeader>Zatwierdzone przez</Table.ColHeader>
                    <Table.ColHeader>Zaktualizowane przez</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {jobOffers.map(jobOffer => {
                    return (
                      <Table.Row key={jobOffer.id}>
                        <Table.Col>{renderStatus(jobOffer.status)}</Table.Col>
                        <Table.Col>{jobOffer.companyName}</Table.Col>
                        <Table.Col>{jobOffer.email}</Table.Col>
                        <Table.Col>{jobOffer.position}</Table.Col>
                        <Table.Col>{jobOffer.address}</Table.Col>
                        <Table.Col>
                          {moment(jobOffer.createdDate).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Table.Col>
                        <Table.Col>
                          {renderDate(jobOffer.approvedDate)}
                        </Table.Col>
                        <Table.Col>
                          {renderDate(jobOffer.expirationDate)}
                        </Table.Col>
                        <Table.Col>
                          {jobOffer.approvedByUserId ===
                          "00000000-0000-0000-0000-000000000000"
                            ? "—"
                            : jobOffer.approvedByName}
                        </Table.Col>
                        <Table.Col>
                          {jobOffer.updatedByUserId ===
                          "00000000-0000-0000-0000-000000000000"
                            ? "—"
                            : jobOffer.updatedByName}
                        </Table.Col>
                        <StyledButtonsCol>
                          <Tooltip title="Edytuj" position="bottom">
                            <Button
                              color="secondary"
                              type="button"
                              className="only-icon"
                              onClick={() =>
                                history.push(
                                  routes.jobOffers + "/" + jobOffer.id
                                )
                              }
                            >
                              <Icon
                                path={mdiPencilOutline}
                                size={0.8}
                                color="#222222"
                              />
                            </Button>
                          </Tooltip>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {jobOffers.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </PageContent>
    </SiteWrapper>
  );
};

export default JobOffers;
