import React, { useState, useEffect } from 'react';
import SiteWrapper from '../layout/SiteWrapper';
import PageContent from '../layout/PageContent';
import { Grid, Button, Table, Card } from 'tabler-react';
import Loader from '../layout/Loader';
import handleError from '../../utils/errorHandler';
import PageHeader from '../layout/PageHeader';
import Icon from '@mdi/react';
import { mdiPencilOutline, mdiClockOutline } from '@mdi/js';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import Modal from '../modal/Modal';
import DictionaryService from '../../services/dictionaryService';
import EditOpeningHours from './EditOpeningHours';
import moment from 'moment';

const StyledButtonsCol = styled(Table.Col)`
  width: 75px;

  > div {
    text-align: center;
  }
`;

const findByPartialKey = (partialKey, openingHours) => {
  return openingHours.find((x) => x.key.includes(partialKey));
};

const prepareOpeningHours = (openingHours) => {
  const result = [];

  const weekData = [
    { name: 'Poniedziałek', partialKey: 'MONDAY' },
    { name: 'Wtorek', partialKey: 'TUESDAY' },
    { name: 'Środa', partialKey: 'WEDNESDAY' },
    { name: 'Czwartek', partialKey: 'THURSDAY' },
    { name: 'Piątek', partialKey: 'FRIDAY' },
    { name: 'Sobota', partialKey: 'SATURDAY' },
    { name: 'Niedziela', partialKey: 'SUNDAY' },
  ];

  for (let index = 0; index < weekData.length; index++) {
    let dayOfWeek = weekData[index];
    let hours = findByPartialKey(dayOfWeek.partialKey, openingHours);
    let partialHours = hours.value.split(' - ');

    result.push({
      key: hours.key,
      name: dayOfWeek.name,
      hours,
      from: partialHours[0],
      to: partialHours[1],
      version: hours.version,
    });
  }

  return result;
};

const OpeningHours = ({ history }) => {
  const [openingHours, setOpeningHours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editModalOptions, setEditModalOptions] = useState({ isOpen: false });

  const getOpeningHours = async () => {
    const openingHours = await DictionaryService.getAll('OPENING-HOURS');
    console.log(openingHours);
    const result = prepareOpeningHours(openingHours);
    console.log(result);
    return result;
  };

  const fetchOpeningHours = async () => {
    setIsLoading(true);
    try {
      const openingHours = await getOpeningHours();

      setOpeningHours(openingHours);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchOpeningHours();
  }, []);

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader
          title="Godziny otwarcia"
          icon={mdiClockOutline}
        ></PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Dzień tygodnia</Table.ColHeader>
                    <Table.ColHeader>Godziny otwarcia</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader>Data modyfikacji</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {openingHours.map((openingHour) => {
                    return (
                      <Table.Row key={openingHour.name}>
                        <Table.Col>{openingHour.name}</Table.Col>
                        <Table.Col>
                          <strong>
                            {(openingHour.hours.value === 'nieczynne' &&
                              'nieczynne') ||
                              `${openingHour.hours.value}`}
                          </strong>
                        </Table.Col>
                        <Table.Col>{openingHour.hours.updatedByName}</Table.Col>
                        <Table.Col>
                          {moment(openingHour.hours.updatedDate).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Table.Col>
                        <StyledButtonsCol>
                          <div>
                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setEditModalOptions({
                                    isOpen: true,
                                    state: {
                                      name: openingHour.name,
                                      key: openingHour.key,
                                      from: openingHour.from,
                                      to: openingHour.to,
                                      version: openingHour.version,
                                    },
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal
          isOpen={editModalOptions.isOpen}
          title={`Edytuj godziny - ${
            (editModalOptions.state &&
              editModalOptions.state.name &&
              editModalOptions.state.name) ||
            ''
          }`}
        >
          <EditOpeningHours
            onSuccess={() => {
              setEditModalOptions({ isOpen: false });
              fetchOpeningHours();
            }}
            state={editModalOptions.state}
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          />
        </Modal>
      </PageContent>
    </SiteWrapper>
  );
};

export default OpeningHours;
