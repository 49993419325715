import React from "react";
import styled from "styled-components";

const StyledImage = styled.div`
  background: transparent;
  width: 80px;
  height: 80px;
  position: relative;

  ::after {
    content: "";
    background: ${({ path }) => "url(" + path + ") no-repeat"};
    background-size: cover;
    position: absolute;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

const PostListImage = ({ url }) => {
  return <StyledImage path={url} />;
};

export default PostListImage;
