import React from 'react';
import ReactModal from 'react-modal';

const Modal = ({
  isOpen,
  title,
  children,
  fullscreen,
  style = {},
  styleInnerContent = {},
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      className={`pwsm-modal ${fullscreen ? 'fullscreen' : ''}`}
      shouldCloseOnEsc
      overlayClassName="pwsm-modal-overlay"
      style={style}
    >
      {title && (
        <div
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            marginBottom: 15,
            fontWeight: 500,
          }}
        >
          {title}
        </div>
      )}
      <div style={styleInnerContent}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
