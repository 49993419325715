import React, { useContext } from "react";
import useInterval from "@use-it/interval";
import { UserContext } from "../../context/UserContext";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { Button } from "tabler-react";

const ExtendSession = () => {
  const { user, extendSession } = useContext(UserContext);
  const { addToast, removeAllToasts, toastStack } = useToasts();

  const handleClick = async () => {
    removeAllToasts();
    await extendSession();
    addToast("Twoja sesja została przedłużona!", {
      appearance: "success",
      autoDismiss: true
    });
  };

  useInterval(() => {
    const expirationDate = moment(user.expirationDate);
    const diff = expirationDate.diff(moment(), "seconds");

    if (diff / 60 <= 5 && toastStack.length === 0) {
      addToast(
        <div style={{ textAlign: "center" }}>
          <span style={{ color: "#222222" }}>
            Twoja sesja niedługo wygaśnie.
          </span>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20
            }}
          >
            <Button color="primary" type="button" onClick={handleClick}>
              Przedłuż
            </Button>
          </div>
        </div>,
        {
          appearance: "warning",
          autoDismiss: false
        }
      );
    }
  }, 1000);

  return <></>;
};

export default ExtendSession;
