import Axios from "axios";

const EnrolmentCoursesService = {
  getAll: async () => {
    const result = await Axios.get("/api/enrolmentCourses");

    return result.data;
  },
  add: async enrolmentCourse => {
    await Axios.post("/api/enrolmentCourses", enrolmentCourse);
  },
  edit: async (id, enrolmentCourse) => {
    await Axios.patch(`/api/enrolmentCourses/${id}`, enrolmentCourse);
  },
  delete: async id => {
    await Axios.delete(`/api/enrolmentCourses/${id}`);
  }
};

export default EnrolmentCoursesService;
