import React from "react";
import Modal from "../modal/Modal";
import { Button, StampCard, Tabs, Tab } from "tabler-react";
import PagesService from "../../services/pagesService";
import Loader from "../layout/Loader";
import PostsService from "../../services/postsService";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import styled from "styled-components";

const StyledItem = styled.div`
  .fe-check {
    opacity: 0;
  }

  :hover {
    cursor: pointer;

    .card {
      border: 1px solid #467fcf;

      .stamp {
        background-color: #5eba00 !important;
      }

      .fe-check {
        opacity: 1;
      }
    }
  }
`;

const LinkSelector = ({ isOpen, onDiscard, setHref }) => {
  const [posts, setPosts] = React.useState(null);
  const [pages, setPages] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchLinks = async () => {
    try {
      const posts = await PostsService.getAll(1, 9999, { status: 2 });
      setPosts(posts.items);

      const pages = await PagesService.getAll(1, 9999, { status: 2 });
      setPages(pages.items);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchLinks();
  }, []);

  const renderContent = type => {
    if (isLoading) return <Loader />;

    if (type === "posts") {
      return (
        <>
          {posts.map(x => (
            <StyledItem key={x.id} onClick={() => setLink(x.slug)}>
              <StampCard
                key={x.id}
                header={x.title}
                footer={"/" + x.slug}
                color="grey"
                icon="check"
              />
            </StyledItem>
          ))}
        </>
      );
    } else {
      return (
        <>
          {pages.map(x => (
            <StyledItem key={x.id} onClick={() => setLink(x.slug)}>
              <StampCard
                header={x.title}
                footer={"/" + x.slug}
                color="grey"
                icon="check"
              />
            </StyledItem>
          ))}
        </>
      );
    }
  };

  const setLink = slug => {
    const href = `/${slug}`;
    setHref(href);
    onDiscard();
  };

  return (
    <Modal isOpen={isOpen} fullscreen title="Wybór odniesienia">
      <Tabs initialTab="Aktualności">
        <Tab title="Aktualności">{renderContent("posts")}</Tab>
        <Tab title="Podstrony">{renderContent("pages")}</Tab>
      </Tabs>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <Button color="secondary" onClick={onDiscard}>
          <Icon path={mdiClose} size={0.8} color="#222222" />
          Anuluj
        </Button>
      </div>
    </Modal>
  );
};

export default LinkSelector;
