import React from "react";
import styled from "styled-components";
import { Button } from "tabler-react";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose } from "@mdi/js";

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  > button {
    margin-right: 15px;
  }

  > button:last-child {
    margin-right: 0;
  }
`;

const FormButtons = ({
  submitOptions,
  secondButtonOptions,
  onDiscard,
  children
}) => {
  return (
    <StyledButtonsWrapper>
      <Button
        type={(submitOptions && submitOptions.type) || "submit"}
        color={(submitOptions && submitOptions.color) || "success"}
        onClick={(submitOptions && submitOptions.onClick) || null}
        disabled={
          submitOptions && submitOptions.disabled !== undefined
            ? submitOptions.disabled
            : false
        }
      >
        <Icon
          path={(submitOptions && submitOptions.icon) || mdiCheck}
          size={0.8}
          color="white"
        />
        {(submitOptions && submitOptions.label) || "Zapisz"}
      </Button>
      {children}
      <Button
        type="button"
        color={
          (secondButtonOptions && secondButtonOptions.color) || "secondary"
        }
        onClick={onDiscard}
        disabled={
          secondButtonOptions && secondButtonOptions.disabled
            ? secondButtonOptions.disabled
            : false
        }
      >
        <Icon
          path={(secondButtonOptions && secondButtonOptions.icon) || mdiClose}
          size={0.8}
          color="#495057"
        />
        {(secondButtonOptions && secondButtonOptions.label) || "Anuluj"}
      </Button>
    </StyledButtonsWrapper>
  );
};

export default FormButtons;
