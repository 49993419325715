import React, { useState } from "react";
import styled from "styled-components";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";
import { mdiLink } from "@mdi/js";
import LinkSelector from "../link-selector/LinkSelector";

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 10px;
  }
`;

const AddActions = ({ form, actions }) => {
  const [linkModalOptions, setLinkModalOptions] = useState({
    isOpen: false,
    fieldName: null
  });

  return (
    <div>
      <StyledActionsWrapper>
        <div>1.</div>
        <div>
          {renderFormGroup(form, "actionTitle1", {
            label: "",
            placeholder: "Wprowadź tytuł..."
          })}
        </div>
        <div>
          {renderFormGroup(form, "actionTo1", {
            label: "",
            placeholder: "Prowadź do...",
            inputButton: {
              icon: mdiLink,
              onClick: () =>
                setLinkModalOptions({ isOpen: true, fieldName: "actionTo1" })
            }
          })}
        </div>
        <div>2.</div>
        <div>
          {renderFormGroup(form, "actionTitle2", {
            label: "",
            placeholder: "Wprowadź tytuł..."
          })}
        </div>
        <div>
          {renderFormGroup(form, "actionTo2", {
            label: "",
            placeholder: "Prowadź do...",
            inputButton: {
              icon: mdiLink,
              onClick: () =>
                setLinkModalOptions({ isOpen: true, fieldName: "actionTo2" })
            }
          })}
        </div>
        <div>3.</div>
        <div>
          {renderFormGroup(form, "actionTitle3", {
            label: "",
            placeholder: "Wprowadź tytuł..."
          })}
        </div>
        <div>
          {renderFormGroup(form, "actionTo3", {
            label: "",
            placeholder: "Prowadź do...",
            inputButton: {
              icon: mdiLink,
              onClick: () =>
                setLinkModalOptions({ isOpen: true, fieldName: "actionTo3" })
            }
          })}
        </div>
      </StyledActionsWrapper>
      {linkModalOptions.isOpen && (
        <LinkSelector
          isOpen={linkModalOptions.isOpen}
          setHref={href => form.setFieldValue(linkModalOptions.fieldName, href)}
          onDiscard={() => setLinkModalOptions(false)}
        />
      )}
    </div>
  );
};

export default AddActions;
