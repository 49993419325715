import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email jest niepoprawny")
    .required("Email musi być uzupełniony")
});

const EditEnrolmentEmail = ({ onOk, onDiscard, email }) => {
  return (
    <Formik
      initialValues={{ email }}
      onSubmit={onOk}
      validationSchema={Schema}
      render={form => {
        return (
          <Form>
            {renderFormGroup(form, "email", {
              label: "Email",
              placeholder: "Docelowy adres email..."
            })}
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default EditEnrolmentEmail;
