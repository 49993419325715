import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormButtons from "../shared/form/FormButtons";
import {
  renderFormGroup,
  renderDisplayField
} from "../shared/form/FormRenderHelpers";
import ErrorMessage from "../shared/form/ErrorMessage";
import handleError from "../../utils/errorHandler";
import UploadsService from "../../services/uploadsService";
import moment from "moment";

const Schema = Yup.object().shape({
  name: Yup.string()
    .max(200, "Nazwa pliku nie może przekraczać 200 znaków")
    .required("Nazwa pliku musi być uzupełniona."),
  description: Yup.string().max(
    250,
    "Opis pliku nie może przekraczać 250 znaków"
  )
});

const EditFileImage = ({ onSuccess, onDiscard, history, file }) => {
  const [validationError, setValidationError] = useState(null);

  const updateFileImage = async ({ name, description, ext }) => {
    try {
      await UploadsService.update(file.id, {
        name: name + ext,
        description,
        version: file.version
      });
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data);
      } else {
        handleError(error, history);
      }
    }
  };

  if (!file) return <></>;

  const dotIndex = file.name.lastIndexOf(".");
  const name = file.name.substr(0, dotIndex);
  const ext = file.name.substr(dotIndex);

  const initialValues = {
    name,
    ext,
    description: file.description || ""
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateFileImage}
      validationSchema={Schema}
      render={form => {
        return (
          <Form>
            {renderFormGroup(form, "name", {
              label: "Nazwa pliku",
              inputAppend: form.values.ext
            })}
            {renderFormGroup(form, "description", {
              label: "Opis pliku",
              type: "textarea"
            })}
            {renderDisplayField(
              "Ostatnia modyfikacja wykonana przez",
              file.updatedByName
            )}
            {renderDisplayField(
              "Data ostatniej modyfikacji",
              moment(file.updatedDate).format("YYYY-MM-DD HH:mm:ss")
            )}
            <ErrorMessage show={validationError}>
              {validationError}
            </ErrorMessage>
            <FormButtons onDiscard={onDiscard}></FormButtons>
          </Form>
        );
      }}
    />
  );
};

export default EditFileImage;
