import React, { useState, useEffect } from "react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import { Grid, Button, Table, Card } from "tabler-react";
import Loader from "../layout/Loader";
import handleError from "../../utils/errorHandler";
import PageHeader from "../layout/PageHeader";
import Icon from "@mdi/react";
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiClipboardArrowDownOutline,
  mdiClose,
  mdiCheck,
  mdiInformationOutline
} from "@mdi/js";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import ConfirmModal from "../modal/ConfirmModal";
import Modal from "../modal/Modal";
import ListNoDataMessage from "../shared/ListNoDataMessage";
import EnrolmentCoursesService from "../../services/enrolmentCoursesService";
import AddEditEnrolmentCourse from "./AddEnrolmentCourse";
import moment from "moment";

const StyledButtonsCol = styled(Table.Col)`
  display: flex;
  margin-right: 0 auto;
  justify-content: flex-end;

  > div:first-child {
    margin-right: 10px;
  }
`;

const EnrolmentCourses = ({ history }) => {
  const [enrolmentCourses, setEnrolmentCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: ""
  });
  const [addEditModalOptions, setAddEditModalOptions] = useState({
    isOpen: false
  });

  const getEnrolmentCourses = async () => {
    const enrolmentCourses = await EnrolmentCoursesService.getAll();
    return enrolmentCourses;
  };

  const fetchEnrolmentCourses = async () => {
    setIsLoading(true);
    try {
      const enrolmentCourses = await getEnrolmentCourses();

      setEnrolmentCourses(enrolmentCourses);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchEnrolmentCourses();
  }, []);

  const deleteEnrolmentCourse = async ({ id }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await EnrolmentCoursesService.delete(id);

      fetchEnrolmentCourses();
    } catch (error) {
      handleError(error, history);
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader
          title="Zapisy - kierunki"
          icon={mdiClipboardArrowDownOutline}
        >
          <Button
            color="success"
            onClick={() => setAddEditModalOptions({ isOpen: true })}
          >
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj
          </Button>
        </PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Oznaczenie grupy</Table.ColHeader>
                    <Table.ColHeader>Nazwa kierunku</Table.ColHeader>
                    <Table.ColHeader>Czy aktywny?</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Data modyfikacji</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {enrolmentCourses.map(enrolmentCourse => {
                    return (
                      <Table.Row key={enrolmentCourse.id}>
                        <Table.Col>{enrolmentCourse.courseSymbol}</Table.Col>
                        <Table.Col>{enrolmentCourse.course}</Table.Col>
                        <Table.Col>
                          {enrolmentCourse.isActive ? (
                            <Icon path={mdiCheck} size={0.8} color="#222222" />
                          ) : (
                            <Icon path={mdiClose} size={0.8} color="#222222" />
                          )}
                        </Table.Col>
                        <Table.Col>
                          {moment(enrolmentCourse.createdDate).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Table.Col>
                        <Table.Col>
                          {moment(enrolmentCourse.updatedDate).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Table.Col>
                        <Table.Col>
                          {enrolmentCourse.updatedByName}{" "}
                          <Tooltip
                            title={`Utworzone przez: <strong>${enrolmentCourse.createdByName}</strong>`}
                            position="bottom"
                            arrow
                          >
                            <Icon
                              path={mdiInformationOutline}
                              color="lightblue"
                              size={0.8}
                              style={{ paddingTop: 5 }}
                            />
                          </Tooltip>
                        </Table.Col>
                        <StyledButtonsCol>
                          <div>
                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setAddEditModalOptions({
                                    isOpen: true,
                                    course: { ...enrolmentCourse }
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć kierunek: <strong>${enrolmentCourse.course}</strong>?`,
                                    state: { id: enrolmentCourse.id }
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {enrolmentCourses.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Modal isOpen={addEditModalOptions.isOpen} title="Dodaj kierunek">
          <AddEditEnrolmentCourse
            onSuccess={() => {
              setAddEditModalOptions({ isOpen: false });
              fetchEnrolmentCourses();
            }}
            isEdit={addEditModalOptions.course}
            course={addEditModalOptions.course}
            onDiscard={() => setAddEditModalOptions({ isOpen: false })}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={deleteEnrolmentCourse}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default EnrolmentCourses;
