import styled from "styled-components";

const StyledLink = styled.div`
  color: #467fcf;
  cursor: pointer;

  :hover {
    color: #295a9f;
    text-decoration: underline;
  }
`;

export default StyledLink;
