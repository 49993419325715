import Axios from 'axios';
const TOKEN = 'b80188b57e3b937c1e317d990e1921b8cb55c59d';

const CiService = {
  getBuilds: async () => {
    const result = await Axios.get(
      `https://circleci.com/api/v1.1/project/github/bflaga/pwsm-site-gatsby`,
      {
        params: {
          'circle-token': TOKEN,
          shallow: true,
          limit: 20,
        },
      }
    );

    return result.data;
  },
  triggerBuild: async () => {
    await Axios.post(
      `https://circleci.com/api/v1.1/project/github/bflaga/pwsm-site-gatsby?circle-token=${TOKEN}`
    );
  },
};

export default CiService;
