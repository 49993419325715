import routes from "../routes";

const handleError = (error, history) => {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 400:
        history.push(routes.error400, {
          message: error.response.data,
          prevLocation: history.location.pathname
        });
        break;
      case 403:
        history.push(routes.error403);
        break;
      case 404:
        history.push(routes.error404);
        break;
      case 500:
        history.push(routes.error500);
        break;
      default:
        break;
    }
  } else {
    history.push(routes.serverError);
  }
};

export default handleError;
