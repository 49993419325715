import Axios from "axios";

const PagesService = {
  get: async id => {
    const result = await Axios.get(`/api/pages/${id}`);

    return result.data;
  },
  getAll: async (page, limit, filters) => {
    const result = await Axios.get("/api/pages", {
      params: { limit, page, ...filters }
    });

    return result.data;
  },
  add: async post => {
    await Axios.post("/api/pages", post);
  },
  edit: async (id, post) => {
    await Axios.patch(`/api/pages/${id}`, post);
  },
  delete: async id => {
    await Axios.delete(`/api/pages/${id}`);
  },
  release: async id => {
    await Axios.patch(`/api/pages/${id}/release`);
  },
  archive: async id => {
    await Axios.patch(`/api/pages/${id}/archive`);
  }
};

export default PagesService;
