import React from "react";
import styled from "styled-components";
import Icon from "@mdi/react";
import { mdiHelpCircleOutline, mdiCheck, mdiClose } from "@mdi/js";
import Modal from "./Modal";
import { Button } from "tabler-react";

const StyledMessage = styled.div`
  text-align: center;
  padding: 15px 0;
`;

const StyledIcon = styled.div`
  text-align: center;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  > button:first-child {
    margin-right: 15px;
  }
`;

const ConfirmModal = ({ isOpen, message, state, onConfirm, onDiscard }) => {
  return (
    <Modal isOpen={isOpen}>
      <StyledIcon>
        <Icon path={mdiHelpCircleOutline} size={3} color="#84a6d6" />
      </StyledIcon>
      <StyledMessage
        dangerouslySetInnerHTML={{ __html: message }}
      ></StyledMessage>
      <StyledButtonsWrapper>
        <Button color="success" onClick={() => onConfirm(state)}>
          <Icon path={mdiCheck} size={0.8} color="white" />
          Tak
        </Button>
        <Button color="secondary" onClick={onDiscard}>
          <Icon path={mdiClose} size={0.8} color="#222222" />
          Anuluj
        </Button>
      </StyledButtonsWrapper>
    </Modal>
  );
};

export default ConfirmModal;
