import React from "react";
import { Grid, Card } from "tabler-react";
import { Formik, Form } from "formik";
import FormButtons from "../shared/form/FormButtons";
import { renderFormGroup } from "../shared/form/FormRenderHelpers";
import styled from "styled-components";
import Loader from "../layout/Loader";
import UsersService from "../../services/usersService";
import handleError from "../../utils/errorHandler";
import { mdiMagnify, mdiRestore } from "@mdi/js";

const StyledWrapperFormItems = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 0 0 33.33%;
    padding: 0 7.5px;
  }

  > div:first-child {
    padding-left: 0;
  }

  > div:first-child {
    padding-left: 0;
  }

  > div:last-child {
    padding-right: 0;
  }

  @media (max-width: 1024px) {
    > div {
      flex: 0 0 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const StyledFormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FileImagesFilters = ({ history, onSearch, filters, onReset }) => {
  const [users, setUsers] = React.useState(null);

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await UsersService.getAll();
        setUsers(users);
      } catch (error) {
        handleError(error, history);
      }
    };
    fetchUsers();
  }, []);

  const handleFileTypeChange = (field, checked, form) => {
    const fileTypes = [...form.values["fileType"]];

    if (checked) {
      form.setFieldValue("fileType", [...fileTypes, field.name]);
    } else {
      form.setFieldValue(
        "fileType",
        fileTypes.filter(x => x !== field.name)
      );
    }
  };

  const initialValues = {
    name: (filters && filters.name) || "",
    userId: (filters && filters.userId) || "",
    fileType: (filters && filters.fileType) || []
  };

  if (!users) {
    return <Loader />;
  }

  return (
    <Grid.Col>
      <Card>
        <Card.Header>
          <Card.Title>Wyszukaj pliki lub zdjęcia</Card.Title>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onSearch}
            render={form => {
              return (
                <Form>
                  <StyledWrapperFormItems>
                    {renderFormGroup(form, "name", {
                      label: "Nazwa pliku lub zdjęcia",
                      placeholder: "Wpisz część lub całą nazwę pliku..."
                    })}
                    {renderFormGroup(form, "fileType", {
                      label: "Typ pliku",
                      type: "checkbox",
                      onChangeCustom: handleFileTypeChange,
                      options: [
                        {
                          label: "PDF",
                          name: "pdf",
                          checked: form.values.fileType.includes("pdf")
                        },
                        {
                          label: "Word (.docx lub .doc)",
                          name: "word",
                          checked: form.values.fileType.includes("word")
                        },
                        {
                          label: "Zdjęcie",
                          name: "image",
                          checked: form.values.fileType.includes("image")
                        }
                      ]
                    })}
                    {renderFormGroup(form, "userId", {
                      label: "Utworzone przez",
                      type: "select",
                      options: [].concat(
                        [
                          <option key={1} value={""}>
                            Kogokolwiek
                          </option>
                        ],
                        users.map(user => (
                          <option key={user.id} value={user.id}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))
                      )
                    })}
                  </StyledWrapperFormItems>

                  <StyledFormButtons>
                    <FormButtons
                      submitOptions={{
                        color: "primary",
                        label: "Szukaj",
                        icon: mdiMagnify
                      }}
                      secondButtonOptions={{
                        icon: mdiRestore,
                        label: "Wyczyść"
                      }}
                      onDiscard={() => {
                        onReset();
                        form.handleReset();
                      }}
                    ></FormButtons>
                  </StyledFormButtons>
                </Form>
              );
            }}
          />
        </Card.Body>
      </Card>
    </Grid.Col>
  );
};

export default FileImagesFilters;
