import React, { useState, useEffect } from "react";
import SiteWrapper from "../layout/SiteWrapper";
import PageContent from "../layout/PageContent";
import { Grid, Button, Table, Card } from "tabler-react";
import Loader from "../layout/Loader";
import handleError from "../../utils/errorHandler";
import PageHeader from "../layout/PageHeader";
import Icon from "@mdi/react";
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiInformationOutline,
  mdiFileCheckOutline,
  mdiFileCancelOutline,
  mdiFileDocumentBoxOutline
} from "@mdi/js";
import styled from "styled-components";
import { Tooltip } from "react-tippy";
import ConfirmModal from "../modal/ConfirmModal";
import Modal from "../modal/Modal";
import Status from "../shared/Status";
import moment from "moment";
import Pager from "../pager/Pager";
import StyledLink from "../shared/StyledLink";
import PagesService from "../../services/pagesService";
import AddEditPages from "./AddEditPages";

const StyledButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 10px;
  }
`;

const StyledButtonsCol = styled(Table.Col)`
  width: 100px;
`;

const limit = 11;

const Pages = ({ history }) => {
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: ""
  });
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalOptions, setEditModalOptions] = useState({ isOpen: false });
  const [pagingInfo, setPagingInfo] = useState(null);
  const [currentFilters, setCurrentFilters] = useState(null);

  const getPages = async (page, filters) => {
    const pages = await PagesService.getAll(page, limit, filters);
    return pages;
  };

  const fetchPages = async (page = 1, filters = null) => {
    setIsLoading(true);
    try {
      const pages = await getPages(page, filters);
      setPages(pages.items);
      setPagingInfo({ totalCount: pages.count, currentPage: page });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const deletePage = async ({ id }) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await PagesService.delete(id);

      await fetchPages();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleRelease = async id => {
    try {
      await PagesService.release(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchPages();
    } catch (error) {
      handleError(error, history);
    }
  };

  const handleArchive = async id => {
    try {
      await PagesService.archive(id);
      setConfirmModalOptions({ isOpen: false });
      await fetchPages();
    } catch (error) {
      handleError(error, history);
    }
  };

  const renderStatusButton = page => {
    let title = "";
    let message = "";
    let onConfirmHandler = null;
    let color = "";
    let icon = null;

    switch (page.status) {
      case "Szkic":
        title = "Opublikuj";
        message = `Czy na pewno chcesz zmienić status na <strong>Opublikowana</strong>?`;
        onConfirmHandler = () => handleRelease(page.id);
        color = "primary";
        icon = mdiFileCheckOutline;
        break;
      case "Opublikowana":
        title = "Archiwizuj";
        message = `Czy na pewno chcesz zmienić status na <strong>Zarchwizowana</strong>?`;
        onConfirmHandler = () => handleArchive(page.id);
        color = "warning";
        icon = mdiFileCancelOutline;
        break;
      case "Zarchiwizowana":
        title = "Opublikuj ponownie";
        message = `Czy na pewno chcesz przywrócić status <strong>Opublikowana</strong>?`;
        onConfirmHandler = () => handleRelease(page.id);
        color = "gray-dark";
        icon = mdiFileCheckOutline;
        break;
      default:
        break;
    }
    return (
      <Tooltip title={title} position="bottom" arrow>
        <Button
          color={color}
          type="button"
          className="only-icon"
          onClick={() =>
            setConfirmModalOptions({
              isOpen: true,
              message,
              state: { id: page.id },
              onConfirmHandler
            })
          }
        >
          <Icon path={icon} size={0.8} color="white" />
        </Button>
      </Tooltip>
    );
  };

  const handleOnChangePage = page => {
    fetchPages(page, currentFilters);
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader title="Podstrony" icon={mdiFileDocumentBoxOutline}>
          <Button color="success" onClick={() => setAddModalIsOpen(true)}>
            <Icon path={mdiPlus} size={0.8} color="white" />
            Dodaj podstronę
          </Button>
        </PageHeader>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10
          }}
        >
          <div
            style={{
              padding: "0 0 0 5px"
            }}
          >
            Znalezionych podstron: <strong>{pagingInfo.totalCount}</strong>
          </div>
          {pages.length > 0 && (
            <div style={{ marginLeft: "auto" }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={limit}
              />
            </div>
          )}
        </div>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Tytuł</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Data modyfikacji</Table.ColHeader>
                    <Table.ColHeader>Zmodyfikowane przez</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {pages.map(page => {
                    return (
                      <Table.Row key={page.id}>
                        <Table.Col>
                          <StyledLink
                            onClick={() =>
                              setEditModalOptions({
                                isOpen: true,
                                state: { ...page }
                              })
                            }
                          >
                            <strong>{page.title}</strong>
                          </StyledLink>
                        </Table.Col>
                        <Table.Col>
                          <Status value={page.status} />
                        </Table.Col>
                        <Table.Col>
                          {moment(page.createdDate).format("YYYY-MM-DD HH:mm")}
                        </Table.Col>
                        <Table.Col>
                          {moment(page.updatedDate).format("YYYY-MM-DD HH:mm")}
                        </Table.Col>
                        <Table.Col>
                          {page.updatedByName}{" "}
                          <Tooltip
                            title={`Utworzone przez: <strong>${page.createdByName}</strong>`}
                            position="bottom"
                            arrow
                          >
                            <Icon
                              path={mdiInformationOutline}
                              color="lightblue"
                              size={0.8}
                              style={{ paddingTop: 5 }}
                            />
                          </Tooltip>
                        </Table.Col>
                        <StyledButtonsCol>
                          <StyledButtonsWrapper>
                            {renderStatusButton(page)}
                            <Tooltip title="Edytuj" position="bottom" arrow>
                              <Button
                                color="secondary"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setEditModalOptions({
                                    isOpen: true,
                                    state: { ...page }
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPencilOutline}
                                  size={0.8}
                                  color="#222222"
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                className="only-icon"
                                type="button"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć podstronę pod tytułem: <strong>${page.title}</strong>?`,
                                    state: { id: page.id },
                                    onConfirmHandler: deletePage
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </StyledButtonsWrapper>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
          {pages.length > 0 && (
            <div style={{ marginLeft: "auto" }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={limit}
              />
            </div>
          )}
        </div>
        <Modal isOpen={addModalIsOpen} title="Dodawanie podstrony" fullscreen>
          <AddEditPages
            onSuccess={() => {
              setAddModalIsOpen(false);
              fetchPages();
            }}
            onDiscard={() => setAddModalIsOpen(false)}
          />
        </Modal>
        <Modal
          isOpen={editModalOptions.isOpen}
          title="Edycja podstrony"
          fullscreen
        >
          <AddEditPages
            isEdit
            page={editModalOptions.state}
            onSuccess={() => {
              setEditModalOptions({ isOpen: false });
              fetchPages();
            }}
            onDiscard={() => setEditModalOptions({ isOpen: false })}
          />
        </Modal>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={confirmModalOptions.onConfirmHandler}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
      </PageContent>
    </SiteWrapper>
  );
};

export default Pages;
