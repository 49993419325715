import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "tabler-react";
import Icon from "@mdi/react";
import { mdiClose, mdiAccountCardDetailsOutline, mdiLockReset } from "@mdi/js";
import EditUserDataForm from "./EditUserDataForm";
import EditUserPasswordForm from "./EditUserPasswordForm";

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  > button {
    margin-right: 15px;
  }

  > button:last-child {
    margin-right: 0;
  }
`;

const modes = {
  DATA: "DATA",
  PASSWORD: "PASSWORD"
};

const EditUserForm = ({ onDiscard, onSuccessChange, user }) => {
  const [mode, setMode] = useState(null);
  const undo = () => {
    setMode(null);
  };

  if (mode === modes.DATA) {
    return (
      <EditUserDataForm
        onSuccess={onSuccessChange}
        onDiscard={onDiscard}
        user={user}
        undo={undo}
      />
    );
  }

  if (mode === modes.PASSWORD) {
    return (
      <EditUserPasswordForm onDiscard={onDiscard} user={user} undo={undo} />
    );
  }

  return (
    <StyledButtonsWrapper>
      <Button type="button" color="primary" onClick={() => setMode(modes.DATA)}>
        <Icon path={mdiAccountCardDetailsOutline} size={0.8} color="white" />
        Zmiana danych
      </Button>
      <Button
        type="button"
        color="warning"
        onClick={() => setMode(modes.PASSWORD)}
      >
        <Icon path={mdiLockReset} size={0.8} color="white" />
        Zmiana hasła
      </Button>
      <Button type="button" color="secondary" onClick={onDiscard}>
        <Icon path={mdiClose} size={0.8} color="#222222" />
        Anuluj
      </Button>
    </StyledButtonsWrapper>
  );
};

export default EditUserForm;
