import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import locale from "date-fns/locale/pl";
registerLocale("pl-PL", locale);

const DatePickerControl = props => {
  return <DatePicker {...props} className="form-control" autoComplete="off" />;
};

export default DatePickerControl;
