import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import SiteWrapper from "../layout/SiteWrapper";
import Loader from "../layout/Loader";
import JobOffersService from "../../services/jobOffersService";
import { mdiClipboardOutline, mdiPencilOutline } from "@mdi/js";
import { Card, Button, Grid, Table } from "tabler-react";
import PageHeader from "../layout/PageHeader";
import PageContent from "../layout/PageContent";
import handleError from "../../utils/errorHandler";
import Icon from "@mdi/react";
import { Tooltip } from "react-tippy";
import moment from "moment";
import { renderStatus, renderDate } from "./JobOffers";
import ConfirmModal from "../modal/ConfirmModal";
import EditJobOfferDetails from "./EditJobOfferDetails";
import Modal from "../modal/Modal";

const StyledButtonsCol = styled(Table.Col)`
  display: flex;
  justify-content: flex-end;

  > div:first-child {
    margin-right: 15px;
  }
`;

const StyledTitle = styled.div`
  color: #9aa0ac;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
`;

const StyledLabel = styled.div`
  margin-bottom: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
`;

const StyledValue = styled.div`
  margin-bottom: 1.5rem;
`;

const EditJobOffer = ({ history, match }) => {
  const [jobOffer, setJobOffer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: ""
  });
  const [editDetailsModalIsOpen, setEditDetailsModalIsOpen] = useState(false);

  const fetchJobOffer = async () => {
    try {
      const jobOffer = await JobOffersService.get(match.params.id);
      setJobOffer(jobOffer);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchJobOffer();
  }, []);

  const changeStatus = async status => {
    setIsLoading(true);
    setConfirmModalOptions({ isOpen: false });
    try {
      await JobOffersService.changeStatus(match.params.id, status);
      await fetchJobOffer();
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader
          title={`Ogłoszenie o pracę (${match.params.id})`}
          icon={mdiClipboardOutline}
        ></PageHeader>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Data utworzenia</Table.ColHeader>
                    <Table.ColHeader>Data akceptacji</Table.ColHeader>
                    <Table.ColHeader>Data wygaśnięcia</Table.ColHeader>
                    <Table.ColHeader>Zatwierdzone przez</Table.ColHeader>
                    <Table.ColHeader>Zaktualizowane przez</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key={jobOffer.id}>
                    <Table.Col>{renderStatus(jobOffer.status)}</Table.Col>
                    <Table.Col>
                      {moment(jobOffer.createdDate).format("YYYY-MM-DD HH:mm")}
                    </Table.Col>
                    <Table.Col>{renderDate(jobOffer.approvedDate)}</Table.Col>
                    <Table.Col>{renderDate(jobOffer.expirationDate)}</Table.Col>
                    <Table.Col>
                      {jobOffer.approvedByUserId ===
                      "00000000-0000-0000-0000-000000000000"
                        ? "—"
                        : jobOffer.approvedByName}
                    </Table.Col>
                    <Table.Col>
                      {jobOffer.updatedByUserId ===
                      "00000000-0000-0000-0000-000000000000"
                        ? "—"
                        : jobOffer.updatedByName}
                    </Table.Col>
                    <StyledButtonsCol>
                      {jobOffer.status !== "Zaakceptowane" && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            onClick={() =>
                              setConfirmModalOptions({
                                isOpen: true,
                                message: `Czy na pewno chcesz zaakceptować to ogłoszenie?`,
                                state: 2
                              })
                            }
                          >
                            Akceptuj
                          </Button>
                        </div>
                      )}
                      {jobOffer.status !== "Odrzucone" && (
                        <div>
                          <Button
                            color="danger"
                            type="button"
                            onClick={() =>
                              setConfirmModalOptions({
                                isOpen: true,
                                message: `Czy na pewno chcesz odrzucić to ogłoszenie?`,
                                state: 3
                              })
                            }
                          >
                            Odrzuć
                          </Button>
                        </div>
                      )}
                    </StyledButtonsCol>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <StyledTitle>Szczegóły ogłoszenia</StyledTitle>
                </Card.Title>
                <Card.Options>
                  <Tooltip title="Edytuj" position="bottom" arrow>
                    <Button
                      color="secondary"
                      type="button"
                      className="only-icon"
                      onClick={() => setEditDetailsModalIsOpen(true)}
                    >
                      <Icon
                        path={mdiPencilOutline}
                        size={0.8}
                        color="#222222"
                      />
                    </Button>
                  </Tooltip>
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <StyledLabel>Nazwa firmy</StyledLabel>
                <StyledValue>{jobOffer.companyName}</StyledValue>
                <StyledLabel>Email</StyledLabel>
                <StyledValue>{jobOffer.email}</StyledValue>
                <StyledLabel>Stanowisko</StyledLabel>
                <StyledValue>{jobOffer.position}</StyledValue>
                <StyledLabel>Adres</StyledLabel>
                <StyledValue>{jobOffer.address}</StyledValue>
                <StyledLabel>Treść ogłoszenia</StyledLabel>
                <StyledValue
                  dangerouslySetInnerHTML={{ __html: jobOffer.content }}
                ></StyledValue>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <ConfirmModal
          isOpen={confirmModalOptions.isOpen}
          message={confirmModalOptions.message}
          state={confirmModalOptions.state}
          onConfirm={changeStatus}
          onDiscard={() => setConfirmModalOptions({ isOpen: false })}
        ></ConfirmModal>
        <Modal isOpen={editDetailsModalIsOpen} fullscreen>
          <EditJobOfferDetails
            jobOffer={jobOffer}
            onDiscard={() => setEditDetailsModalIsOpen(false)}
            onSuccess={async () => {
              await fetchJobOffer();
              setEditDetailsModalIsOpen(false);
            }}
          ></EditJobOfferDetails>
        </Modal>
      </PageContent>
    </SiteWrapper>
  );
};

export default withRouter(EditJobOffer);
