import React from "react";
import styled, { css } from "styled-components";
import { isImage, isPdf, isDoc, isDocx } from "../../utils/fileUtils";
import Icon from "@mdi/react";
import { Tooltip } from "react-tippy";
import { Button } from "tabler-react";
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiMagnify
} from "@mdi/js";

const StyledButtons = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  opacity: 0;
  transition: opacity 0.3s;

  > div {
    margin-right: 15px;
  }

  > div:last-child {
    margin-right: 0;
  }
`;

export const StyledFilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const StyledFileImageWrapper = styled.div`
  margin: 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;

  ::after {
    content: "";
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    opacity: 0.8;
  }

  :hover {
    ${StyledButtons} {
      opacity: 1;
    }

    ::after {
      z-index: 1;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 2px solid #6574cd;

      ${StyledButtons} {
        opacity: 1;
      }

      ::after {
        z-index: 1;
        background: #467fcf;
      }
    `};
`;

export const StyledImage = styled.div`
  background: white;
  width: 178px;
  height: 178px;
  margin: 10px;
  position: relative;

  ::after {
    content: "";
    background: ${({ path }) => "url(" + path + ") no-repeat"};
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

const StyledFile = styled.div`
  background: white;
  width: 178px;
  height: 178px;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;

  img {
    height: 50px;
  }
`;

const StyledFileName = styled.div`
  height: 35px;
  overflow: hidden;
  position: absolute;
  bottom: -10px;
  left: -10px;
  text-align: center;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
  background: #eaeaea;
  font-size: 16px;
  padding: 5px;
`;

const FilesImagesDisplay = ({
  files,
  onRemoveClick,
  onEditClick,
  onSelectClick,
  selectedFiles,
  onPreviewClick
}) => {
  const selectedFileIds = selectedFiles ? selectedFiles.map(x => x.id) : [];
  const renderStyledButtons = file => {
    return (
      <StyledButtons>
        {typeof onSelectClick === "function" && (
          <div>
            <Button
              color={selectedFileIds.includes(file.id) ? "warning" : "primary"}
              type="button"
              onClick={() => onSelectClick(file, selectedFiles)}
            >
              <Icon
                path={
                  selectedFileIds.includes(file.id)
                    ? mdiCloseCircleOutline
                    : mdiCheckCircleOutline
                }
                size={0.8}
                color="white"
              />
              {selectedFileIds.includes(file.id) ? "Odznacz" : "Wybierz"}
            </Button>
          </div>
        )}
        {typeof onPreviewClick === "function" && (
          <div>
            <Tooltip title="Podgląd" position="bottom" arrow>
              <Button
                color="gray-dark"
                type="button"
                className="only-icon"
                onClick={() => onPreviewClick(file)}
              >
                <Icon path={mdiMagnify} size={0.8} color="white" />
              </Button>
            </Tooltip>
          </div>
        )}
        {typeof onEditClick === "function" && (
          <div>
            <Tooltip title="Edytuj" position="bottom" arrow>
              <Button
                color="primary"
                type="button"
                className="only-icon"
                onClick={() => onEditClick(file)}
              >
                <Icon path={mdiPencilOutline} size={0.8} color="white" />
              </Button>
            </Tooltip>
          </div>
        )}
        {typeof onRemoveClick === "function" && (
          <div>
            <Tooltip title="Usuń" position="bottom" arrow>
              <Button
                color="danger"
                type="button"
                className="only-icon"
                onClick={() => onRemoveClick(file)}
              >
                <Icon path={mdiTrashCanOutline} size={0.8} color="white" />
              </Button>
            </Tooltip>
          </div>
        )}
      </StyledButtons>
    );
  };

  const renderFile = file => {
    if (isImage(file.type)) {
      return (
        <StyledImage path={file.url}>{renderStyledButtons(file)}</StyledImage>
      );
    } else if (isPdf(file.type)) {
      return (
        <StyledFile>
          <img src="/pdf_icon.png" alt="" />
          <div>.PDF</div>
          <StyledFileName>{file.name}</StyledFileName>
          {renderStyledButtons(file)}
        </StyledFile>
      );
    } else if (isDocx(file.type)) {
      return (
        <StyledFile>
          <img src="/word_icon.png" alt="" />
          <div>.DOCX</div>
          <StyledFileName>{file.name}</StyledFileName>
          {renderStyledButtons(file)}
        </StyledFile>
      );
    } else if (isDoc(file.type)) {
      return (
        <StyledFile>
          <img src="/word_icon.png" alt="" />
          <div>.DOC</div>
          <StyledFileName>{file.name}</StyledFileName>
          {renderStyledButtons(file)}
        </StyledFile>
      );
    }
  };

  if (!files) {
    return <div />;
  }

  return (
    <StyledFilesWrapper>
      {files.map(file => {
        return (
          <StyledFileImageWrapper
            key={file.id}
            isSelected={selectedFileIds.includes(file.id)}
          >
            {renderFile(file)}
          </StyledFileImageWrapper>
        );
      })}
    </StyledFilesWrapper>
  );
};

export default FilesImagesDisplay;
